import { callbackActiveGame } from 'helpers/SharedFunctions'
import { useCallback, useEffect, useState } from 'react'
import { oraclePriceADAUSDTContract, oraclePriceBTCUSDTContract, oraclePriceCAKEUSDTContract, oraclePriceLINKUSDTContract } from '../utils/contracts'
import { useGetActiveGame } from './useGetActiveGame'

import useLastUpdated from './useLastUpdated'

const usePredictionDescription = () => {
    const [description, setDescription] = useState('')

    const { lastUpdated, setLastUpdated: refresh } = useLastUpdated()
    const activeGame = useGetActiveGame();

    const updateDescription = useCallback(async (oracleContract: any) => {
        const response: string = await oracleContract.methods.description().call();
        setDescription(response.replace('/', '-'));
    }, [])

    useEffect(() => {
        const fetchDescription = async () => {
            callbackActiveGame(
                activeGame,
                () => updateDescription(oraclePriceLINKUSDTContract), // lINK
                () => updateDescription(oraclePriceADAUSDTContract), // ADA
                () => updateDescription(oraclePriceCAKEUSDTContract), // CAKE
                () => updateDescription(oraclePriceBTCUSDTContract), // BITCOIN  
                () => updateDescription(oraclePriceLINKUSDTContract), // DEFAULT
            );
        }

        fetchDescription()
    }, [description, activeGame, updateDescription])

    return { description, lastUpdated, refresh }
}

export default usePredictionDescription

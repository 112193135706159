/* eslint-disable react-hooks/rules-of-hooks */
import { callbackActiveGame } from "helpers/SharedFunctions";
import { useSelector } from "react-redux"
import { useGetActiveGame } from "./useGetActiveGame";

export const useGetIntervalSeconds = () => {
  const activeGame = useGetActiveGame();
  let intervalSeconds = useSelector((state: any) => state.predictionBitcoinReducer.intervalSeconds)

  callbackActiveGame(
    activeGame,
    () => { // lINK
      intervalSeconds = useSelector((state: any) => state.predictionLinkReducer.intervalSeconds)
    },
    () => { // ADA
      intervalSeconds = useSelector((state: any) => state.predictionAdaReducer.intervalSeconds)
    },
    () => { // CAKE
      intervalSeconds = useSelector((state: any) => state.predictionCakeReducer.intervalSeconds)
    },
    () => { // BITCOIN
      intervalSeconds = useSelector((state: any) => state.predictionBitcoinReducer.intervalSeconds)
    },
    () => { // DEFAULT
      intervalSeconds = useSelector((state: any) => state.predictionBitcoinReducer.intervalSeconds)
    }
  );

  return intervalSeconds;
}

import { useTransition } from "react-spring";
import * as easings from 'd3-ease';

/**
 * Count numbers animation script useSpring
 * @param duration The animation duration
 * @param from A number where to start the animation
 * @param to A number where to end the animation
 * @param delay A delay number for the animation
 */
export const countNumbers = (duration: number = 2000, from: number = 0, to: number = 0, delay: number = 500) => {
    return {
        config: { duration: duration ? duration : 2000 },
        number: to,
        from: { number: from },
        delay: delay === 0 ? 0 : 500
    }
}

/**
 * Fade in animation duration is set to 300 milliseconds
 * @param show boolean to show or hide the object
 * @returns a useTranslationResult
 */
export const FadeIn = (show: any, customOpacity = 1, duration: number = 300) => {
    const transitions = useTransition(show, null, {
        from: { opacity: 0 },
        enter: { opacity: customOpacity },
        leave: { opacity: 0 },
        config: {
            duration: duration,
            easing: easings.easeCubicInOut
        }
    });
    return transitions;
}

/**
 * Animates from the left
 * @param show boolean to show or hide the object
 * @param widthOfTheItemAsNegativeNumber the total width of the object but as a negative number
 * @param duration in milliseconds
 * @returns a useTranslationResult
 */
export const PullRight = (show: boolean, widthOfTheItemAsNegativeNumber: number, duration: number) => {
    const transitions = useTransition(show, null, {
        from: { right: widthOfTheItemAsNegativeNumber, width: 520 },
        enter: { right: 0, width: 520 },
        leave: { right: widthOfTheItemAsNegativeNumber, width: 520 },
        config: {
            duration: duration
        }
    });
    return transitions;
}
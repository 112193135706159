import { Games } from "types/Games";

export const INIT_PRESALE_LINK = "INIT_PRESALE_LINK";
export const INIT_PRESALE_ADA = "INIT_PRESALE_ADA";
export const INIT_PRESALE_CAKE = "INIT_PRESALE_CAKE";

export const initPresale = (data: any, nameOfPresale: string) => {
    const action = {
        rate: data.rate,
        minPurchase: data.minPurchase,
        maxPurchase: data.maxPurchase,
        rewardTokenCount: data.rewardTokenCount,
        availableTokensICO: data.availableTokensICO,
        endICO: data.endICO,
        withdrawAmount: data.withdrawAmount,
        hasBeenFetched: true
    };

    switch (nameOfPresale) {
        case Games.LUCRA_LINK:
            return {
                type: INIT_PRESALE_LINK,
                payload: { LINK: action }
            }
        case Games.LUCRA_ADA:
            return {
                type: INIT_PRESALE_ADA,
                payload: { ADA: action }
            }
        case Games.LUCRA_CAKE:
            return {
                type: INIT_PRESALE_CAKE,
                payload: { CAKE: action }
            }
    }
}
import bitcoin from '../../assets/images/lucra_btc.svg';
import link from '../../assets/images/lucra_link.svg';
import ada from '../../assets/images/lucra_ada.svg';
import cake from '../../assets/images/lucra_cake.svg';

export const mainMenuConfig = () => {
    return [
        {
            name: 'Dashboard',
            icon: 'fa-light fa-house-blank',
            link: '/dashboard',
            disabled: false,
            dropdownItems: []
        },
        {
            name: 'Prediction',
            icon: 'fa-light fa-hand-holding-seedling',
            link: '/prediction/bitcoin',
            disabled: false,
            dropdownItems: [
                { name: 'Bitcoin', link: '/prediction/bitcoin', imgDetails: { image: bitcoin, alt: 'Bitcoin' } },
                { name: 'Lucra LINK', link: '/prediction/lucraLINK', imgDetails: { image: link, alt: 'Link' } }, { name: 'Lucra ADA', link: '/prediction/lucraADA', imgDetails: { image: ada, alt: 'Ada' } },
                { name: 'Lucra CAKE', link: '/prediction/lucraCAKE', imgDetails: { image: cake, alt: 'Cake' } }
            ]
        },
        {
            name: 'Presale',
            icon: 'fa-light fa-trophy',
            link: '/presale/lucraLINK',
            disabled: true,
            dropdownItems: [
                { name: 'Lucra LINK', link: '/presale/lucraLINK', imgDetails: { image: link, alt: 'Link' } },
                { name: 'Lucra ADA', link: '/presale/lucraADA', imgDetails: { image: ada, alt: 'Ada' } },
                { name: 'Lucra CAKE', link: '/presale/lucraCAKE', imgDetails: { image: cake, alt: 'Cake' } }
            ]
        },
        {
            name: 'Exchange',
            icon: 'fa-light fa-trophy',
            link: '/exchange',
            disabled: false,
            dropdownItems: []
        },
        // {
        //     name: 'Leaderboard',
        //     icon: 'fa-light fa-trophy',
        //     link: '/leaderboard'
        // },
        {
            name: 'Help',
            icon: 'fa-light fa-circle-question',
            link: 'https://www.gitbook.com/',
            disabled: false,
            dropdownItems: []
        },
    ]
}
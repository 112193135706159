import { ContractName } from '../types/ContractName';

import MULTI_CALL_ABI from '../assets/abi/MultiCall.json';
import CHAINLINK_ORACLE_ABI from '../assets/abi/ChainlinkOracle.json';
import EXCHANGE_ABI from '../assets/abi/Exchange.json';
import WBNB_ABI from '../assets/abi/WBNB.json';

import LUCRA_LINK_ABI from '../assets/abi/LucraLINKToken.json';
import LUCRA_ADA_ABI from '../assets/abi/LucraADAToken.json';
import LUCRA_CAKE_ABI from '../assets/abi/LucraCAKEToken.json';

import PREDICTION_BTC_ABI from '../assets/abi/PredictionBTC.json';
import PREDICTION_LINK_ABI from '../assets/abi/PredictionLINK.json';
import PREDICTION_ADA_ABI from '../assets/abi/PredictionADA.json';
import PREDICTION_CAKE_ABI from '../assets/abi/PredictionCAKE.json';

import PRESALE_LINK_ABI from '../assets/abi/PresaleLucraLINK.json';
import PRESALE_ADA_ABI from '../assets/abi/PresaleLucraADA.json';
import PRESALE_CAKE_ABI from '../assets/abi/PresaleLucraCAKE.json';

import FACTORY_ABI from '../assets/abi/Factory.json';
import ROUTER_ABI from '../assets/abi/Router.json';

export const addresses: any = {
    // ******************************************
    // *************** GAME ******************
    // ******************************************
    [ContractName.PredictionBTC]: {
        97: { // testnet
            'address': '0x656c0A166E2BA2d17C760048153e24b504b4ABa3',
            'abi': PREDICTION_BTC_ABI
        },
        56: { // mainnet
            'address': '',
            'abi': PREDICTION_BTC_ABI
        }
    },
    [ContractName.PredictionLINK]: {
        97: { // testnet
            'address': '0x302e758A6F243a19a1352f33d4c898c3854E170B',
            'abi': PREDICTION_LINK_ABI
        },
        56: { // mainnet
            'address': '',
            'abi': PREDICTION_LINK_ABI
        }
    },
    [ContractName.PredictionADA]: {
        97: { // testnet
            'address': '0x9C24f766c1164f3236b74938305be82Ef9E19c79',
            'abi': PREDICTION_ADA_ABI
        },
        56: { // mainnet
            'address': '',
            'abi': PREDICTION_ADA_ABI
        }
    },
    [ContractName.PredictionCAKE]: {
        97: { // testnet
            'address': '0xCDA73ccE5faa9FBa772491e40d9D27061348Cd97',
            'abi': PREDICTION_CAKE_ABI
        },
        56: { // mainnet
            'address': '',
            'abi': PREDICTION_CAKE_ABI
        }
    },
    // ******************************************
    // *************** ORACLES ******************
    // ******************************************
    [ContractName.OracleBTCUSDT]: {
        97: { // testnet
            'address': '0x5741306c21795FdCBb9b265Ea0255F499DFe515C',
            'abi': CHAINLINK_ORACLE_ABI
        },
        56: { // mainnet
            'address': '',
            'abi': CHAINLINK_ORACLE_ABI
        }
    },
    [ContractName.OracleLINKUSDT]: {
        97: { // testnet
            'address': '0x143db3CEEfbdfe5631aDD3E50f7614B6ba708BA7',
            'abi': CHAINLINK_ORACLE_ABI
        },
        56: { // mainnet
            'address': '',
            'abi': CHAINLINK_ORACLE_ABI
        }
    },
    [ContractName.OracleADAUSDT]: {
        97: { // testnet
            'address': '0x5e66a1775BbC249b5D51C13d29245522582E671C',
            'abi': CHAINLINK_ORACLE_ABI
        },
        56: { // mainnet
            'address': '',
            'abi': CHAINLINK_ORACLE_ABI
        }
    },
    [ContractName.OracleCAKEUSDT]: {
        97: { // testnet
            'address': '0x81faeDDfeBc2F8Ac524327d70Cf913001732224C',
            'abi': CHAINLINK_ORACLE_ABI
        },
        56: { // mainnet
            'address': '',
            'abi': CHAINLINK_ORACLE_ABI
        }
    },
    // ******************************************
    // *************** TOKENS *******************
    // ******************************************
    [ContractName.LucraLINKToken]: {
        97: { // testnet
            'address': '0x300157CbD5394b5e3d52056312942E32413b9f53',
            'abi': LUCRA_LINK_ABI
        },
        56: { // mainnet
            'address': '',
            'abi': LUCRA_LINK_ABI
        }
    },
    [ContractName.LucraADAToken]: {
        97: { // testnet
            'address': '0xCC632831a436Cc421f0a614df4058A2f8Fe0AE16',
            'abi': LUCRA_ADA_ABI
        },
        56: { // mainnet
            'address': '',
            'abi': LUCRA_ADA_ABI
        }
    },
    [ContractName.LucraCAKEToken]: {
        97: { // testnet
            'address': '0xc6f47A0397026Ee45C80689fCcc901d4C7C0A1c6',
            'abi': LUCRA_CAKE_ABI
        },
        56: { // mainnet
            'address': '',
            'abi': LUCRA_CAKE_ABI
        }
    },
    // ******************************************
    // *************** PRESALE ******************
    // ******************************************
    [ContractName.PresaleLucraLINK]: {
        97: { // testnet
            'address': '0x99aE791C977569bf11345eBEbd514B2b52982ecf',
            'abi': PRESALE_LINK_ABI
        },
        56: { // mainnet
            'address': '',
            'abi': PRESALE_LINK_ABI
        }
    },
    [ContractName.PresaleLucraADA]: {
        97: { // testnet
            'address': '0x95fa16F32472B1C298a3Eb1FB99c16510dd282A9',
            'abi': PRESALE_ADA_ABI
        },
        56: { // mainnet
            'address': '',
            'abi': PRESALE_ADA_ABI
        }
    },
    [ContractName.PresaleLucraCAKE]: {
        97: { // testnet
            'address': '0xBbFFBd6e6ab52220e358dD88b01d5cF2d62E7C09',
            'abi': PRESALE_CAKE_ABI
        },
        56: { // mainnet
            'address': '',
            'abi': PRESALE_CAKE_ABI
        }
    },
    // ******************************************
    // *************** GENERAL ******************
    // ******************************************
    [ContractName.MultiCall]: {
        97: { // testnet
            'address': '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
            'abi': MULTI_CALL_ABI
        },
        56: { // mainnet
            'address': '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
            'abi': MULTI_CALL_ABI
        }
    },
    [ContractName.Exchange]: {
        97: { // testnet
            'address': '0xEcF9DacfC7E21c9ab184A8ffAE3AA5cC2630A3Cb',
            'abi': EXCHANGE_ABI
        },
        56: { // mainnet
            'address': '',
            'abi': EXCHANGE_ABI
        }
    },
    [ContractName.WBNB]: {
        97: { // testnet
            'address': '0xae13d989dac2f0debff460ac112a837c89baa7cd',
            'abi': WBNB_ABI
        },
        56: { // mainnet
            'address': '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
            'abi': WBNB_ABI
        }
    },
    [ContractName.PancakeFactory]: {
        97: { // testnet
            'address': '0xb7926c0430afb07aa7defde6da862ae0bde767bc',
            'abi': FACTORY_ABI
        },
        56: { // mainnet
            'address': '0xBCfCcbde45cE874adCB698cC183deBcF17952812',
            'abi': FACTORY_ABI
        }
    },
    [ContractName.PancakeRouter]: {
        97: { // testnet
            'address': '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',
            'abi': ROUTER_ABI
        },
        56: { // mainnet
            'address': '',
            'abi': ROUTER_ABI
        }
    }
}
import CenterActionBlock from 'components/CenterActionBlock/CenterActionBlock';
import React from 'react'

function Leaderboard() {

    return (
        <div id='main-wrapper' className='align-center'>
            <CenterActionBlock
                icon={<i className="fa-light fa-trophy"></i>}
                title='Leaderboard is not available yet'
                description='This will be activated in the next update'
            />
        </div>
    )
}

export default Leaderboard;
import React, { useCallback, useEffect, useState } from 'react'
import MenuItem from 'components/MenuItem/MenuItem';
import RadioBlock from 'components/RadioBlock/RadioBlock';
import HistoryRounds from './HistoryRounds';
import HistoryPNL from './HistoryPNL';
import CenterActionBlock from 'components/CenterActionBlock/CenterActionBlock';
import ConnectButton from 'components/ConnectButton/ConnectButton';
import Button from 'components/Button/Button';

import { PullRight } from 'helpers/AnimationHelper';
import { useHistorySideMenuStatus } from 'hooks/useHistorySideMenuStatus';
import { animated } from 'react-spring';
import { useDispatch } from 'react-redux';
import { historyMenuConfig } from './config';
import { HistoryMenu } from 'types/HistoryMenu';
import { HistoryMenuNames } from 'types/HistoryMenuNames';
import { HistoryFilter } from 'types/HistoryFilter';
import { useWallet } from '@binance-chain/bsc-use-wallet';
import { getFilteredBets } from 'containers/Prediction/helper';
import { useGetHistory } from 'hooks/useGetHistory';
import { useGetHistoryFilter } from 'hooks/useGetHistoryFilter';
import { useGetActiveGame } from 'hooks/useGetActiveGame';
import { callbackActiveGame } from 'helpers/SharedFunctions';

import { updateHistorySideMenuStatus as updateHistorySideMenuStatusBitcoin, updateHistoryFilter as updateHistoryFilterBitcoin  } from 'actions/PredictionBitcoin';
import { updateHistorySideMenuStatus as updateHistorySideMenuStatusLink, updateHistoryFilter as updateHistoryFilterLink } from 'actions/PredictionLink';
import { updateHistorySideMenuStatus as updateHistorySideMenuStatusAda, updateHistoryFilter as updateHistoryFilterAda} from 'actions/PredictionAda';
import { updateHistorySideMenuStatus as updateHistorySideMenuStatusCake, updateHistoryFilter as updateHistoryFilterCake } from 'actions/PredictionCake';

interface Props { }

function HistorySideMenu(props: Props) {
    const activeGame = useGetActiveGame();
    const { account } = useWallet();
    const dispatch = useDispatch();
    const historySideMenuStatus = useHistorySideMenuStatus();
    const historyFilter = useGetHistoryFilter()
    const bets = useGetHistory()
    const pullRightAnimation = PullRight(historySideMenuStatus, -520, 200);
    const [activeMenuItem, setActiveMenuItem] = useState(HistoryMenuNames.ROUNDS)
    const results = getFilteredBets(bets, historyFilter);
    const hasBetHistory = results && results.bets ? results.bets.length > 0 : results.length > 0;

    /**
    * @dev Open the history side menu
    */
    const toggleHistorySideMenu = useCallback(() => {
        callbackActiveGame(
            activeGame,
            () => dispatch(updateHistorySideMenuStatusLink(false)), // lINK
            () => dispatch(updateHistorySideMenuStatusAda(false)), // ADA
            () => dispatch(updateHistorySideMenuStatusCake(false)), // CAKE
            () => dispatch(updateHistorySideMenuStatusBitcoin(false)), // BITCOIN
            () => dispatch(updateHistorySideMenuStatusBitcoin(false)), // DEFAULT
        );
    }, [activeGame, dispatch])

    /**
      * @dev Open the history side menu
      */
    const toggleHistoryFilter = useCallback((filter: HistoryFilter) => {
        callbackActiveGame(
            activeGame,
            () => dispatch(updateHistoryFilterLink(filter)), // lINK
            () => dispatch(updateHistoryFilterAda(filter)), // ADA
            () => dispatch(updateHistoryFilterCake(filter)), // CAKE
            () => dispatch(updateHistoryFilterBitcoin(filter)), // BITCOIN
            () => dispatch(updateHistoryFilterBitcoin(filter)), // DEFAULT
        );
    }, [activeGame, dispatch])

    useEffect(() => {
        if (!account) {
            toggleHistorySideMenu()
        }
    }, [account, dispatch, toggleHistorySideMenu])

    return (
        <>
            {pullRightAnimation.map(({ item, key, props }) => item &&
                <animated.div key={key} style={props} className='history-side-menu-wrap'>
                    <div className='history-side-menu-top'>
                        <div className='history-side-menu-header'>
                            <h1>History</h1>
                            <div className='lg-icon' onClick={() => toggleHistorySideMenu()}>
                                <i className="fa-thin fa-xmark"></i>
                            </div>
                        </div>
                        {account &&
                            <nav className='history-menu' id="nav-main">
                                <ul>
                                    {historyMenuConfig().map((menu: HistoryMenu) => {
                                        return <MenuItem
                                            key={menu.name}
                                            onClick={() => setActiveMenuItem(menu.name)}
                                            link={menu.link}
                                            name={menu.name}
                                            disabled={menu.disabled}
                                            dropdownItems={[]}
                                        />
                                    })}
                                </ul>
                            </nav>
                        }
                    </div>
                    {account &&
                        <div className='history-side-menu-bottom'>
                            <div className='history-side-menu-filter'>
                                <span className='sm-txt'>Filter</span>
                                <RadioBlock active={historyFilter === HistoryFilter.ALL} title={HistoryFilter.ALL} onChange={() => toggleHistoryFilter(HistoryFilter.ALL)} />
                                <RadioBlock active={historyFilter === HistoryFilter.COLLECTED} title={HistoryFilter.COLLECTED} onChange={() => toggleHistoryFilter(HistoryFilter.COLLECTED)} />
                                <RadioBlock active={historyFilter === HistoryFilter.UNCOLLECTED} title={HistoryFilter.UNCOLLECTED} onChange={() => toggleHistoryFilter(HistoryFilter.UNCOLLECTED)} />
                            </div>
                            {activeMenuItem === HistoryMenuNames.ROUNDS
                                ? <HistoryRounds hasBetHistory={hasBetHistory} bets={results} />
                                : <HistoryPNL hasBetHistory={hasBetHistory} bets={results} />
                            }
                        </div>
                    }
                    {!account &&
                        <CenterActionBlock
                            icon={<i className="fa-thin fa-wallet"></i>}
                            title='Wallet'
                            description='Connect your wallet to view your prediction history'
                            customButton={<ConnectButton enableTextDialog={false} />}
                        />
                    }
                    {account && !hasBetHistory && historyFilter === HistoryFilter.COLLECTED
                        ? <CenterActionBlock
                            icon={<i className="fa-thin fa-coin"></i>}
                            title='No collected bets available'
                            description='You have no collected bets in your history!'
                            customButton={<Button className='button btn-main' onClick={() => toggleHistorySideMenu()}>Close</Button>}
                        />
                        : account && !hasBetHistory && historyFilter === HistoryFilter.UNCOLLECTED
                            ? <CenterActionBlock
                                icon={<i className="fa-thin fa-coin"></i>}
                                title='No uncollected bets available'
                                description='You have no uncollected bets in your history!'
                                customButton={<Button className='button btn-main' onClick={() => toggleHistorySideMenu()}>Close</Button>}
                            />
                            : account && !hasBetHistory &&
                            <CenterActionBlock
                                icon={<i className="fa-thin fa-history"></i>}
                                title='No prediction history available'
                                description='You have no bet history, play games to create history!'
                                customButton={<Button className='button btn-main' onClick={() => toggleHistorySideMenu()}>Close</Button>}
                            />
                    }
                </animated.div>
            )}
        </>
    )
}

export default HistorySideMenu;
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import History from './helpers/History';
import thunk from 'redux-thunk';
import predictionBitcoinReducer from './reducers/PredictionBitcoin';
import predictionLinkReducer from 'reducers/PredictionLink';
import presaleReducer from 'reducers/Presale';
import globalReducer from 'reducers/Global';
import predictionAdaReducer from 'reducers/PredictionAda';
import predictionCakeReducer from 'reducers/PredictionCake';
import App from './App';
import * as bsc from '@binance-chain/bsc-use-wallet'

// Import Swiper styles
import './theme/global.scss';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'react-toastify/dist/ReactToastify.css';

import { composeWithDevTools } from 'redux-devtools-extension' // DISABLE THIS IN PRODUCTION MODE
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { Router } from 'react-router-dom';
import { Provider, intlReducer } from 'react-intl-redux'
import { config } from './utils/config';
import { ToastContainer } from 'react-toastify';

// #endregion
// #region Create the store, The thunk middleware is used to dispatch fetch calls.
const middleware = [thunk];

const allReducers = combineReducers({
  intl: intlReducer,
  predictionBitcoinReducer: predictionBitcoinReducer,
  predictionLinkReducer: predictionLinkReducer,
  predictionAdaReducer: predictionAdaReducer,
  predictionCakeReducer: predictionCakeReducer,
  presaleReducer: presaleReducer,
  globalReducer: globalReducer
});

const store = createStore(allReducers, composeWithDevTools(applyMiddleware(...middleware)))

ReactDOM.render(
  <Router history={History}>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    <Provider store={store}>
      <bsc.UseWalletProvider chainId={config.networkId} connectors={{ bsc }}>
        <App />
      </bsc.UseWalletProvider>
    </Provider>
  </Router >,
  document.getElementById('outer-wrapper')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react'
import Button from 'components/Button/Button';
import bannerImage from '../../assets/images/lucra-main-banner-straight.png'
import lucaCardImage from '../../assets/images/lucra-card.png'
import History from 'helpers/History';
import mainLogo from '../../assets/images/Logo_Lucra.svg'

function Dashboard() {
    return (
        <div id='dashboard-wrapper'>
            <div id="content-3" className="content-banner-wrap">
                <div className="container">
                    <div className="content-banner content-banner-txt-img">
                        <div className="banner-blk-txt">
                            <div className="content-banner-title">
                                <h1>Introducing the innovative LUCRA Ecosystem.</h1>
                            </div>
                            <div className="content-banner-txt">
                                <p>
                                    LUCRA is a multifaceted, multi-token ecosystem that provides bluechip rewards and dynamic use cases to investors. Thanks to huge ambition, a considerable roadmap and an extensive suite of offerings, LUCRA Finance has found a way to move beyond conventional trading volume dependency for continuous reward generation. This is what sets LUCRA apart from the pack.
                                </p>
                            </div>
                            <div className="content-banner-btn">
                                <Button className='button btn-main' onClick={() => History.push('/prediction/bitcoin')}>Start playing</Button>
                                {/* <Button className='button b-btn'>Learn more</Button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content-0" className="content-banner-wrap">
                <div className="container">
                    <div className="content-banner content-banner-txt-img">
                        <div className="banner-blk-txt">
                            <div className="content-banner-title">
                                <h1>LUCRA, the new home of social wagering.</h1>
                            </div>
                            <div className="content-banner-txt">
                                <p>
                                    Speculate on cryptocurrency price action, Win big multipliers.
                                    Bet on the price movement of several bluechip cryptocurrencies, including $LINK, $ADA, $CAKE, and $BTC itself. Speculate sensibly.
                                </p>
                            </div>
                        </div>
                        <div className="banner-blk-img neg-margin">
                            <img src={bannerImage} alt="Banner" />
                        </div>
                    </div>
                </div>
            </div>
            <div id="content-1" className="content-banner-wrap">
                <div className="container">
                    <div className="content-banner content-banner-txt-img">
                        <div className="banner-blk-img">
                            <img src={lucaCardImage} alt="Card" />
                        </div>
                        <div className="banner-blk-txt">
                            <div className="content-banner-title">
                                <h2>More Prediction Pools, More Possibilities.</h2>
                            </div>
                            <div className='content-banner-icon-info'>
                                <div className='content-banner-icon'>
                                    <div className='sm-icon'>
                                        <i className="fa-thin fa-chart-line"></i>
                                    </div>
                                </div>
                                <div className='content-banner-icon-txt-wrap'>
                                    <h3 className='content-banner-icon-txt'>
                                        Multiply Your Earnings, Be Rewarded For Holding
                                    </h3>
                                    <p className='content-banner-icon-subtitle'>
                                        Our Price Prediction games benefit all $LUCRA holders. While $BNB is wagered on Bitcoin’s price movement, native $LUCRA tokens are wagered on the remaining three bluechips. A 2% Platform Fee market buys all three $LUCRA tokens, providing holders with constant rewards.
                                    </p>
                                </div>
                            </div>
                            <div className='content-banner-icon-info'>
                                <div className='content-banner-icon'>
                                    <div className='sm-icon'>
                                        <i className="fa-thin fa-gamepad"></i>
                                    </div>
                                </div>
                                <div className='content-banner-icon-txt-wrap'>
                                    <h3 className='content-banner-icon-txt'>
                                        Ungameable, thanks to Chainlink.
                                    </h3>
                                    <p className='content-banner-icon-subtitle'>
                                        Our games proudly utilises Chainlink Oracles, meaning price action is provided in real-time and cannot be gamed, thus providing an equal playing field for all participants.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content-2" className="content-banner-wrap animate">
                <div className="container">
                    <div className='big-card-wrap'>
                        <div className='big-card'>
                            <h2>An Entire Ecosystem Designed for Holders</h2>
                            <p>
                                Unlike other reward-based projects, trading volume based rewards will not be the only incentives present to continually benefit holders. An intricately devised ecosystem will allow for holders of LUCRA tokens to continue to reap the benefits of bluechip rewards, even without a high level of trading volume. No other rewards-based project has overcome this challenge. Additionally, arbitrage is encouraged between the ecosystem’s tokens thanks to reduced intra-LUCRA transaction fee, allowing for further consistent volume.
                            </p>
                            {/* <Button className='button b-btn'>Learn more</Button> */}
                        </div>
                        <div className='big-card'>
                            <h2>A Lucrative Arsenal of Use Cases</h2>
                            <p>As previously mentioned, many use cases will be incorporated into the expansive <b>LUCRA Ecosystem</b>. Included in this vision is the expansion of the current library of volume generating betting games. The growth of this library and its diversification, amongst other factors, will be a key catalyst in pushing the Ecosystem forward.</p>
                            {/* <Button className='button b-btn'>Learn more</Button> */}
                        </div>
                    </div>
                </div>
            </div>
            <div id="footer">
                <div className="container">
                    <div id="footer-bottom">
                        <div className="footer-copyright">
                            <img src={mainLogo} alt="Lucra" />
                        </div>
                        <ul className="footer-social">
                            <li>
                                <a href="https://medium.com/" target="_blank" title="Medium" rel="noreferrer">
                                    <i className="fa-brands fa-medium"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com" target="_blank" title="Twitter" rel="noreferrer">
                                    <i className="fa-brands fa-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://telegram.com/" target="_blank" title="Telegram" rel="noreferrer">
                                    <i className="fa-brands fa-telegram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;
import React, { useState } from 'react'
import { NodeRound } from '../../types/NodeRound';
import { GameStatus } from '../../types/GameStatus';
import { getRoundPosition, updatePredictionGame } from '../../containers/Prediction/helper';
import { BetPosition } from '../../types/BetPosition';
import { getToken, predictionADAContract, predictionBTCContract, predictionCAKEContract, predictionLINKContract } from 'utils/contracts';
import { useWallet } from '@binance-chain/bsc-use-wallet';
import { sendTransaction } from 'utils/web3';
import { useDispatch } from 'react-redux';
import { callbackActiveGame, showToast } from 'helpers/SharedFunctions';
import { ContractName } from 'types/ContractName';
import { useGetActiveGame } from 'hooks/useGetActiveGame';
import { useGetCurrentEpoch } from 'hooks/useGetCurrentEpoch';
import { useGetEarliestEpoch } from 'hooks/useGetEarliestEpoch';

import CenterActionBlock from 'components/CenterActionBlock/CenterActionBlock';
import Button from 'components/Button/Button';

interface Props {
    round: NodeRound
}

function ClaimRoundCard(props: Props) {
    const { epoch, lockPrice, closePrice }: any = props.round
    const betPosition = getRoundPosition(lockPrice, closePrice);
    const [btnIsLoading, setBtnIsLoading] = useState(false)
    const { account } = useWallet();
    const { ethereum }: { ethereum: any } = useWallet()
    const dispatch = useDispatch();
    const activeGame = useGetActiveGame();
    const currentEpoch = useGetCurrentEpoch(activeGame);
    const earliestEpoch = useGetEarliestEpoch()

    const getColorStatus = () => {
        if (betPosition === BetPosition.BULL) {
            return 'status-up';
        }
        if (betPosition === BetPosition.BEAR) {
            return 'status-down';
        }
        if (betPosition === null) {
            return 'status-down';
        }
        return '';
    }

    const collectRewards = async () => {
        setBtnIsLoading(true)
        try {
            let func: any;
            let address: string;
            callbackActiveGame(
                activeGame,
                () => { // lINK
                    address = getToken(ContractName.PredictionLINK).address;
                    func = predictionLINKContract.methods.claim([epoch]).encodeABI();
                },
                () => { // ADA
                    address = getToken(ContractName.PredictionADA).address;
                    func = predictionADAContract.methods.claim([epoch]).encodeABI();
                },
                () => { // CAKE
                    address = getToken(ContractName.PredictionCAKE).address;
                    func = predictionCAKEContract.methods.claim([epoch]).encodeABI();
                },
                () => {  // BITCOIN
                    address = getToken(ContractName.PredictionBTC).address;
                    func = predictionBTCContract.methods.claim([epoch]).encodeABI();
                },
                () => { // DEFAULT
                    address = getToken(ContractName.PredictionBTC).address;
                    func = predictionBTCContract.methods.claim([epoch]).encodeABI();
                }
            );
            await sendTransaction(ethereum, account, address, func, '0x0',
                async () => { // onSuccess
                    setBtnIsLoading(false);
                    // We have to mark the bet as claimed immediately because it does not update fast enough
                    updatePredictionGame(currentEpoch, earliestEpoch, activeGame, dispatch, account);
                    showToast('Successfully collected rewards', false);
                }, (err: any) => { // onError
                    if (err.code === 4001) {
                        showToast('Transaction has been denied', true);
                    } else {
                        showToast('Failed to collect rewards', true);
                    }
                    setBtnIsLoading(false);

                })
        } catch {
            setBtnIsLoading(false);
        }
    }

    return (
        <div className={`card-wrap is-calculating won ${getColorStatus()}`}>
            <div className='card-header'>
                <div className='card-header-left'>
                    <div className='sm-icon'>
                        <i className="fa-thin fa-clock-five"></i>
                    </div>
                    <span>{GameStatus.COLLECT}</span>
                </div>
                <div className='card-header-right'>
                    <span>#{epoch}</span>
                </div>
            </div>
            <div className='card-content'>
                <div className='card-content-loader-wrap'>
                    <div className='card-content-loader-txt'>
                        <CenterActionBlock
                            icon={<i className="fa-light fa-trophy"></i>}
                            title='Congratulations!'
                            description='Collect your winnings'
                            customButton={<Button className='button btn-main' isLoading={btnIsLoading} onClick={() => collectRewards()}>Collect</Button>}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClaimRoundCard;
import React, { useCallback, useEffect } from 'react'
import MainMenu from './components/MainMenu/MainMenu';
import Prediction from './containers/Prediction/Prediction';
import usePollOraclePrice from './hooks/usePollOraclePrice';
import usePollPredictions from './hooks/usePollPredictions';
import Dashboard from 'containers/Dashboard/Dashboard';
import Leaderboard from 'containers/Leaderboard/Leaderboard';
import HistorySideMenu from 'components/HistorySideMenu/HistorySideMenu';
import Presale from 'containers/Presale/Presale';
import Exchange from 'containers/Exchange/Exchange';

import { Redirect, Route, Switch, useLocation } from 'react-router';
import { usePollBlockNumber } from './hooks/usePollBlockNumber';
import { useHistorySideMenuStatus } from 'hooks/useHistorySideMenuStatus';
import { FadeIn } from 'helpers/AnimationHelper';
import { animated } from 'react-spring';
import { useDispatch } from 'react-redux';
import { useGetActiveGame } from 'hooks/useGetActiveGame';
import { callbackActiveGame } from 'helpers/SharedFunctions';

import { updateHistorySideMenuStatus as updateHistorySideMenuStatusBitcoin } from 'actions/PredictionBitcoin';
import { updateHistorySideMenuStatus as updateHistorySideMenuStatusLink } from 'actions/PredictionLink';
import { updateHistorySideMenuStatus as updateHistorySideMenuStatusCake } from 'actions/PredictionCake';
import { updateHistorySideMenuStatus as updateHistorySideMenuStatusAda } from 'actions/PredictionAda';

function App() {
    usePollBlockNumber();
    usePollOraclePrice();
    usePollPredictions();

    const activeGame = useGetActiveGame();
    const location = useLocation();
    const dispatch = useDispatch();
    const historySideMenuStatus = useHistorySideMenuStatus();
    const backdropAnimation = FadeIn(historySideMenuStatus, 0.72);

    const initBackgroundGradientColor = useCallback(() => {
        const outerWrapper = document.getElementById('outer-wrapper');
        if (outerWrapper && location.pathname !== '/dashboard') {
            outerWrapper.classList.add('gradient-bg');
        } else {
            outerWrapper.classList.remove('gradient-bg');
        }
    }, [location]);

    /**
    * @dev Open the history side menu
    */
      const toggleHistorySideMenu = () => {
        callbackActiveGame(
            activeGame,
            () => dispatch(updateHistorySideMenuStatusLink(false)), // lINK
            () => dispatch(updateHistorySideMenuStatusAda(false)), // ADA
            () => dispatch(updateHistorySideMenuStatusCake(false)), // CAKE
            () => dispatch(updateHistorySideMenuStatusBitcoin(false)), // BITCOIN
            () => dispatch(updateHistorySideMenuStatusBitcoin(false)), // DEFAULT
        );
    }

    useEffect(() => {
        initBackgroundGradientColor();
    }, [initBackgroundGradientColor])

    return (
        <React.Fragment>
            <MainMenu />
            <Switch>
                <Redirect strict exact from="/" to="/dashboard" />
                <Route path="/dashboard" component={Dashboard} exact />
                <Redirect strict exact from="/prediction/" to="/prediction/bitcoin" />
                <Route path="/prediction/:name" component={Prediction} exact />
                <Route path="/leaderboard" component={Leaderboard} exact />
                <Redirect strict exact from="/presale/" to="/dashboard" />
                <Route path="/presale/:name" component={Presale} exact />
                <Route path="/exchange" component={Exchange} exact />
            </Switch>
            {/* Popups */}
            {backdropAnimation.map(({ item, key, props }) => item && <animated.div onClick={() => toggleHistorySideMenu()} id='backdrop' className='cursor-pointer' key={key} style={props}></animated.div>)}
            <HistorySideMenu />
        </React.Fragment>
    )
}

export default App;
import React from 'react'
import { Bet } from 'types/Bet';

interface Props {
    hasBetHistory: boolean
    bets: Bet[]
}

function HistoryPNL(props: Props) {
    return (
        <div className='history-side-menu-content'>
            asd
        </div>
    )
}

export default HistoryPNL;
import React, { useCallback, useEffect, useState, } from 'react'
import Button from 'components/Button/Button';
import bnbLogo from '../../assets/images/lucra-bnb-icon.svg'
import adaLogo from '../../assets/images/lucra-ada-icon.svg'
import cakeLogo from '../../assets/images/lucra-cake-icon.svg'
import linkLogo from '../../assets/images/lucra-link-icon.svg'
import BigNumber from 'bignumber.js';
import MenuItem from '@mui/material/MenuItem';
import ConnectButton from 'components/ConnectButton/ConnectButton'
import Loader from 'components/Loader/Loader';

import { useGetBnbBalance } from 'hooks/useGetBnbBalance';
import { callbackActiveGame, formatBigNumber, showToast } from 'helpers/SharedFunctions';
import { Select } from '@material-ui/core';
import { useInitialBlock } from 'hooks/usePollBlockNumber';
import { useDispatch } from 'react-redux';
import { initializePresale } from './helper';
import { initPresale } from 'actions/Presale';
import { useGetPresaleEndICO } from 'hooks/useGetPresaleEndICO';
import { useGetMinPurchasePresale } from 'hooks/useGetMinPurchasePresale';
import { useGetRewardTokenCountPresale } from 'hooks/useGetRewardTokenCountPresale';
import { useWallet } from '@binance-chain/bsc-use-wallet';
import { FetchStatus } from 'types/FetchStatus';
import { sendTransaction } from 'utils/web3';
import { getToken, PresaleLucraADAContract, PresaleLucraCAKEContract, PresaleLucraLINKContract } from 'utils/contracts';
import { useGetWithdrawAmountPresale } from 'hooks/useGetWithdrawAmountPresale';
import { useGetPresaleFetched } from 'hooks/useGetPresaleFetched';
import { ContractName } from 'types/ContractName';
import { useGetMaxPurchasePresale } from 'hooks/useGetMaxPurchasePresale';
import { useParams } from 'react-router';

function Presale() {
    const params: any = useParams();
    const presaleDataFetched = useGetPresaleFetched();
    const endIcoBlock = useGetPresaleEndICO(params.name);
    const getMinPurchase = useGetMinPurchasePresale(params.name);
    const getMaxPurchase = useGetMaxPurchasePresale(params.name);
    const getRewardTokenCount = useGetRewardTokenCountPresale(params.name);
    const withdrawAmount = useGetWithdrawAmountPresale(params.name);
    const formattedTokenCount = new BigNumber(formatBigNumber(getRewardTokenCount)).toNumber()
    const formattedMinPurchase = new BigNumber(formatBigNumber(getMinPurchase)).toNumber()
    const formattedMaxPurchase = new BigNumber(formatBigNumber(getMaxPurchase)).toNumber()
    const initialBlock = useInitialBlock()
    const dispatch = useDispatch();

    const { ethereum }: { ethereum: any } = useWallet()
    const { account } = useWallet();
    const { balance: bnbBalance, fetchStatus } = useGetBnbBalance();

    const balanceDisplay = formatBigNumber(bnbBalance);

    const [pageIsLoading, setPageIsLoading] = useState<boolean>(true);
    const [bnbValue, setBnbValue] = useState<BigNumber>(new BigNumber(0))
    const [receiveValue, setReceiveValue] = useState<BigNumber>(new BigNumber(0))
    const [buyIsLoading, setBuyIsLoading] = useState<boolean>(false)
    const [buyBtnText, setBuyBtnText] = useState<string>('Buy')
    const [claimIsLoading, setClaimIsLoading] = useState<boolean>(false)
    const [loaderTitle, setLoaderTitle] = useState<string>('Connecting to Binance Smart Chain nodes...');
    const [loaderDescription, setLoaderDescription] = useState<string>('Please have some patience. Refresh the page when it takes to long.');

    /**
    * @dev Checking if bnb input has a error, if so show error message
    */
    const getErrorMessage = () => {
        if (balanceDisplay) {
            if (new BigNumber(bnbValue).isGreaterThan(balanceDisplay)) return 'Insuffiencent balance';
            if (new BigNumber(bnbValue).isLessThanOrEqualTo(0)) return '';
            if (new BigNumber(bnbValue).isLessThan(formattedMinPurchase)) return 'The minimum purchase is ' + formattedMinPurchase;
            if (new BigNumber(receiveValue).isGreaterThan(formattedMaxPurchase)) return 'The maximum purchase is ' + formattedMaxPurchase;
            if (bnbValue.toString() === '') return '';
        }
        return '';
    }

    /**
    * @dev Checking if bnb input has a error
    */
    const hasError = () => {
        if (balanceDisplay) {
            if (new BigNumber(bnbValue).isGreaterThan(balanceDisplay)) return true;
            if (new BigNumber(bnbValue).isLessThanOrEqualTo(0)) return true;
            if (new BigNumber(bnbValue).isLessThan(formattedMinPurchase)) return true;
            if (bnbValue.toString() === '') return true;
            if (new BigNumber(receiveValue).isGreaterThan(formattedMaxPurchase)) return true;
            if (initialBlock > parseInt(endIcoBlock)) return true;
        }
        return false;
    }

    /**
    * @dev Checks if the current presale ido has ended.
    * @returns boolean
    */
    const hasICOEnded = useCallback(() => {
        if (initialBlock > parseInt(endIcoBlock)) return true;
        return false;
    }, [endIcoBlock, initialBlock])

    /**
    * @dev Gets the current title based on the urlParams
    */
    const getCurrentPresaleTitle = () => {
        let title: string = '';
        callbackActiveGame(
            params.name,
            () => { // lINK
                title = 'LUCRA LINK'
            },
            () => { // ADA
                title = 'LUCRA ADA'
            },
            () => { // CAKE
                title = 'LUCRA CAKE'
            },
            () => { }, // BITCOIN
            () => { // DEFAULT
                title = 'LUCRA LINK'
            }
        );
        return title;
    }

    const getActiveToken = () => {
        let value: string = 'LINK';
        callbackActiveGame(
            params.name,
            () => { // lINK
                value = 'LINK';
            },
            () => { // ADA
                value = 'ADA';
            },
            () => { // CAKE
                value = 'CAKE';
            },
            () => { }, // BITCOIN
            () => { // DEFAULT
                value = 'LINK';
            }
        );
        // Fallback
        return value;
    }

    /**
    * @dev Gets the current title based on the urlParams
    * @params boolean -> getValue: returns the select default value
    * @params boolean -> getImage: returns the image based on the active game
    * @params boolean -> getTitle: returns the title based on the active game
    */
    const getReceiveMenuItem = (getImage: boolean, getTitle: boolean) => {
        let logo: string = linkLogo;
        if (getImage) {
            callbackActiveGame(
                params.name,
                () => { // lINK
                    logo = linkLogo;
                },
                () => { // ADA
                    logo = adaLogo;
                },
                () => { // CAKE
                    logo = cakeLogo;
                },
                () => { }, // BITCOIN
                () => { // DEFAULT
                    logo = linkLogo;
                }
            );
            // Fallback
            return logo;
        }

        let title: string = 'LCR LINK';
        if (getTitle) {
            callbackActiveGame(
                params.name,
                () => { // lINK
                    title = 'LCR LINK';
                },
                () => { // ADA
                    title = 'LCR ADA';
                },
                () => { // CAKE
                    title = 'LCR CAKE';
                },
                () => { }, // BITCOIN
                () => { // DEFAULT
                    title = 'LCR LINK';
                }
            );
            // Fallback
            return title;
        }
    }

    /**
    * @dev Buys the presale
    */
    const buyPresale = async () => {
        setBuyIsLoading(true);
        setBuyBtnText('Submitting tx...')
        try {
            const createTx = async (contract: any, address: string) => {
                const func = await contract.methods.buyTokens(account).encodeABI();
                const wei = new BigNumber(new BigNumber(bnbValue).times(1e18)).toNumber()
                await sendTransaction(ethereum, account, address, func, wei,
                    async () => { // onSuccess
                        setBuyIsLoading(false);
                        initPresaleData();
                        showToast('Successfully bought tokens', false);
                        setBuyBtnText('Buy');
                    }, (err: any) => { // onError
                        if (err.code === 4001) {
                            showToast('Transaction has been denied', true);
                        } else {
                            showToast('Failed to buy tokens', true);
                        }
                        setBuyBtnText('Buy');
                        setBuyIsLoading(false);
                    })
            }

            callbackActiveGame(
                params.name,
                () => { // lINK
                    createTx(PresaleLucraLINKContract, getToken(ContractName.PresaleLucraLINK).address);
                },
                () => { // ADA
                    createTx(PresaleLucraADAContract, getToken(ContractName.PresaleLucraADA).address);
                },
                () => { // CAKE
                    createTx(PresaleLucraCAKEContract, getToken(ContractName.PresaleLucraCAKE).address);
                },
                () => { }, // BITCOIN
                () => { // DEFAULT
                    createTx(PresaleLucraLINKContract, getToken(ContractName.PresaleLucraLINK).address);
                }
            );
        } catch {
            setBuyIsLoading(false);
            setBuyBtnText('Buy');
        }
    }

    /**
    * @dev Claims the presale
    */
    const claimPresale = async () => {
        setClaimIsLoading(true);
        try {
            const createTx = async (contract: any, address: string) => {
                const func = await contract.methods.withdrawReward().encodeABI();
                await sendTransaction(ethereum, account, address, func, '0x0',
                    async () => { // onSuccess
                        setClaimIsLoading(false);
                        initPresaleData();
                        showToast('Successfully claimed tokens', false);
                    }, (err: any) => { // onError
                        if (err.code === 4001) {
                            showToast('Transaction has been denied', true);
                        } else {
                            showToast('Failed to claim tokens', true);
                        }
                        setClaimIsLoading(false);
                    })
            }

            callbackActiveGame(
                params.name,
                () => { // lINK
                    createTx(PresaleLucraLINKContract, getToken(ContractName.PresaleLucraLINK).address);
                },
                () => { // ADA
                    createTx(PresaleLucraADAContract, getToken(ContractName.PresaleLucraADA).address);
                },
                () => { // CAKE
                    createTx(PresaleLucraCAKEContract, getToken(ContractName.PresaleLucraCAKE).address);
                },
                () => { }, // BITCOIN
                () => { // DEFAULT
                    createTx(PresaleLucraLINKContract, getToken(ContractName.PresaleLucraLINK).address);
                }
            );
        } catch {
            setClaimIsLoading(false);
        }
    }

    /**
    * @dev Sets the bnb value input to max BNB available
    */
    const onMaxBNB = () => {
        setBnbValue(new BigNumber(balanceDisplay));
        setReceiveValue(new BigNumber(new BigNumber(balanceDisplay).toNumber()).times(formattedTokenCount))
    }

    /**
   * @dev Sets the [Token] value input to max buy
   */
    const onMaxBuy = () => {
        setBnbValue(new BigNumber(formattedMaxPurchase).div(formattedTokenCount));
        setReceiveValue(new BigNumber(formattedMaxPurchase))
    }

    /**
    * @dev Sets the bnb value input to max BNB available
    */
    const bnbOnChange = (ev: any) => {
        setBnbValue(ev.target.value);
        setReceiveValue(new BigNumber(ev.target.value).times(formattedTokenCount));
    }

    /**
    * @dev Sets the bnb value input to max BNB available
    */
    const receiveOnChange = (ev: any) => {
        setReceiveValue(new BigNumber(ev.target.value));
        setBnbValue(new BigNumber(ev.target.value).div(formattedTokenCount));
    }

    /**
    * @dev Adds the disabled class if ico has ended
    */
    const getICOEndedClass = () => {
        return hasICOEnded() ? 'disabled' : '';
    }

    /**
    * @dev Based on the urlParams, the init is being dispatched to the reducer
    * @TODO Vraag murat wat voor error teksten hier moet komen
    */
    const initPresaleData = useCallback(async () => {
        const loaderConfig = (isLoading: boolean, title: string, description: string) => {
            setPageIsLoading(isLoading);
            setLoaderTitle(title);
            setLoaderDescription(description);
        }

        if (!presaleDataFetched[getActiveToken()].isFetched) {
            loaderConfig(true, 'Initializing data', 'Please have some patience. While we Initialize the pre-sale data');
        }
        try {
            const { data, fetchStatus } = await initializePresale(account, params.name);
            if (fetchStatus === FetchStatus.SUCCESS && data) {
                dispatch(initPresale(data, params.name));
                const formattedMinPurchase = new BigNumber(formatBigNumber(data.minPurchase));
                const formattedTokenCount = new BigNumber(formatBigNumber(data.rewardTokenCount)).toNumber()
                setBnbValue(formattedMinPurchase)
                setReceiveValue(new BigNumber(formattedMinPurchase).times(formattedTokenCount))
                setPageIsLoading(false);
                presaleDataFetched[getActiveToken()].isFetched = true;
            } else if (fetchStatus === FetchStatus.FAILED) {
                loaderConfig(true, 'Failed to initialize data', 'Sorry, something went wrong. Please try again by refreshing the page.');
            }
        } catch {
            loaderConfig(true, 'Failed to initialize data', 'Sorry, something went wrong. Please try again by refreshing the page.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, params.name]);

    /**
    * @dev Making sure that web3 is working with the current RPC by checking the first block and bnbBalance is being fetched.
    */
    useEffect(() => {
        if (account && initialBlock > 0) {
            setPageIsLoading(false);
            initPresaleData();
        }
    }, [account, initPresaleData, initialBlock])

    return (
        <div id='main-wrapper' className='align-center'>
            {pageIsLoading
                ? <Loader title={loaderTitle} description={loaderDescription} />
                : <div className='main-big-card'>
                    <div className='main-big-card-top'>
                        <h3>PRE-SALE {getCurrentPresaleTitle()}</h3>
                        <p>{hasICOEnded() ? `Pre-Sale has ended. Claim your rewards now!` : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'}</p>
                    </div>
                    <div className='main-big-card-content'>
                        {/* BNB */}
                        <span className={`sm-txt ${getICOEndedClass()}`}>You pay</span>
                        <div className={`form-control ${getICOEndedClass()}`}>
                            <div className='form-icon'>
                                <Select
                                    disabled={hasICOEnded()}
                                    className='removeArrow'
                                    value='BNB'
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}>
                                    <MenuItem value="BNB">
                                        <img src={bnbLogo} alt="BNB" />
                                        BNB
                                    </MenuItem>
                                </Select>
                            </div>
                            <input disabled={hasICOEnded()} value={bnbValue.toString()} placeholder='Amount' type="number" onChange={(ev: any) => bnbOnChange(ev)} />
                            <Button disabled={hasICOEnded()} className='button t-btn' onClick={() => onMaxBNB()}>MAX</Button>
                        </div>
                        <div className='form-control-txt'>
                            <span className={`sm-txt ${getICOEndedClass()}`}>{hasICOEnded() ? '' : getErrorMessage()}</span>
                            <span className={`sm-txt ${getICOEndedClass()}`}>{fetchStatus !== FetchStatus.SUCCESS ? 'Loading...' : `Balance: ${balanceDisplay}`}</span>
                        </div>
                        {/* LCR */}
                        <span className={`sm-txt ${getICOEndedClass()}`}>You receive</span>
                        <div className={`form-control ${getICOEndedClass()}`}>
                            <div className='form-icon'>
                                <Select
                                    disabled={true}
                                    value={getActiveToken()}
                                    className='removeArrow'
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}>
                                    <MenuItem value={getActiveToken()}>
                                        <img src={getReceiveMenuItem(true, false)} alt={getActiveToken()} />
                                        {getReceiveMenuItem(false, true)}
                                    </MenuItem>
                                </Select>
                            </div>
                            <input disabled={hasICOEnded()} value={receiveValue.toString()} placeholder='Amount' type="number" onChange={(ev: any) => receiveOnChange(ev)} />
                            <Button disabled={hasICOEnded()} className='button t-btn' onClick={() => onMaxBuy()}>MAX</Button>
                        </div>
                    </div>
                    <div className={`main-big-card-btm ${!account ? 'single-button' : ''}`}>
                        {account
                            ? <>
                                <Button
                                    className={`button ${hasICOEnded() ? 'main-btn' : 'b-btn'}`}
                                    disabled={!hasICOEnded() || new BigNumber(withdrawAmount).isLessThanOrEqualTo(0)}
                                    isLoading={claimIsLoading}
                                    onClick={() => claimPresale()}>
                                    Claim
                                </Button>
                                <Button
                                    className={`button ${hasICOEnded() ? 'b-btn' : 'main-btn'}`}
                                    disabled={hasError()}
                                    isLoading={buyIsLoading}
                                    onClick={() => buyPresale()}>
                                    {buyBtnText}
                                </Button>
                            </>
                            :
                            <ConnectButton enableTextDialog={true} />
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default Presale;
import React from 'react'
import Button from '../../components/Button/Button'
import BigNumber from 'bignumber.js';

import { countNumbers } from '../../helpers/AnimationHelper';
import { useSpring, animated } from 'react-spring';
import { useGetActiveCurrency } from 'hooks/useGetActiveCurrency';

interface Props {
    prizePool: any
    onEnterBear: Function
    onEnterBull: Function
    hasEnteredUp: boolean
    hasEnteredDown: boolean
}

function GameButtons(props: Props) {
    const prizePool = new BigNumber(new BigNumber(props.prizePool).toFixed(3)).toNumber();
    const priceCount = useSpring(countNumbers(1000, 0, prizePool, 0));
    const currency = useGetActiveCurrency();

    return (
        <div className='vote-pool-btm'>
            <div className='vote-pool'>
                <span className='info-txt'>Locked price</span>
                <animated.div>
                    {priceCount.number.interpolate((val: any) => {
                        return isNaN(val) ? 0 : val.toFixed(3);
                    })}
                </animated.div>
                <div>
                    &nbsp; {currency}
                </div>
            </div>
            {props.hasEnteredUp
                ? <div className='vote-pool-btn'>
                    <Button disabled className='button btn-green'>Entered UP</Button>
                </div>
                : props.hasEnteredDown
                    ? <div className='vote-pool-btn'>
                        <Button disabled className='button btn-red'>Entered DOWN</Button>
                    </div>
                    : <div className='vote-pool-btns'>
                        <Button onClick={() => props.onEnterBull()} className='button btn-green'>Enter UP</Button>
                        <Button onClick={() => props.onEnterBear()} className='button btn-red'>Enter DOWN</Button>
                    </div>
            }
        </div>
    )
}

export default GameButtons;
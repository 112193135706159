import { ethers } from 'ethers';
import { Games } from 'types/Games';
import { HistoryFilter } from 'types/HistoryFilter';
import { getClaimStatuses, getLedgerData, getPredictionData, getRoundsData, makeLedgerData, serializePredictionsRoundsResponse } from '../containers/Prediction/helper';
import { LedgerData } from '../types/LedgerData';
import { ReduxNodeRound } from '../types/ReduxNodeRound';

export const INIT_PREDICTIONS_CAKE = "INIT_PREDICTIONS_CAKE";
export const INIT_HISTORY_CAKE = "INIT_HISTORY_CAKE";
export const UPDATE_PREDICTIONS_CAKE = "UPDATE_PREDICTIONS_CAKE";
export const UPDATE_ROUNDS_CAKE = "UPDATE_ROUNDS_CAKE";
export const UPDATE_LEDGERS_CAKE = "UPDATE_LEDGERS_CAKE";
export const UPDATE_CLAIMABLE_STATUSES_CAKE = "UPDATE_CLAIMABLE_STATUSES_CAKE";
export const SET_LAST_ORACLE_PRICE_CAKE = "SET_LAST_ORACLE_PRICE_CAKE";
export const SET_ROUNDS_CAKE = "SET_ROUNDS_CAKE";
export const SET_HISTORY_SIDE_MENU_STATUS_CAKE = "SET_HISTORY_SIDE_MENU_STATUS_CAKE"
export const UPDATE_LEDGERS_AS_MARKED_CAKE = "UPDATE_LEDGERS_AS_MARKED_CAKE";
export const SET_HISTORY_FILTER_CAKE = "SET_HISTORY_FILTER_CAKE";
export const IS_FETCHED_CAKE = "IS_FETCHED_CAKE";
export const UPDATE_HISTORY_CAKE = "UPDATE_HISTORY_CAKE";

export const initPredictions = (data: any) => {
    return {
        type: INIT_PREDICTIONS_CAKE,
        payload: {
            bufferSeconds: data.bufferSeconds ? data.bufferSeconds : 0,
            claimableStatuses: data.claimableStatuses ? data.claimableStatuses : {},
            currentEpoch: data.currentEpoch ? data.currentEpoch : 0,
            intervalSeconds: data.intervalSeconds ? data.intervalSeconds : 0,
            ledgers: data.ledgers ? data.ledgers : 0,
            minBetAmount: data.minBetAmount ? data.minBetAmount : '0',
            rounds: data.rounds ? data.rounds : {},
            status: data.status ? data.status : undefined
        }
    }
}

export const initHistory = (data: any) => {
    return {
        type: INIT_HISTORY_CAKE,
        payload: {
            history: {
                bets: data.bets,
                claimableStatuses: data.claimableStatuses,
                page: data.page,
                totalHistory: data.totalHistory
            }
        }
    }
}

export const updateHistory = (data: any) => {
    return {
        type: UPDATE_HISTORY_CAKE,
        payload: {
            history: {
                bets: data.bets,
                claimableStatuses: data.claimableStatuses,
                page: data.page,
                totalHistory: data.totalHistory
            }
        }
    }
}

export const updatePredictionsMarket = (data: any) => {
    return {
        type: UPDATE_PREDICTIONS_CAKE,
        payload: {
            bufferSeconds: data.bufferSeconds,
            currentEpoch: data.currentEpoch,
            intervalSeconds: data.intervalSeconds,
            minBetAmount: data.minBetAmount,
            status: data.status,
        }
    }
}

export const updateRounds = (rounds: { [key: string]: ReduxNodeRound }) => {
    return {
        type: UPDATE_ROUNDS_CAKE,
        payload: {
            rounds: rounds
        }
    }
}

export const updateHistoryFilter = (filter: HistoryFilter) => {
    return {
        type: SET_HISTORY_FILTER_CAKE,
        payload: {
            historyFilter: filter
        }
    }
}

export const updateLedgers = (ledgers: LedgerData) => {
    return {
        type: UPDATE_LEDGERS_CAKE,
        payload: {
            ledgers: ledgers
        }
    }
}

export const updateLedgerAsMarked = (epoch: number) => {
    return async (dispatch: Function, getState: any) => {
        const claimableStatuses: any = getState().predictionBitcoinReducer.history.claimableStatuses;
        claimableStatuses[epoch] = false;

        dispatch({
            type: UPDATE_LEDGERS_AS_MARKED_CAKE,
            payload: {
                history: {
                    claimableStatuses: claimableStatuses
                }
            }
        })
    }
}

export const updateHistorySideMenuStatus = (isOpen: boolean) => {
    return {
        type: SET_HISTORY_SIDE_MENU_STATUS_CAKE,
        payload: {
            historySideMenuStatus: isOpen
        }
    }
}

export const setLastOraclePriceCAKEUSDT = (lastPrice: ethers.BigNumber) => {
    return async (dispatch: Function) => {
        dispatch({
            type: SET_LAST_ORACLE_PRICE_CAKE,
            payload: {
                lastOraclePriceCAKEUSDT: lastPrice.toString()
            }
        })
    }
}

export const fetchRounds = (epochs: any[], activeGame: Games) => {
    return async (dispatch: Function) => {
        const roundsResponse: any = await getRoundsData(epochs, activeGame);
        const initialRoundData: { [key: string]: ReduxNodeRound } = roundsResponse.reduce((accum: any, roundResponse: any) => {
            const reduxNodeRound = serializePredictionsRoundsResponse(roundResponse)
            return {
                ...accum,
                [reduxNodeRound.epoch.toString()]: reduxNodeRound,
            }
        }, {});
        dispatch(updateRounds(initialRoundData));
    }
}

export const fetchMarketData = (activeGame: Games) => {
    return async (dispatch: Function) => {
        const marketData = await getPredictionData(activeGame);
        dispatch(updatePredictionsMarket(marketData))
        return marketData
    }
}

export const fetchLedgerData = (account: any, epochs: any[], activeGame: Games) => {
    return async (dispatch: Function) => {
        const ledgerResponses: any = await getLedgerData(account, epochs, activeGame);
        const d = makeLedgerData(account, ledgerResponses, epochs);
        dispatch(updateLedgers(d))
        return d;
    }
}

export const fetchClaimableStatuses = (account: any, epochs: any[], activeGame: Games) => {
    return async () => {
        const ledgers = await getClaimStatuses(account, epochs, activeGame);
        return ledgers
    }
}

export const setCakeIsFetched = (isFetched: boolean) => {
    return async (dispatch: Function) => {
        dispatch({
            type: IS_FETCHED_CAKE,
            payload: {
                isFetched
            }
        })
    }
}
import { callbackActiveGame } from "helpers/SharedFunctions";
import { PredictionGame } from "types/PredictionGame";
import { useGetActiveGame } from "./useGetActiveGame";

export const useGetActiveCurrency = () => {
    const activeGame = useGetActiveGame();
    let activeCurrency: PredictionGame = PredictionGame.BNB;

    callbackActiveGame(
        activeGame,
        () => { // lINK
            activeCurrency = PredictionGame.LCR_LINK;
        },
        () => { // ADA
            activeCurrency = PredictionGame.LCR_ADA;
        },
        () => { // CAKE
            activeCurrency = PredictionGame.LCR_CAKE;
        },
        () => { // BITCOIN
            activeCurrency = PredictionGame.BNB;
        },
        () => { // DEFAULT
            activeCurrency = PredictionGame.BNB;
        }
    );

    return activeCurrency;
}
import React from 'react'
import BigNumber from 'bignumber.js';

import { getPriceDifference } from '../../containers/Prediction/helper';
import { formatUsdv2, getPrizePoolAmount } from '../../helpers/SharedFunctions';
import { useGetLastOraclePrice } from '../../hooks/useGetLastOraclePrice';
import { GameStatus } from '../../types/GameStatus';
import { NodeRound } from '../../types/NodeRound';
import { countNumbers } from '../../helpers/AnimationHelper';
import { useSpring, animated } from 'react-spring';
import { Round } from 'types/Round';
import { useGetActiveCurrency } from 'hooks/useGetActiveCurrency';

interface Props {
    round: NodeRound | Round,
    hasFailed?: boolean,
    gameStatus: GameStatus,
    customClosePrice?: React.ReactNode,
}

function RoundResult(props: Props) {
    const { closePrice, lockPrice, totalAmount }: any = props.round;
    const priceCloseLockDifference = getPriceDifference(closePrice, lockPrice);
    const price = useGetLastOraclePrice();
    const currentPriceDifference = getPriceDifference(price, lockPrice);
    const currency = useGetActiveCurrency();

    const priceDifferenceLiveFormattedToFixed = isNaN(new BigNumber(formatUsdv2(currentPriceDifference, false).priceFormattedToFixed).toNumber()) ? 0 : new BigNumber(formatUsdv2(currentPriceDifference, false).priceFormattedToFixed).toNumber();
    const priceDifferenceLiveAnimation = useSpring(countNumbers(1000, 0, priceDifferenceLiveFormattedToFixed, 0))

    const priceCloseLockFormattedToFixed = isNaN(new BigNumber(formatUsdv2(priceCloseLockDifference, false).priceFormattedToFixed).toNumber()) ? 0 : new BigNumber(formatUsdv2(priceCloseLockDifference, false).priceFormattedToFixed).toNumber();
    const priceCloseLockAnimation = useSpring(countNumbers(1000, 0, priceCloseLockFormattedToFixed, 0))

    return (
        <div className='vote-info'>
            <div className='vote-info-top'>
                <div className='vote-info-top-left'>
                    <span className='info-txt'>Closed price:</span>
                    {props.customClosePrice && <h3>{props.customClosePrice}</h3>}
                    {!props.customClosePrice && <h3>{props.hasFailed ? '-' : formatUsdv2(closePrice)}</h3>}
                </div>
                <div className='vote-info-top-right'>
                    <div className='chip'>
                        <div className='sm-icon'>
                            {
                                (priceDifferenceLiveFormattedToFixed > 0 && props.gameStatus === GameStatus.LIVE) ||
                                    (priceCloseLockFormattedToFixed > 0 && props.gameStatus !== GameStatus.LIVE)
                                    ? <i className="fa-solid fa-caret-up"></i>
                                    : <i className="fa-solid fa-caret-down"></i>
                            }
                        </div>
                        <animated.span>
                            {props.gameStatus === GameStatus.LIVE
                                ? priceDifferenceLiveAnimation.number.interpolate((val: any) => { return '$' + val.toFixed(3) })
                                : priceCloseLockAnimation.number.interpolate((val: any) => { return '$' + val.toFixed(3) })
                            }
                        </animated.span>
                    </div>
                </div>
            </div>
            <div className='vote-info-btm'>
                <div className='vote-info-btm-left'>
                    <span>Locked price:</span>
                    <div>Price pool:</div>
                </div>
                <div className='vote-info-btm-right'>
                    <span className='info-txt'>{props.hasFailed ? '-' : formatUsdv2(lockPrice)}</span>
                    <div>{getPrizePoolAmount(totalAmount)} {currency}</div>
                </div>
            </div>
        </div>
    )
}

export default RoundResult;
import { setBlock } from 'actions/Global'
import { useRef, useEffect, useCallback, } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { web3 } from '../utils/web3'

export const usePollBlockNumber = (seconds = 10) => {
    const timer: any = useRef(null)
    const dispatch = useDispatch()
    const checkOnStart = useRef(false)

    const setBlockNumber = useCallback(async () => {
        const blockNumber = await web3.eth.getBlockNumber();
        dispatch(setBlock(blockNumber))
    }, [dispatch]);

    useEffect(() => {
        if (!checkOnStart.current) {
            checkOnStart.current = true;
            setBlockNumber();
        }
        timer.current = setInterval(async () => {
            setBlockNumber();
        }, seconds * 1000)
        return () => clearInterval(timer.current)
    }, [checkOnStart, dispatch, setBlockNumber, timer, seconds])
}

export const useBlock = () => {
    return useSelector((state: any) => state.globalReducer.currentBlock);
}

export const useInitialBlock = () => {
    return useSelector((state: any) => state.globalReducer.initialBlock);
}


/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from "react-redux"
import { Games } from "types/Games"
import { callbackActiveGame, parseBigNumberObj } from "../helpers/SharedFunctions"
import { NodeRound } from "../types/NodeRound"

export const useGetRounds = (activeGame: Games) => {
  let rounds = useSelector((state: any) => state.predictionBitcoinReducer.rounds);

  callbackActiveGame(
    activeGame,
    () => { // lINK
      rounds = useSelector((state: any) => state.predictionLinkReducer.rounds);
    },
    () => { // ADA
      rounds = useSelector((state: any) => state.predictionAdaReducer.rounds);
    },
    () => { // CAKE
      rounds = useSelector((state: any) => state.predictionCakeReducer.rounds);
    },
    () => { // BITCOIN
      rounds = useSelector((state: any) => state.predictionBitcoinReducer.rounds);
    },
    () => { // DEFAULT
      rounds = useSelector((state: any) => state.predictionBitcoinReducer.rounds);
    }
  );

  return Object.keys(rounds).reduce((accum, epoch) => {
    return {
      ...accum,
      [epoch]: parseBigNumberObj<NodeRound>(rounds[epoch]),
    }
  }, {}) as { [key: string]: NodeRound }
}

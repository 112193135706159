/* eslint-disable react-hooks/rules-of-hooks */
import { callbackActiveGame } from "helpers/SharedFunctions";
import { useSelector } from "react-redux"
import { Games } from "types/Games"

export const useGetCurrentEpoch = (activeGame: Games) => {
  let currentEpoch = useSelector((state: any) => state.predictionBitcoinReducer.currentEpoch);

  callbackActiveGame(
    activeGame,
    () => { // lINK
      currentEpoch = useSelector((state: any) => state.predictionLinkReducer.currentEpoch);
    },
    () => { // ADA
      currentEpoch = useSelector((state: any) => state.predictionAdaReducer.currentEpoch);
    },
    () => { // CAKE
      currentEpoch = useSelector((state: any) => state.predictionCakeReducer.currentEpoch);
    },
    () => { // BITCOIN
      currentEpoch = useSelector((state: any) => state.predictionBitcoinReducer.currentEpoch);
    },
    () => { // DEFAULT
      currentEpoch = useSelector((state: any) => state.predictionBitcoinReducer.currentEpoch);
    }
  );

  return currentEpoch;
}
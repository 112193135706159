import React from 'react'
import Radio from '@mui/material/Radio';

interface Props {
    title: string,
    active?: boolean,
    onChange: Function
}

function RadioBlock(props: Props) {

    return (
        <div className={`radio-block ${props.active ? 'active' : ''}`} onClick={() => props.onChange()}>
            <Radio
                checked={props.active}
                onChange={() => props.onChange()}
            />
            <span className='radio-txt'>{props.title}</span>
        </div>
    )
}

export default RadioBlock;
import { getToken, multicallv2 } from "utils/contracts";
import { Presale } from '../../types/Presale'
import { FetchStatus } from "types/FetchStatus";
import { Games } from "types/Games";
import { ContractName } from "types/ContractName";

export const initializePresale = async (account: any, activeGame: Games) => {
    let fetchStatus = FetchStatus.NOT_FETCHED;
    let address: string = '';
    let abi: any;
    if (activeGame === Games.LUCRA_LINK) {
        address = getToken(ContractName.PresaleLucraLINK).address;
        abi = getToken(ContractName.PresaleLucraLINK).abi;
    } else if (activeGame === Games.LUCRA_ADA) {
        address = getToken(ContractName.PresaleLucraADA).address;
        abi = getToken(ContractName.PresaleLucraADA).abi;
    } else if (activeGame === Games.LUCRA_CAKE) {
        address = getToken(ContractName.PresaleLucraCAKE).address;
        abi = getToken(ContractName.PresaleLucraCAKE).abi;
    } else {
        address = getToken(ContractName.PresaleLucraLINK).address;
        abi = getToken(ContractName.PresaleLucraLINK).abi;
    }

    let staticCalls: any[] = [];
    if (account) {
        staticCalls.push(
            { address: address, name: 'rate', params: [] },
            { address: address, name: 'minPurchase', params: [] },
            { address: address, name: 'maxPurchase', params: [] },
            { address: address, name: 'rewardTokenCount', params: [] },
            { address: address, name: 'availableTokensICO', params: [] },
            { address: address, name: 'endICO', params: [] },
            { address: address, name: 'checkWithdrawAmount', params: [account] }
        )
    } else {
        staticCalls.push(
            { address: address, name: 'rate', params: [] },
            { address: address, name: 'minPurchase', params: [] },
            { address: address, name: 'maxPurchase', params: [] },
            { address: address, name: 'rewardTokenCount', params: [] },
            { address: address, name: 'availableTokensICO', params: [] },
            { address: address, name: 'endICO', params: [] }
        )
    }
    let data: Presale;
    const presaleResponses: any = await multicallv2(abi, staticCalls);
    fetchStatus = FetchStatus.FETCHING;

    if (presaleResponses) {
        fetchStatus = FetchStatus.SUCCESS;
        if (account) {
            data = {
                rate: presaleResponses[0].toString(),
                minPurchase: presaleResponses[1].toString(),
                maxPurchase: presaleResponses[2].toString(),
                rewardTokenCount: presaleResponses[3].toString(),
                availableTokensICO: presaleResponses[4].toString(),
                endICO: presaleResponses[5].toString(),
                withdrawAmount: presaleResponses[6].toString()
            }
        } else {
            fetchStatus = FetchStatus.SUCCESS;
            data = {
                rate: presaleResponses[0].toString(),
                minPurchase: presaleResponses[1].toString(),
                maxPurchase: presaleResponses[2].toString(),
                rewardTokenCount: presaleResponses[3].toString(),
                availableTokensICO: presaleResponses[4].toString(),
                endICO: presaleResponses[5].toString()
            }
        }
    } else {
        fetchStatus = FetchStatus.FAILED;
    }

    return { data, fetchStatus };
}
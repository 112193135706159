import { useEffect, useState } from "react"
import { FetchStatus } from "types/FetchStatus"
import { ethers } from 'ethers';
import { useWallet } from "@binance-chain/bsc-use-wallet";
import { LucraCAKETokenContract } from "utils/contracts";

import useLastUpdated from "./useLastUpdated";

export const useGetLucraCakeBalance = () => {
    const [fetchStatus, setFetchStatus] = useState(FetchStatus.NOT_FETCHED)
    const [balance, setBalance] = useState(ethers.BigNumber.from(0))
    const { account } = useWallet()
    const { lastUpdated, setLastUpdated } = useLastUpdated()

    useEffect(() => {
        const fetchBalance = async () => {
            try {
                const walletBalance = await LucraCAKETokenContract.methods.balanceOf(account).call();
                setBalance(walletBalance)
                setFetchStatus(FetchStatus.SUCCESS)
            } catch {
                setFetchStatus(FetchStatus.FAILED)
            }
        }

        if (account) {
            fetchBalance()
        }
    }, [account, lastUpdated, setBalance, setFetchStatus])

    return { balance, fetchStatus, refresh: setLastUpdated }
}
import { Connectors } from "@binance-chain/bsc-use-wallet";
import sample from 'lodash/sample'

// #56 = Binance Mainnet
// #97 = Binance Testnet
export const networkId = parseInt(process.env.REACT_APP_CHAIN_ID!.toString());

const getRPCURL = () => {
  let nodes: any = [];
  if (networkId === 56) {
    nodes.push(process.env.REACT_APP_NODE_1_MAINNET)
    nodes.push(process.env.REACT_APP_NODE_2_MAINNET)
    nodes.push(process.env.REACT_APP_NODE_3_MAINNET)
  } else {
    nodes.push(process.env.REACT_APP_NODE_1_TESTNET)
    nodes.push(process.env.REACT_APP_NODE_2_TESTNET)
    nodes.push(process.env.REACT_APP_NODE_3_TESTNET)
  }
  return sample(nodes);
}

export const web3Provider: any = getRPCURL();

export const etherAPI: any =
  networkId === 56 ? process.env.REACT_APP_BINANCE_MAINNET_ETHER_API :
    networkId === 97 ? process.env.REACT_APP_BINANCE_TESTNET_ETHER_API :
      process.env.REACT_APP_BINANCE_MAINNET_ETHER_API

export const config = {
  web3Provider: web3Provider,
  networkId: networkId,
  etherAPI: etherAPI
};

export function setWalletConnected(value: keyof Connectors | null) {
  localStorage.setItem('__WALLET_CONNECTED', JSON.stringify(value));
}

export function isWalletConnected(): keyof Connectors | null {
  const val = localStorage.getItem('__WALLET_CONNECTED');
  return val ? JSON.parse(val) : null;
}
import { SET_INITIAL_BLOCK, SET_CURRENT_BLOCK, SET_ACTIVE_GAME } from "actions/Global";
import { BIG_ZERO } from "containers/Prediction/config";
import { Games } from "types/Games";

const initialState: any = {
    initialBlock: BIG_ZERO,
    currentBlock: BIG_ZERO,
    activeGame: Games.BITCOIN
};

export default function globalReducer(state = initialState, action: any) {
    let changes;
    switch (action.type) {
        case SET_INITIAL_BLOCK:
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case SET_CURRENT_BLOCK:
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case SET_ACTIVE_GAME:
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        default:
            return state;
    }
}

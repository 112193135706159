import { HistoryFilter } from "types/HistoryFilter";
import {
    INIT_PREDICTIONS_LINK,
    INIT_HISTORY_LINK,
    SET_LAST_ORACLE_PRICE_LINK,
    SET_ROUNDS_LINK, UPDATE_PREDICTIONS_LINK,
    SET_HISTORY_SIDE_MENU_STATUS_LINK,
    SET_HISTORY_FILTER_LINK,
    UPDATE_ROUNDS_LINK,
    UPDATE_LEDGERS_LINK,
    UPDATE_CLAIMABLE_STATUSES_LINK,
    UPDATE_LEDGERS_AS_MARKED_LINK,
    IS_FETCHED_LINK
} from "../actions/PredictionLink";
import { BIG_ZERO } from "../containers/Prediction/config";

const initialState: any = {
    history: {
        bets: {},
        claimableStatuses: {},
        page: 0,
        totalHistory: 0
    },
    historySideMenuStatus: false,
    historyFilter: HistoryFilter.ALL,
    bufferSeconds: 0,
    claimableStatuses: {},
    currentEpoch: 0,
    intervalSeconds: 0,
    ledgers: {},
    minBetAmount: '0',
    rounds: {},
    status: undefined,
    lastOraclePriceLINKUSDT: BIG_ZERO.toJSON(),
    isFetched: false
};

export default function predictionLinkReducer(state = initialState, action: any) {
    let changes;
    switch (action.type) {
        case INIT_PREDICTIONS_LINK:
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case SET_LAST_ORACLE_PRICE_LINK:
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case SET_ROUNDS_LINK:
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case SET_HISTORY_FILTER_LINK:
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case UPDATE_PREDICTIONS_LINK:
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case SET_HISTORY_SIDE_MENU_STATUS_LINK:
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case UPDATE_ROUNDS_LINK:
            changes = {
                ...state,
                rounds: { ...state.rounds, ...action.payload.rounds }
            };
            return changes;
        case UPDATE_LEDGERS_LINK:
            changes = {
                ...state,
                ledgers: { ...state.ledgers, ...action.payload.ledgers }
            };
            return changes;
        case UPDATE_CLAIMABLE_STATUSES_LINK:
            changes = {
                ...state,
                claimableStatuses: { ...state.claimableStatuses, ...action.payload.claimableStatuses }
            };
            return changes;
        case INIT_HISTORY_LINK:
            changes = {
                ...state,
                history: { ...state.history, ...action.payload.history }
            };
            return changes;
        case UPDATE_LEDGERS_AS_MARKED_LINK:
            changes = {
                ...state,
                history: {
                    ...state.history,
                    claimableStatuses: action.payload.history.claimableStatuses
                }
            };
            return changes;
        case IS_FETCHED_LINK:
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        default:
            return state;
    }
}

import { HistoryMenuNames } from '../../types/HistoryMenuNames';

export const historyMenuConfig = () => {
    return [
        {
            name: HistoryMenuNames.ROUNDS,
            icon: 'fa-light fa-house-blank',
            link: '/rounds',
            disabled: false,
            dropdownItems: []
        }
        // {
        //     name: HistoryMenuNames.PNL,
        //     icon: 'fa-light fa-hand-holding-seedling',
        //     link: '/pnl'
        // }
    ]
}
import React from 'react'

interface Props {
    color: 'green' | 'red' | 'main',
    fill?: boolean,
    uppercase?: boolean
    icon?: React.ReactNode,
    marginRightIcon?: number
    txt: any,
    onClick?: Function,
    addCursor?: boolean
}


function Chip(props: Props) {

    return (
        <div className={`chip chip-${props.color} ${props.uppercase ? 'uppercase' : ''} ${props.fill ? 'fill' : ''} ${props.addCursor ? 'cursor-pointer' : ''}`} onClick={() => props.onClick && props.onClick()}>
            <div className='sm-icon' style={{ marginRight: props.marginRightIcon}}>
                {props.icon ? props.icon : props.color === 'green' ? <i className="fa-solid fa-caret-up"></i> : <i className="fa-solid fa-caret-down"></i>}
            </div>
            <span>
                {props.txt}
            </span>
        </div>
    )
}

export default Chip;
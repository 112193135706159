import React from 'react';
import Button from '../Button/Button';

export interface Props {
    title?: string,
    description?: string,
    image?: string,
    imageAlt?: string,
    icon?: React.ReactNode,
    btnText?: string,
    btnOnClick?: Function,
    customButton?: React.ReactNode
};

const CenterActionBlock: React.FC<Props> = (props) => {
    return (
        <div className={`center-action-block-wrap`}>
            {props.image &&
                <div className='center-action-img'>
                    <img src={props.image} alt={props.imageAlt} />
                </div>
            }
            {props.icon &&
                <div className='center-action-icon'>
                    {props.icon}
                </div>
            }
            {props.title &&
                <div className={`center-action-top`}>
                    {props.title}
                </div>
            }
            {props.description &&
                <p className={`center-action-center`}>
                    {props.description}
                </p>
            }
            {props.btnText &&
                <div className={`center-action-btm`}>
                    <Button onClick={(evt: any) => props.btnOnClick ? props.btnOnClick(evt) : null}>{props.btnText}</Button>
                </div>
            }
            {props.customButton &&
                props.customButton
            }
        </div>
    );
};

export default CenterActionBlock
import { BIG_ZERO } from "containers/Prediction/config";
import {
    INIT_PRESALE_LINK,
    INIT_PRESALE_ADA,
    INIT_PRESALE_CAKE
} from "../actions/Presale";

const initialState: any = {
    LINK: {
        rate: BIG_ZERO,
        minPurchase: BIG_ZERO,
        maxPurchase: BIG_ZERO,
        rewardTokenCount: BIG_ZERO,
        availableTokensICO: BIG_ZERO,
        endICO: BIG_ZERO,
        withdrawAmount: BIG_ZERO,
        hasBeenFetched: false
    },
    ADA: {
        rate: BIG_ZERO,
        minPurchase: BIG_ZERO,
        maxPurchase: BIG_ZERO,
        rewardTokenCount: BIG_ZERO,
        availableTokensICO: BIG_ZERO,
        endICO: BIG_ZERO,
        withdrawAmount: BIG_ZERO,
        hasBeenFetched: false
    },
    CAKE: {
        rate: BIG_ZERO,
        minPurchase: BIG_ZERO,
        maxPurchase: BIG_ZERO,
        rewardTokenCount: BIG_ZERO,
        availableTokensICO: BIG_ZERO,
        endICO: BIG_ZERO,
        withdrawAmount: BIG_ZERO,
        hasBeenFetched: false
    },
};

export default function presaleReducer(state = initialState, action: any) {
    let changes;
    switch (action.type) {
        case INIT_PRESALE_LINK:
            changes = {
                ...state,
                LINK: { ...action.payload.LINK }
            };
            return changes;
        case INIT_PRESALE_ADA:
            changes = {
                ...state,
                ADA: { ...action.payload.ADA }
            };
            return changes;
        case INIT_PRESALE_CAKE:
            changes = {
                ...state,
                CAKE: { ...action.payload.CAKE }
            };
            return changes;
        default:
            return state;
    }
}

import { useGetCurrentRound } from "./useGetCurrentRound"
import { useGetIntervalSeconds } from "./useGetIntervalSeconds"

/**
* The current round's lock timestamp will not be set immediately so we return an estimate until then
*/
export const useGetCurrentRoundLockTimestamp = () => {
    const currentRound = useGetCurrentRound()
    const intervalSeconds = useGetIntervalSeconds()

    if (currentRound) {
        if (!currentRound.lockTimestamp) {
            return currentRound.startTimestamp + intervalSeconds
        }

        return currentRound.lockTimestamp
    } else {
        return 0;
    }
}

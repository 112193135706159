/* eslint-disable react-hooks/rules-of-hooks */
import { callbackActiveGame } from "helpers/SharedFunctions";
import { useSelector } from "react-redux"
import { useGetActiveGame } from "./useGetActiveGame";

export const useGetPredictionsStatus = () => {
  const activeGame = useGetActiveGame();
  let status = useSelector((state: any) => state.predictionBitcoinReducer.status);

  callbackActiveGame(
    activeGame,
    () => { // lINK
      status = useSelector((state: any) => state.predictionLinkReducer.status)
    },
    () => { // ADA
      status = useSelector((state: any) => state.predictionAdaReducer.status)
    },
    () => { // CAKE
      status = useSelector((state: any) => state.predictionCakeReducer.status)
    },
    () => { // BITCOIN
      status = useSelector((state: any) => state.predictionBitcoinReducer.status)
    },
    () => { // DEFAULT
      status = useSelector((state: any) => state.predictionBitcoinReducer.status)
    }
  );

  return status;
}
import { setLastOraclePriceADAUSDT } from 'actions/PredictionAda';
import { setLastOraclePriceCAKEUSDT } from 'actions/PredictionCake';
import { callbackActiveGame } from 'helpers/SharedFunctions';
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setLastOraclePriceBTCUSDT } from '../actions/PredictionBitcoin';
import { setLastOraclePriceLINKUSDT } from '../actions/PredictionLink';

import useGetLatestOraclePrice from './useGetLatestOraclePrice';

const usePollOraclePrice = (seconds = 10) => {
  const { price, refresh, activeGame } = useGetLatestOraclePrice()
  const dispatch = useDispatch()

  // Poll for the oracle price
  useEffect(() => {
    refresh()
    const timer = setInterval(() => {
      refresh()
    }, seconds * 1000)

    return () => {
      clearInterval(timer)
    }
  }, [refresh, seconds])

  // If the price changed update global state
  useEffect(() => {
    callbackActiveGame(
      activeGame,
      async () => { // lINK
        dispatch(setLastOraclePriceLINKUSDT(price));
      },
      () => { // ADA
        dispatch(setLastOraclePriceADAUSDT(price));
      },
      () => { // CAKE
        dispatch(setLastOraclePriceCAKEUSDT(price));
      },
      async () => {  // BITCOIN
        dispatch(setLastOraclePriceBTCUSDT(price));
      },
      async () => { // DEFAULT
        dispatch(setLastOraclePriceBTCUSDT(price));
      }
    );
  }, [price, dispatch, activeGame])
}

export default usePollOraclePrice

import React from 'react'
import Spinner from 'react-bootstrap/Spinner';

interface Props {
    id?: string;
    className?: string;
    showSpan?: boolean;
    children: React.ReactNode;
    extraChildren?: React.ReactNode;
    isLoading?: boolean;
    overrideIcon?: boolean;
    loadingElement?: React.ReactNode;
    onClick?: Function;
    icon?: React.ReactNode;
    disabled?: boolean;
    ref?: any,
    style?: any,
    type?: "button" | "submit" | "reset" | undefined;
}

function Button(props: Props) {
    const onClick = async (e: any) => {
        if (props.onClick) {
            props.onClick(e);
        }
        e.stopPropagation(); // prevent clickthrough
    }

    const fallbackLoadingElement = <Spinner animation="border" role="status"></Spinner>
    const loadingElement = props.loadingElement ? props.loadingElement : fallbackLoadingElement;
    const elementNextToEachOther = props.isLoading
        ? <><div>{loadingElement}</div> <span>{props.children}</span></>
        : props.showSpan === false ? props.children : <span>{props.children}</span>

    return (
        <button style={props.style} ref={props.ref} id={props.id} className={props.className} onClick={(e: any) => onClick(e)} type={props.type} disabled={props.disabled}>
            {props.icon ?
                <>
                    <div>{props.isLoading ? loadingElement : props.icon}</div>
                    {props.children}
                </>
                :
                props.overrideIcon ? props.isLoading ? loadingElement : props.children : <>{elementNextToEachOther}{props.extraChildren}</>
            }
        </button>
    )
}

export default Button;
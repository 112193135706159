import React, { useState } from 'react'
import Chip from 'components/Chip/Chip';
import Button from 'components/Button/Button';

import { RoundResult } from 'types/RoundResult';
import { BetPosition } from 'types/BetPosition';
import { Bet } from 'types/Bet';
import { callbackActiveGame, formatBnb, showToast } from 'helpers/SharedFunctions';
import { useGetIsClaimable } from 'hooks/useGetIsClaimable';
import { getToken, predictionADAContract, predictionBTCContract, predictionCAKEContract, predictionLINKContract } from 'utils/contracts';
import { sendTransaction } from 'utils/web3';
import { useWallet } from '@binance-chain/bsc-use-wallet';
import { useDispatch } from 'react-redux';
import { ContractName } from 'types/ContractName';
import { useGetActiveGame } from 'hooks/useGetActiveGame';
import { useGetActiveCurrency } from 'hooks/useGetActiveCurrency';
import { useGetCurrentEpoch } from 'hooks/useGetCurrentEpoch';
import { useGetEarliestEpoch } from 'hooks/useGetEarliestEpoch';

import { getNetPayout, getRoundResult, updatePredictionGame } from 'containers/Prediction/helper';
import { useGetPredictionsStatus } from 'hooks/useGetPredictionsStatus';
import { PredictionStatus } from 'types/PredictionStatus';
import { REWARD_RATE } from 'containers/Prediction/config';

interface Props {
    roundResult: RoundResult
    position: BetPosition
    bet: Bet,
    payout: number,
    resultTextPrefix: string
}

function HistoryCard(props: Props) {
    const { amount, round } = props.bet;
    const { account } = useWallet();
    const dispatch = useDispatch();
    const canClaim = useGetIsClaimable(round.epoch);
    const { ethereum }: { ethereum: any } = useWallet()
    const [btnIsLoading, setBtnIsLoading] = useState(false)
    const activeGame = useGetActiveGame();
    const currency = useGetActiveCurrency();
    const currentEpoch = useGetCurrentEpoch(activeGame);
    const earliestEpoch = useGetEarliestEpoch()
    const isOpenRound = round.epoch === currentEpoch
    const status = useGetPredictionsStatus()
    const roundResult = getRoundResult(props.bet, currentEpoch)
    const isLiveRound = status === PredictionStatus.LIVE && round.epoch === currentEpoch - 1;
    const payout = roundResult === RoundResult.WIN ? getNetPayout(props.bet, REWARD_RATE) : amount

    const getRoundPrefix = (result) => {
        if (result === RoundResult.LOSE) {
            return '-'
        }

        if (result === RoundResult.WIN) {
            return '+'
        }

        return ''
    }


    const resultTextPrefix = getRoundPrefix(roundResult);

    const collectRewards = async () => {
        setBtnIsLoading(true)
        try {
            let func: any;
            let address: string;
            callbackActiveGame(
                activeGame,
                () => { // lINK
                    address = getToken(ContractName.PredictionLINK).address;
                    func = predictionLINKContract.methods.claim([round.epoch]).encodeABI();
                },
                () => { // ADA
                    address = getToken(ContractName.PredictionADA).address;
                    func = predictionADAContract.methods.claim([round.epoch]).encodeABI();
                },
                () => { // CAKE
                    address = getToken(ContractName.PredictionCAKE).address;
                    func = predictionCAKEContract.methods.claim([round.epoch]).encodeABI();
                },
                () => {  // BITCOIN
                    address = getToken(ContractName.PredictionBTC).address;
                    func = predictionBTCContract.methods.claim([round.epoch]).encodeABI();
                },
                () => { // DEFAULT
                    address = getToken(ContractName.PredictionBTC).address;
                    func = predictionBTCContract.methods.claim([round.epoch]).encodeABI();
                }
            );
            await sendTransaction(ethereum, account, address, func, '0x0',
                async () => { // onSuccess
                    setBtnIsLoading(false);
                    // We have to mark the bet as claimed immediately because it does not update fast enough
                    updatePredictionGame(currentEpoch, earliestEpoch, activeGame, dispatch, account);
                    showToast('Successfully collected rewards', false);
                }, (err: any) => { // onError
                    if (err.code === 4001) {
                        showToast('Transaction has been denied', true);
                    } else {
                        showToast('Failed to collect rewards', true);
                    }
                    setBtnIsLoading(false);
                })
        } catch {
            setBtnIsLoading(false);
        }
    }

    const renderBetLabel = () => {
        if (isOpenRound) {
            return (
                <>
                    <div>Your Result</div>
                    <div className='txt-yellow'>
                        Starting Soon
                    </div>
                </>
            )
        }

        if (isLiveRound) {
            return (
                <>
                    <div>Your Result</div>
                    <div className='txt-green'>
                        Live
                    </div>
                </>
            )
        }

        return (
            <>
                <div>Your Result</div>
                <div className={`${resultTextPrefix === '+' ? 'txt-green' : 'txt-red'} `}>
                    {roundResult === RoundResult.CANCELED
                        ? 'Canceled'
                        : `${resultTextPrefix.replace('-', '').replace('+', '')}${formatBnb(payout).replace('-', '').replace('+', '')}`
                    }
                </div>
            </>
        )
    }

    return (
        <div className='card-winnings'>
            <div className='card-winnings-header'>
                <div className='lg-icon'>
                    {roundResult === RoundResult.WIN
                        ? <i className="fa-thin fa-trophy"></i>
                        : roundResult === RoundResult.CANCELED
                            ? <i className="fa-thin fa-circle-xmark"></i>
                            : roundResult === RoundResult.LIVE
                                ? <i className="fa-thin fa-tower-broadcast"></i>
                                : <i className="fa-thin fa-circle-xmark"></i>
                    }
                </div>
                <div className='card-header-info game'>
                    <span>
                        {props.roundResult === RoundResult.WIN
                            ? 'YOU WON'
                            : props.roundResult === RoundResult.LIVE ? 'LIVE GAME'
                                : 'YOU LOST'
                        }
                    </span>
                </div>
            </div>
            <div className='card-winnings-content'>
                <div className='card-winnings-item'>
                    <div>Your direction</div>
                    {props.position === BetPosition.BULL
                        ? <Chip color='green' txt='UP' />
                        : <Chip color='red' txt='DOWN' />
                    }
                </div>
                <div className='card-winnings-item'>
                    <div>Your position</div>
                    <div>{formatBnb(amount)} {currency}</div>
                </div>
                <div className='card-winnings-item'>
                    {renderBetLabel()}
                </div>
                {props.roundResult === RoundResult.WIN && canClaim ?
                    (
                        <Button className='button btn-main' isLoading={btnIsLoading} onClick={() => collectRewards()}>Collect</Button>
                    )
                    : props.roundResult === RoundResult.CANCELED && canClaim
                        ?
                        (
                            <Button className='button btn-main' isLoading={btnIsLoading} onClick={() => collectRewards()}>Reclaim</Button>
                        )
                        : props.roundResult === RoundResult.WIN && !canClaim && props.bet.claimed
                            ?
                            (
                                <Button className='button btn-main' isLoading={btnIsLoading} disabled>Already Collected</Button>
                            )
                            :
                            <Button className='button btn-main' isLoading={btnIsLoading} disabled>Not collectable</Button>
                }
                {/* !!! @TODO: ADD VIEW ON BSCSCAN AS FUTURE  */}
                {/* {props.bet.claimed && props.bet.claimedHash &&
                    <span>view on BscScan</span>
                } */}
            </div>
        </div>
    )
}

export default HistoryCard;
import { addresses } from './addresses';
import { networkId } from './config';
import { AbiItem } from 'web3-utils'
import { web3 } from './web3';
import { Interface } from '@ethersproject/abi'
import { Call } from '../types/Call';
import { ContractName } from 'types/ContractName';
import { Addresses } from 'types/Addresses';

export const getToken: Addresses = (token: ContractName) => {
  return addresses[token][networkId]
}

export const getContractOf = (ABI: any, address: string) => {
  const contract = new web3.eth.Contract((ABI as unknown) as AbiItem, address);
  return contract
}

export const multiCallContract = getContractOf(getToken(ContractName.MultiCall).abi, getToken(ContractName.MultiCall).address);
export const exchangeContract = getContractOf(getToken(ContractName.Exchange).abi, getToken(ContractName.Exchange).address);
export const pancakeFactoryContract = getContractOf(getToken(ContractName.PancakeFactory).abi, getToken(ContractName.PancakeFactory).address);
export const pancakeRouterContract = getContractOf(getToken(ContractName.PancakeRouter).abi, getToken(ContractName.PancakeRouter).address);

export const LucraLINKTokenContract = getContractOf(getToken(ContractName.LucraLINKToken).abi, getToken(ContractName.LucraLINKToken).address);
export const LucraADATokenContract = getContractOf(getToken(ContractName.LucraADAToken).abi, getToken(ContractName.LucraADAToken).address);
export const LucraCAKETokenContract = getContractOf(getToken(ContractName.LucraCAKEToken).abi, getToken(ContractName.LucraCAKEToken).address);

export const predictionBTCContract = getContractOf(getToken(ContractName.PredictionBTC).abi, getToken(ContractName.PredictionBTC).address);
export const predictionLINKContract = getContractOf(getToken(ContractName.PredictionLINK).abi, getToken(ContractName.PredictionLINK).address);
export const predictionADAContract = getContractOf(getToken(ContractName.PredictionADA).abi, getToken(ContractName.PredictionADA).address);
export const predictionCAKEContract = getContractOf(getToken(ContractName.PredictionCAKE).abi, getToken(ContractName.PredictionCAKE).address);

export const oraclePriceBTCUSDTContract = getContractOf(getToken(ContractName.OracleBTCUSDT).abi, getToken(ContractName.OracleBTCUSDT).address);
export const oraclePriceLINKUSDTContract = getContractOf(getToken(ContractName.OracleLINKUSDT).abi, getToken(ContractName.OracleLINKUSDT).address);
export const oraclePriceADAUSDTContract = getContractOf(getToken(ContractName.OracleADAUSDT).abi, getToken(ContractName.OracleADAUSDT).address);
export const oraclePriceCAKEUSDTContract = getContractOf(getToken(ContractName.OracleCAKEUSDT).abi, getToken(ContractName.OracleCAKEUSDT).address);

export const PresaleLucraLINKContract = getContractOf(getToken(ContractName.PresaleLucraLINK).abi, getToken(ContractName.PresaleLucraLINK).address);
export const PresaleLucraADAContract = getContractOf(getToken(ContractName.PresaleLucraADA).abi, getToken(ContractName.PresaleLucraADA).address);
export const PresaleLucraCAKEContract = getContractOf(getToken(ContractName.PresaleLucraCAKE).abi, getToken(ContractName.PresaleLucraCAKE).address);

/**
 * Multicall V2 uses the new "tryAggregate" function. It is different in 2 ways
 *
 * 1. If "requireSuccess" is false multicall will not bail out if one of the calls fails
 * 2. The return includes a boolean whether the call was successful e.g. [wasSuccessful, callResult]
 */
export type MultiCallResponse<T> = T | null;

export const multicallv2 = async <T = any>(abi: any[], calls: Call[]): Promise<MultiCallResponse<T>> => {
  const itf = new Interface(abi)

  const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
  const { returnData } = await multiCallContract.methods.aggregate(calldata).call()
  const res = returnData.map((call: any, i: number) => itf.decodeFunctionResult(calls[i].name, call))

  return res
}

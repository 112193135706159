import React, { useEffect, useState } from 'react'
import Showdown from '../../components/Showdown/Showdown';
import Card from '../../components/Card/Card';
import Button from '../../components/Button/Button';
import lucraCoin from '../../assets/images/Coin_Lucra.svg'
import lucraClock from '../../assets/images/Clock_Lucra.svg'
import SwiperClass from 'swiper/types/swiper-class';
import CenterActionBlock from '../../components/CenterActionBlock/CenterActionBlock';
import usePredictionDescription from 'hooks/usePredictionDescription';
import Timer from '../../components/Timer/Timer';
import Loader from 'components/Loader/Loader';

import { Swiper, SwiperSlide } from 'swiper/react';
import { useWallet } from '@binance-chain/bsc-use-wallet';
import { fetchNodeHistory, initializePredictions } from './helper';
import { useDispatch } from 'react-redux';
import { useInitialBlock } from '../../hooks/usePollBlockNumber';
import { usePredictionStatus } from '../../hooks/usePredictionStatus';
import { PredictionStatus } from '../../types/PredictionStatus';
import { useGetSortedRounds } from '../../hooks/useGetSortedRounds';
import { NodeRound } from '../../types/NodeRound';
import { useGetCurrentEpoch } from 'hooks/useGetCurrentEpoch';
import { useGetActiveGame } from 'hooks/useGetActiveGame';
import { callbackActiveGame } from 'helpers/SharedFunctions';
import { useSetActiveGame } from 'hooks/useSetActiveGame';
import { useGetGameIsInitialized } from 'hooks/useGetGameIsInitialized';

import { initHistory as initHistoryBitcoin, initPredictions as initPredictionsBitcoin, setBitcoinIsFetched, updateHistorySideMenuStatus as updateHistorySideMenuStatusBitcoin } from '../../actions/PredictionBitcoin';
import { initHistory as initHistoryLink, initPredictions as initPredictionsLink, setLinkIsFetched, updateHistorySideMenuStatus as updateHistorySideMenuStatusLink } from 'actions/PredictionLink';
import { initHistory as initHistoryAda, initPredictions as initPredictionsAda, setAdaIsFetched, updateHistorySideMenuStatus as updateHistorySideMenuStatusAda } from 'actions/PredictionAda';
import { initHistory as initHistoryCake, initPredictions as initPredictionsCake, setCakeIsFetched, updateHistorySideMenuStatus as updateHistorySideMenuStatusCake } from 'actions/PredictionCake';

function Prediction() {
    const { account }: any = useWallet();
    const { description } = usePredictionDescription()
    const [swiper, setSwiper] = useState<SwiperClass>();

    const isGameInitialized = useGetGameIsInitialized();
    const activeGame = useGetActiveGame();
    const dispatch = useDispatch()
    const predictionStatus = usePredictionStatus();
    const rounds = useGetSortedRounds();
    const initialBlock = useInitialBlock()
    const currentEpoch = useGetCurrentEpoch(activeGame);
    const previousEpoch = currentEpoch > 0 ? currentEpoch - 1 : currentEpoch
    const previousRound = rounds.find((round) => round.epoch === previousEpoch)
    const swiperIndex = rounds.indexOf(previousRound);
    useSetActiveGame();

    /**
    * @dev Is predictionStatus NOT undefined and predictionStatus PAUSED. Than add class 'game-paused'
    */
    const getMainWrapperClasses = () => {
        if ((predictionStatus !== undefined && predictionStatus === PredictionStatus.PAUSED) || (predictionStatus === undefined)) return 'game-paused';
        return '';
    }

    /**
    * @dev Map the rounds into the HTML
    */
    const renderRounds = () => {
        const lastRound = rounds[rounds.length - 1];
        const startTimestamp1 = lastRound && lastRound.startTimestamp ? lastRound.startTimestamp + (10 * 60) : 0;
        const startTimestamp2 = lastRound && lastRound.startTimestamp ? lastRound.startTimestamp + (20 * 60) : 0;
        const futureRounds = [];
        if (lastRound) {
            futureRounds.push({ epoch: lastRound.epoch + 1, startTimestamp: startTimestamp1, closeTimestamp: 1633090734, lockPrice: null });
            futureRounds.push({ epoch: lastRound.epoch + 2, startTimestamp: startTimestamp2, closeTimestamp: 1633090734, lockPrice: null });
        }
        const allRounds = rounds.concat(futureRounds);
        return (allRounds.map((round: NodeRound) => {
            return <SwiperSlide key={round.epoch}>
                <Card round={round} />
            </SwiperSlide>
        }))
    }

    /**
    * @dev Update swiper to the last live card when the swiper get's updated
    */
    const onSwiper = (swiper: SwiperClass) => {
        setSwiper(swiper);

        if (window.innerWidth <= 768)
            return swiper.slideTo(swiper.slides.length - 3);
        else
            return swiper.slideTo(swiper.slides.length - 2)
    }

    /**
    * @dev Init the game based on the active game
    */
    const initializeGame = async () => {
        const init = async (prediction: Function, history: Function, IsFetched: Function) => {
            const i = await initializePredictions(account, activeGame);
            dispatch(prediction(i));
            const h = await fetchNodeHistory(account, activeGame);
            dispatch(history(h));
            dispatch(IsFetched(true));
        }

        callbackActiveGame(
            activeGame,
            () => init(initPredictionsLink, initHistoryLink, setLinkIsFetched), // lINK
            () => init(initPredictionsAda, initHistoryAda, setAdaIsFetched), // ADA
            () => init(initPredictionsCake, initHistoryCake, setCakeIsFetched), // CAKE
            () => init(initPredictionsBitcoin, initHistoryBitcoin, setBitcoinIsFetched), // BITCOIN
            () => init(initPredictionsBitcoin, initHistoryBitcoin, setBitcoinIsFetched), // DEFAULT
        );
    }

    /**
    * @dev Open the history side menu
    */
    const toggleHistorySideMenu = () => {
        callbackActiveGame(
            activeGame,
            () => dispatch(updateHistorySideMenuStatusLink(true)), // lINK
            () => dispatch(updateHistorySideMenuStatusAda(true)), // ADA
            () => dispatch(updateHistorySideMenuStatusCake(true)), // CAKE
            () => dispatch(updateHistorySideMenuStatusBitcoin(true)), // BITCOIN
            () => dispatch(updateHistorySideMenuStatusBitcoin(true)), // DEFAULT
        );
    }

    useEffect(() => {
        // Making sure that web3 is working with the current RPC by checking the first block.
        if (initialBlock > 0 && !isGameInitialized) {
            initializeGame();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialBlock, account, activeGame, isGameInitialized])

    return (
        <div id='main-wrapper' className={`${getMainWrapperClasses()}`}>
            {(predictionStatus === undefined && !isGameInitialized) ?
                (
                    <Loader title='Connecting to Binance Smart Chain nodes...' description='Please have some patience. Refresh the page when it takes to long.' />
                )
                : predictionStatus !== undefined && predictionStatus === PredictionStatus.PAUSED ?
                    (
                        <CenterActionBlock
                            icon={<i className="fa-light fa-circle-pause"></i>}
                            title='Game has been paused'
                            description='Prediction Game under maintenance. Please try again later.'
                            customButton={<Button onClick={() => toggleHistorySideMenu()} className='button' >VIEW HISTORY</Button>}
                        />
                    )
                    :
                    (
                        <>
                            <div className='main-wrapper-top'>
                                <div className='showdown-wrapper'>
                                    <Showdown showPrice={true} image={lucraCoin} imageAlt='Lucra Coin' imageSide='left' size='lg' text={<>{description}</>} />
                                    <Showdown image={lucraClock} imageAlt='Lucra Clock' imageSide='right' size='sm' text={<Timer />} />
                                </div>
                            </div>
                            <div className='main-wrapper-content'>
                                <Swiper
                                    id='prediction-swiper'
                                    initialSlide={swiperIndex}
                                    onSwiper={(swiper: SwiperClass) => { onSwiper(swiper) }}
                                    onSlidesLengthChange={(swiper: SwiperClass) => onSwiper(swiper)}
                                    slidesPerView={window.innerWidth <= 768 ? 1 : window.innerWidth <= 1024 ? 2 : window.innerHeight <= 789 ? 3 : 4.3}
                                    freeMode
                                    freeModeSticky
                                    freeModeMomentumRatio={0.25}
                                    freeModeMomentumVelocityRatio={0.5}
                                    mousewheel
                                    keyboard
                                    resizeObserver>
                                    {renderRounds()}
                                </Swiper>
                            </div>
                        </>
                    )
            }
            {predictionStatus !== undefined && predictionStatus === PredictionStatus.LIVE &&
                (
                    <div className='nav-slider-wrap'>
                        <Button onClick={() => swiper?.slidePrev()} icon={<i className="fa-light fa-arrow-left-long"></i>} className='button b-btn' > </Button>
                        <Button onClick={() => toggleHistorySideMenu()} className='button b-btn' >VIEW HISTORY</Button>
                        <Button onClick={() => swiper?.slideNext()} icon={<i className="fa-light fa-arrow-right-long"></i>} className='button b-btn'> </Button>
                    </div>
                )
            }
        </div>
    )
}

export default Prediction;
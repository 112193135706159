/* eslint-disable react-hooks/rules-of-hooks */
import { callbackActiveGame } from 'helpers/SharedFunctions';
import { useSelector } from 'react-redux';
import { Games } from 'types/Games';

export const useGetMinPurchasePresale = (activeGame: Games) => {
    let minPurchase: any = "";

    callbackActiveGame(
        activeGame,
        () => { // lINK
            minPurchase = useSelector((state: any) => state.presaleReducer.LINK.minPurchase);
        },
        () => { // ADA
            minPurchase = useSelector((state: any) => state.presaleReducer.ADA.minPurchase);
        },
        () => { // CAKE
            minPurchase = useSelector((state: any) => state.presaleReducer.CAKE.minPurchase);
        },
        () => { }, // BITCOIN
        () => { // DEFAULT
            minPurchase = useSelector((state: any) => state.presaleReducer.LINK.minPurchase);
        }
    );

    return minPurchase;
}
/* eslint-disable react-hooks/rules-of-hooks */
import { callbackActiveGame } from "helpers/SharedFunctions";

import { useSelector } from "react-redux"
import { useGetActiveGame } from "./useGetActiveGame";

export const useGetHistoryFilter = () => {
  const activeGame = useGetActiveGame();
  let historyFilter = useSelector((state: any) => state.predictionBitcoinReducer.historyFilter);

  callbackActiveGame(
    activeGame,
    () => { // lINK
      historyFilter = useSelector((state: any) => state.predictionLinkReducer.historyFilter);
    },
    () => { // ADA
      historyFilter = useSelector((state: any) => state.predictionAdaReducer.historyFilter);
    },
    () => { // CAKE
      historyFilter = useSelector((state: any) => state.predictionCakeReducer.historyFilter);
    },
    () => { // BITCOIN
      historyFilter = useSelector((state: any) => state.predictionBitcoinReducer.historyFilter);
    },
    () => { // DEFAULT
      historyFilter = useSelector((state: any) => state.predictionBitcoinReducer.historyFilter);
    }
  );

  return historyFilter;
}
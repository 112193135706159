import React, { useCallback, useEffect, useState } from 'react'
import Button from 'components/Button/Button';
import bnbLogo from '../../assets/images/lucra-bnb-icon.svg'
import adaLogo from '../../assets/images/lucra-ada-icon.svg'
import linkLogo from '../../assets/images/lucra-link-icon.svg'
import MenuItem from '@mui/material/MenuItem';
import ConnectButton from 'components/ConnectButton/ConnectButton';
import BigNumber from 'bignumber.js';
import useGetAllowance from 'hooks/useGetAllowance';

import { Select } from '@material-ui/core';
import { useWallet } from '@binance-chain/bsc-use-wallet';
import { exchangeContract, getToken, LucraADATokenContract, LucraCAKETokenContract, LucraLINKTokenContract, pancakeRouterContract } from 'utils/contracts';
import { ContractName } from 'types/ContractName';
import { useGetLucraLinkBalance } from '../../hooks/useGetLucraLinkBalance';
import { sendTransaction } from 'utils/web3';
import { showToast } from 'helpers/SharedFunctions';

function Exchange() {
    const { account } = useWallet();
    const { ethereum }: { ethereum: any } = useWallet();
    const [swapIsLoading, setSwapIsLoading] = useState(false)
    const [exchangeFromToken, setExchangeFromToken] = useState(getToken(ContractName.LucraLINKToken).address)
    const [exchangeFromTokenName, setExchangeFromTokenName] = useState('Lucra LINK')
    const [exchangeFromTokenValue, setExchangeFromTokenValue] = useState(new BigNumber(0))
    const [exchangeToToken, setExchangeToToken] = useState('')
    const [exchangeToTokenName, setExchangeToTokenName] = useState('')
    const [exchangeToTokenValue, setExchangeToTokenValue] = useState(new BigNumber(0))
    const [priceDifference, setPriceDifference] = useState(new BigNumber(0))

    const allowanceLink = useGetAllowance(LucraLINKTokenContract, account, getToken(ContractName.Exchange).address);
    const allowanceCake = useGetAllowance(LucraCAKETokenContract, account, getToken(ContractName.Exchange).address);
    const allowanceAda = useGetAllowance(LucraADATokenContract, account, getToken(ContractName.Exchange).address);
    const { balance: lucraLinkBalance } = useGetLucraLinkBalance();

    const [approveIsLoading, setApproveIsLoading] = useState(false);

    const getAddressAndAbiOfToken = async (tokenAddress: string, isApprove?: boolean) => {
        const infinityApprove = new BigNumber(2).pow(256).minus(1).toString(10);
        let func: any
        let address: string;
        if (tokenAddress === getToken(ContractName.LucraLINKToken).address) {
            if (isApprove) {
                func = await LucraLINKTokenContract.methods.approve(getToken(ContractName.Exchange).address, infinityApprove).encodeABI();
            }
            address = getToken(ContractName.LucraLINKToken).address;
        } else if (tokenAddress === getToken(ContractName.LucraADAToken).address) {
            if (isApprove) {
                func = await LucraADATokenContract.methods.approve(getToken(ContractName.Exchange).address, infinityApprove).encodeABI();
            }
            address = getToken(ContractName.LucraADAToken).address;
        } else if (tokenAddress === getToken(ContractName.LucraCAKEToken).address) {
            if (isApprove) {
                func = await LucraCAKETokenContract.methods.approve(getToken(ContractName.Exchange).address, infinityApprove).encodeABI();
            }
            address = getToken(ContractName.LucraCAKEToken).address;
        }

        return {
            func,
            address,
            infinityApprove
        }
    }

    const approve = async () => {
        setApproveIsLoading(true);
        const { func, address } = await getAddressAndAbiOfToken(exchangeFromToken, true);
        try {
            await sendTransaction(ethereum, account, address, func, '0x0',
                async () => { // onSuccess
                    setApproveIsLoading(false);
                    showToast('Succesfully approved', false);
                }, (err: any) => { // onError
                    if (err.code === 4001) {
                        showToast('Transaction has been denied', true);
                    } else {
                        showToast('Something went wrong, try again later', true);
                    }
                    setApproveIsLoading(false);
                })
        } catch {
            setApproveIsLoading(false);
        }
    }

    const swapTokens = async () => {
        setSwapIsLoading(true);
        try {
            const path = [exchangeFromToken, getToken(ContractName.WBNB).address, exchangeToToken];
            const amount = new BigNumber(exchangeFromTokenValue).times(1e18);
            const func = await exchangeContract.methods.swapTokens(path, amount.toString(), '0', account).encodeABI();
            await sendTransaction(ethereum, account, getToken(ContractName.Exchange).address, func, '0x0',
                async () => { // onSuccess
                    setSwapIsLoading(false);
                    showToast('Succesfully swapped tokens', false);
                }, (data: any) => { // onError
                    setSwapIsLoading(false);
                    showToast('Something went wrong, try again later', true);
                })
        } catch {
            setSwapIsLoading(false);
        }
    }

    const switchTokens = () => {
        setExchangeFromToken(exchangeToToken);
        setExchangeToToken(exchangeFromToken);
    }

    const handleSelectExchangeFromToken = (ev: any) => {
        if (ev.target.value === exchangeToToken) {
            setExchangeToToken('');
            setExchangeFromToken(ev.target.value)
        } else {
            setExchangeFromToken(ev.target.value)
        }

        if (ev.target.value === getToken(ContractName.LucraLINKToken).address) {
            setExchangeFromTokenName('Lucra LINK')
        } else if (ev.target.value === getToken(ContractName.LucraADAToken).address) {
            setExchangeFromTokenName('Lucra ADA')
        } else if (ev.target.value === getToken(ContractName.LucraCAKEToken).address) {
            setExchangeFromTokenName('Lucra CAKE')
        }
    }

    const handleSelectExchangeToToken = (ev: any) => {
        if (ev.target.value === exchangeFromToken) {
            setExchangeFromToken('');
            setExchangeToToken(ev.target.value)
        } else {
            setExchangeToToken(ev.target.value)
        }

        if (ev.target.value === getToken(ContractName.LucraLINKToken).address) {
            setExchangeToTokenName('Lucra LINK')
        } else if (ev.target.value === getToken(ContractName.LucraADAToken).address) {
            setExchangeToTokenName('Lucra ADA')
        } else if (ev.target.value === getToken(ContractName.LucraCAKEToken).address) {
            setExchangeToTokenName('Lucra CAKE')
        }
    }

    const hasApproved = () => {
        if (exchangeFromToken === getToken(ContractName.LucraLINKToken).address && exchangeToToken.toString() !== '') {
            return new BigNumber(allowanceLink).isGreaterThan(0);
        } else if (exchangeFromToken === getToken(ContractName.LucraADAToken).address && exchangeToToken.toString() !== '') {
            return new BigNumber(allowanceAda).isGreaterThan(0);
        } else if (exchangeFromToken === getToken(ContractName.LucraCAKEToken).address && exchangeToToken.toString() !== '') {
            return new BigNumber(allowanceCake).isGreaterThan(0);
        }
        return false;
    }


    const swapButtonDisabled = () => {
        if (
            swapIsLoading ||
            exchangeFromTokenValue.toString() === '' ||
            exchangeToTokenValue.toString() === '' ||
            exchangeFromToken.toString() === '' ||
            exchangeToToken.toString() === '' ||
            new BigNumber(exchangeFromTokenValue).isLessThanOrEqualTo(0) ||
            new BigNumber(exchangeFromTokenValue).isGreaterThan(lucraLinkBalance.toString())
        ) {
            return true;
        }
        return false;
    }

    const getButtonText = () => {
        if (hasApproved() && new BigNumber(exchangeFromTokenValue).isGreaterThan(lucraLinkBalance.toString())) {
            return 'Insufficient balance';
        }
        return 'Swap';
    }

    const getSwapValues = useCallback(async () => {
        if (
            exchangeFromToken.toString() === '' ||
            exchangeToToken.toString() === '' ||
            new BigNumber(exchangeFromTokenValue).isLessThanOrEqualTo(0) ||
            exchangeFromTokenValue.toString() === ''
        ) return;

        // try {
            let exchangeTotalFees;
            if (exchangeFromToken === getToken(ContractName.LucraLINKToken).address) {
                exchangeTotalFees = await LucraLINKTokenContract.methods.exchangeTotalFees().call();
            } else if (exchangeFromToken === getToken(ContractName.LucraADAToken).address) {
                exchangeTotalFees = await LucraADATokenContract.methods.exchangeTotalFees().call();
            } else if (exchangeFromToken === getToken(ContractName.LucraCAKEToken).address) {
                exchangeTotalFees = await LucraCAKETokenContract.methods.exchangeTotalFees().call();
            }

            const path = [exchangeFromToken, getToken(ContractName.WBNB).address, exchangeToToken];
            const amount = new BigNumber(exchangeFromTokenValue).times(1e18).toString();
            const getAmountsOut = await pancakeRouterContract.methods.getAmountsOut(amount, path).call();
            if (getAmountsOut) {
                const exchangeAmount = new BigNumber(new BigNumber(getAmountsOut[2]).div(1e18));
                const pancakeSwapTradingFeeAndLucraFee = new BigNumber(exchangeAmount).div(100).times(0.25).plus(exchangeTotalFees);
                const feesToAmount = pancakeSwapTradingFeeAndLucraFee.div(100).times(exchangeAmount);
                const toValue = new BigNumber(exchangeAmount.minus(feesToAmount).toFixed(8));
                const priceDiff = new BigNumber(exchangeFromTokenValue).minus(toValue).plus(exchangeFromTokenValue);
                setPriceDifference(priceDiff)
                setExchangeToTokenValue(toValue);
            }

        // } catch {
        //     showToast('Something went wrong, try again later', true);
        // }

    }, [exchangeFromToken, exchangeFromTokenValue, exchangeToToken])

    useEffect(() => { getSwapValues() }, [getSwapValues])

    return (
        <div id='main-wrapper' className='exchange-wrapper align-center'>
            <div className='main-big-card'>
                <div className='main-big-card-top'>
                    <h3>EXCHANGE</h3>
                    <p>Trade tokens in an instant</p>
                </div>
                <div className='main-big-card-content'>
                    {/* FROM */}
                    <div className={`form-control ${!account ? 'disabled' : ''}`}>
                        <input value={exchangeFromTokenValue.toString()} placeholder='Amount' type="number" onChange={(ev: any) => setExchangeFromTokenValue(ev.target.value)} />
                        <div className='floating-btn'>
                            <Select
                                value={exchangeFromToken}
                                onChange={(ev: any) => handleSelectExchangeFromToken(ev)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}>
                                <MenuItem value="">
                                    Select token
                                </MenuItem>
                                <MenuItem value={getToken(ContractName.LucraLINKToken).address}>
                                    <img src={linkLogo} alt="Lucra LINK" />
                                    LCR LINK
                                </MenuItem>
                                <MenuItem value={getToken(ContractName.LucraCAKEToken).address}>
                                    <img src={bnbLogo} alt="Lucra CAKE" />
                                    LCR CAKE
                                </MenuItem>
                                <MenuItem value={getToken(ContractName.LucraADAToken).address}>
                                    <img src={adaLogo} alt="Lucra ADA" />
                                    LCR ADA
                                </MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className='exchange-swap'>
                        <Button disabled={!account} icon={<i className="fa-light fa-arrow-down-long"></i>} className='button b-btn' onClick={switchTokens}> </Button>
                    </div>
                    {/* TO */}
                    <div className={`form-control ${!account ? 'disabled' : ''}`}>
                        <input disabled value={exchangeToTokenValue.toString()} placeholder='Amount' type="number" onChange={(ev: any) => setExchangeToTokenValue(ev.target.value)} />
                        <div className='floating-btn'>
                            <Select
                                value={exchangeToToken}
                                onChange={(ev: any) => handleSelectExchangeToToken(ev)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}>
                                <MenuItem value="">
                                    Select token
                                </MenuItem>
                                <MenuItem value={getToken(ContractName.LucraLINKToken).address}>
                                    <img src={linkLogo} alt="Lucra LINK" />
                                    LCR LINK
                                </MenuItem>
                                <MenuItem value={getToken(ContractName.LucraCAKEToken).address}>
                                    <img src={bnbLogo} alt="Lucra CAKE" />
                                    LCR CAKE
                                </MenuItem>
                                <MenuItem value={getToken(ContractName.LucraADAToken).address}>
                                    <img src={adaLogo} alt="Lucra ADA" />
                                    LCR ADA
                                </MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>
                {priceDifference.isGreaterThan(0) &&
                    <div className='trade-price-wrapper'>
                        <span>Price: </span>
                        <span>{priceDifference.toString()} {exchangeFromTokenName} per {exchangeToTokenName}</span>
                    </div>
                }
                <div className={`main-big-card-btm ${hasApproved() || !account ? 'single-button' : ''}`}>
                    {account
                        ? <>
                            {!hasApproved() &&
                                <Button
                                    className={`button ${swapButtonDisabled() ? 'main-btn' : 'b-btn'}`}
                                    isLoading={approveIsLoading}
                                    onClick={() => approve()}>
                                    Approve
                                </Button>
                            }
                            <Button
                                disabled={swapButtonDisabled()}
                                className={`button ${!swapButtonDisabled() || hasApproved() ? 'main-btn' : 'b-btn'}`}
                                isLoading={swapIsLoading}
                                onClick={() => swapTokens()}>
                                {getButtonText()}
                            </Button>
                        </>
                        :
                        <ConnectButton enableTextDialog={true} />
                    }
                </div>
            </div>
        </div>
    )
}

export default Exchange;
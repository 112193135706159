import useCountdown from 'hooks/useCountdown';

import { ROUND_BUFFER } from 'containers/Prediction/config';
import { formatRoundTime } from 'containers/Prediction/helper';
import { GameStatus } from 'types/GameStatus';
import { NodeRound } from 'types/NodeRound';
import CenterActionBlock from 'components/CenterActionBlock/CenterActionBlock';

interface Props {
    round: NodeRound
}

function SoonRoundCard(props: Props) {
    const { epoch, startTimestamp } = props.round;
    const { secondsRemaining } = useCountdown(startTimestamp + ROUND_BUFFER)
    const countdown = formatRoundTime(secondsRemaining)

    return (
        <div className={`card-wrap is-calculating`}>
            <div className='card-header'>
                <div className='card-header-left'>
                    <div className='sm-icon'>
                        <i className="fa-thin fa-clock"></i>
                    </div>
                    <span>{GameStatus.LATER}</span>
                </div>
                <div className='card-header-right'>
                    <span>#{epoch}</span>
                </div>
            </div>
            <div className='card-content'>
                <div className='card-content-loader-wrap'>
                    <div className='card-content-loader-txt'>
                        <CenterActionBlock
                            icon={<i className="fa-thin fa-clock"></i>}
                            title={countdown}
                            description='Entry starts'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SoonRoundCard;
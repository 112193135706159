import _, { range } from 'lodash';
import getTimePeriods from 'helpers/getTimePeriods';

import { MarketData } from "../../types/MarketData"
import { PredictionStatus } from "../../types/PredictionStatus"
import { getToken, multicallv2, predictionADAContract, predictionBTCContract, predictionCAKEContract, predictionLINKContract } from "../../utils/contracts"
import { PAST_ROUND_COUNT, ROUNDS_PER_PAGE } from "./config"
import { PredictionsRoundsResponse } from "../../types/PredictionsRoundsResponse";
import { PredictionsLedgerResponse } from "../../types/PredictionsLedgerResponse";
import { ReduxNodeRound } from "../../types/ReduxNodeRound";
import { PredictionsState } from "../../types/PredictionsState";
import { PredictionsClaimableResponse } from "../../types/PredictionsClaimableResponse";
import { LedgerData } from "../../types/LedgerData";
import { BetPosition } from "../../types/BetPosition";
import { ReduxNodeLedger } from "../../types/ReduxNodeLedger";
import { ethers } from 'ethers'
import { NodeRound } from '../../types/NodeRound';
import { formatUnits } from 'ethers/lib/utils'
import { HistoryFilter } from 'types/HistoryFilter';
import { Bet } from 'types/Bet';
import { RoundResult } from 'types/RoundResult';
import { ContractName } from 'types/ContractName';
import { Games } from 'types/Games';
import { callbackActiveGame } from 'helpers/SharedFunctions';

import { fetchClaimableStatuses as fetchClaimableStatusesBitcoin, fetchLedgerData as fetchLedgerDataBitcoin, updateHistory as updateHistoryBitcoin, fetchRounds as fetchRoundsBitcoin, fetchMarketData as fetchMarketDataBitcoin } from '../../actions/PredictionBitcoin'
import { fetchClaimableStatuses as fetchClaimableStatusesLink, fetchLedgerData as fetchLedgerDataLink, updateHistory as updateHistoryLink, fetchRounds as fetchRoundsLink, fetchMarketData as fetchMarketDataLink } from '../../actions/PredictionLink'
import { fetchClaimableStatuses as fetchClaimableStatusesAda, fetchLedgerData as fetchLedgerDataAda, updateHistory as updateHistoryAda, fetchRounds as fetchRoundsAda, fetchMarketData as fetchMarketDataAda } from '../../actions/PredictionAda'
import { fetchClaimableStatuses as fetchClaimableStatusesCake, fetchLedgerData as fetchLedgerDataCake, updateHistory as updateHistoryCake, fetchRounds as fetchRoundsCake, fetchMarketData as fetchMarketDataCake } from '../../actions/PredictionCake'

export const getActiveContractDetails = (activeGame: Games) => {
    let address: string = getToken(ContractName.PredictionBTC).address;
    let abi = getToken(ContractName.PredictionBTC).abi;
    
    callbackActiveGame(
        activeGame,
        () => { // lINK
            address = getToken(ContractName.PredictionLINK).address;
            abi = getToken(ContractName.PredictionLINK).abi;
        },
        () => { // ADA
            address = getToken(ContractName.PredictionADA).address;
            abi = getToken(ContractName.PredictionADA).abi;
        },
        () => { // CAKE
            address = getToken(ContractName.PredictionCAKE).address;
            abi = getToken(ContractName.PredictionCAKE).abi;
        },
        () => { // BITCOIN
            address = getToken(ContractName.PredictionBTC).address;
            abi = getToken(ContractName.PredictionBTC).abi;
        },
        () => { // DEFAULT
            address = getToken(ContractName.PredictionBTC).address;
            abi = getToken(ContractName.PredictionBTC).abi;
        }
    );

    return { address, abi };
}

export const getPredictionData = async (activeGame: Games): Promise<MarketData> => {
    const { address, abi } = getActiveContractDetails(activeGame);
    const staticCalls = ['currentEpoch', 'intervalSeconds', 'minBetAmount', 'paused', 'bufferSeconds'].map((method) => ({ address, name: method }));
    const [[currentEpoch], [intervalSeconds], [minBetAmount], [paused], [bufferSeconds]] = await multicallv2(abi, staticCalls);
    return {
        status: paused ? PredictionStatus.PAUSED : PredictionStatus.LIVE,
        currentEpoch: currentEpoch.toNumber(),
        intervalSeconds: intervalSeconds.toNumber(),
        minBetAmount: minBetAmount.toString(),
        bufferSeconds: bufferSeconds.toNumber(),
    }
}

export const getRoundsData = async (epochs: number[], activeGame: Games): Promise<PredictionsRoundsResponse[]> => {
    const { address, abi } = getActiveContractDetails(activeGame);
    const calls = epochs.map((epoch) => ({ address, name: 'rounds', params: [epoch] }));
    const res: any = await multicallv2<PredictionsRoundsResponse[]>(abi, calls);
    return res
}

/**
 * Serializes the return from the "rounds" call for redux
 */
export const serializePredictionsRoundsResponse = (roundResponse: PredictionsRoundsResponse) => {
    const {
        epoch,
        startTimestamp,
        lockTimestamp,
        closeTimestamp,
        lockPrice,
        closePrice,
        totalAmount,
        bullAmount,
        bearAmount,
        rewardBaseCalAmount,
        rewardAmount,
        oracleCalled,
        lockOracleId,
        closeOracleId,
    } = roundResponse

    return {
        oracleCalled,
        epoch: epoch.toNumber(),
        startTimestamp: startTimestamp.eq(0) ? null : startTimestamp.toNumber(),
        lockTimestamp: lockTimestamp.eq(0) ? null : lockTimestamp.toNumber(),
        closeTimestamp: closeTimestamp.eq(0) ? null : closeTimestamp.toNumber(),
        lockPrice: lockPrice.eq(0) ? null : lockPrice.toJSON(),
        closePrice: closePrice.eq(0) ? null : closePrice.toJSON(),
        totalAmount: totalAmount.toJSON(),
        bullAmount: bullAmount.toJSON(),
        bearAmount: bearAmount.toJSON(),
        rewardBaseCalAmount: rewardBaseCalAmount.toJSON(),
        rewardAmount: rewardAmount.toJSON(),
        lockOracleId: lockOracleId.toString(),
        closeOracleId: closeOracleId.toString(),
    }
}

export const getLedgerData = async (account: string, epochs: number[], activeGame: Games) => {
    const { address, abi } = getActiveContractDetails(activeGame);
    const ledgerCalls = epochs.map((epoch) => ({ address, name: 'ledger', params: [epoch, account] }));
    const response = await multicallv2<PredictionsLedgerResponse[]>(abi, ledgerCalls)
    return response
}

export const getClaimStatuses = async (
    account: string,
    epochs: number[],
    activeGame: Games
): Promise<PredictionsState['claimableStatuses']> => {
    const { address, abi } = getActiveContractDetails(activeGame);
    const claimableCalls = epochs.map((epoch) => ({ address, name: 'claimable', params: [epoch, account] }));
    const claimableResponses: any = await multicallv2<[PredictionsClaimableResponse][]>(abi, claimableCalls)

    return claimableResponses.reduce((accum: any, claimableResponse: any, index: number) => {
        const epoch = epochs[index]
        const [claimable] = claimableResponse

        return {
            ...accum,
            [epoch]: claimable,
        }
    }, {})
}


export const serializePredictionsLedgerResponse = (ledgerResponse: PredictionsLedgerResponse): ReduxNodeLedger => ({
    position: ledgerResponse.position === 0 || ledgerResponse.position === '0' ? BetPosition.BULL : BetPosition.BEAR,
    amount: ledgerResponse.amount.toJSON(),
    claimed: ledgerResponse.claimed,
})

export const makeLedgerData = (account: string, ledgers: PredictionsLedgerResponse[], epochs: number[]): LedgerData => {
    return ledgers.reduce((accum: any, ledgerResponse, index) => {
        if (!ledgerResponse) {
            return accum
        }

        // If the amount is zero that means the user did not bet
        if (ledgerResponse.amount.eq(0)) {
            return accum
        }

        const epoch = epochs[index].toString()

        return {
            ...accum,
            [account]: {
                ...accum[account],
                [epoch]: serializePredictionsLedgerResponse(ledgerResponse),
            },
        }
    }, {})
}

export const initializePredictions = async (account = null, activeGame: Games) => {
    // Static values
    const marketData = await getPredictionData(activeGame);
    const epochs = marketData.currentEpoch > PAST_ROUND_COUNT ? _.range(marketData.currentEpoch, marketData.currentEpoch - PAST_ROUND_COUNT) : [marketData.currentEpoch];
    // Round data
    const roundsResponse = await getRoundsData(epochs, activeGame);
    const initialRoundData: { [key: string]: ReduxNodeRound } = roundsResponse.reduce((accum, roundResponse) => {
        const reduxNodeRound = serializePredictionsRoundsResponse(roundResponse)
        return {
            ...accum,
            [reduxNodeRound.epoch.toString()]: reduxNodeRound,
        }
    }, {})

    const initializedData = {
        ...marketData,
        rounds: initialRoundData,
        ledgers: {},
        claimableStatuses: {},
    }

    if (!account) {
        return initializedData
    }

    // Bet data
    const ledgerResponses: any = await getLedgerData(account, epochs, activeGame)

    // Claim statuses
    const claimableStatuses = await getClaimStatuses(account, epochs, activeGame)
    return _.merge({}, initializedData, { ledgers: makeLedgerData(account, ledgerResponses, epochs), claimableStatuses });
}

export const getMultiplierv2 = (total: ethers.BigNumber, amount: ethers.BigNumber) => {
    if (!total) {
        return ethers.FixedNumber.from(0)
    }

    if (total.eq(0) || amount.eq(0)) {
        return ethers.FixedNumber.from(0)
    }

    const rewardAmountFixed = ethers.FixedNumber.from(total)
    const multiplierAmountFixed = ethers.FixedNumber.from(amount)

    return rewardAmountFixed.divUnsafe(multiplierAmountFixed)
}


/*
* @dev Round is failed when closeTimestampMs !== null and current timestamp is higher than the closeTimeStamp
* and oracle hasn't been called
*/
export const getHasRoundFailed = (round: NodeRound | any, buffer: number) => {
    const closeTimestampMs = (round.closeTimestamp + buffer) * 1000
    const now = Date.now()

    if (closeTimestampMs !== null && now > closeTimestampMs && !round.oracleCalled) {
        return true
    }

    return false
}

export const getRoundPosition = (lockPrice: ethers.BigNumber, closePrice: ethers.BigNumber) => {
    if (!closePrice) {
        return null
    }

    if (closePrice.eq(lockPrice)) {
        return BetPosition.HOUSE
    }

    return closePrice.gt(lockPrice) ? BetPosition.BULL : BetPosition.BEAR
}

/**
* Method to format the display of wei given an ethers.BigNumber object with toFixed
* Note: rounds
*/
export const formatBigNumberToFixed = (number: ethers.BigNumber, displayDecimals = 18, decimals = 18) => {
    const formattedString = formatUnits(number, decimals)
    return (+formattedString).toFixed(displayDecimals)
}

export const getPriceDifference = (price: ethers.BigNumber, lockPrice: ethers.BigNumber) => {
    if (!price || !lockPrice) {
        return ethers.BigNumber.from(0)
    }

    return price.sub(lockPrice)
}


export const padTime = (num: number) => num.toString().padStart(2, '0')

export const formatRoundTime = (secondsBetweenBlocks: number) => {
    const { hours, minutes, seconds } = getTimePeriods(secondsBetweenBlocks)
    const minutesSeconds = `${padTime(minutes)}:${padTime(seconds)}`

    if (hours > 0) {
        return `${padTime(hours)}:${minutesSeconds}`
    }

    return minutesSeconds
}


export const getFilteredBets = (bets: any, filter: HistoryFilter) => {
    switch (filter) {
        case HistoryFilter.COLLECTED:
            return bets.bets.filter((bet) => bet.claimed === true)
        case HistoryFilter.UNCOLLECTED:
            return bets.bets.filter((bet) => {
                return !bet.claimed && (bet.position === bet.round.position || bet.round.failed === true)
            })
        case HistoryFilter.ALL:
        default:
            return bets
    }
}

export const fetchUsersRoundsLength = async (account: string, activeGame: Games) => {
    try {
        let contract: any = predictionBTCContract;
        callbackActiveGame(
            activeGame,
            () => contract = predictionLINKContract, // lINK
            () => contract = predictionADAContract, // ADA
            () => contract = predictionCAKEContract, // CAKE
            () => contract = predictionBTCContract, // BITCOIN
            () => contract = predictionBTCContract, // DEFAULT
        );
        const length = await contract.methods.getUserRoundsLength(account).call()
        return ethers.BigNumber.from(length)
    } catch {
        return ethers.BigNumber.from(0)
    }
}

/**
 * Fetches rounds a user has participated in
 */
export const fetchUserRounds = async (
    activeGame: Games,
    account: string,
    cursor = 0,
    size = ROUNDS_PER_PAGE,
): Promise<{ [key: string]: ReduxNodeLedger }> => {
    try {
        let contract: any = predictionBTCContract;
        callbackActiveGame(
            activeGame,
            () => contract = predictionLINKContract, // lINK
            () => contract = predictionADAContract, // ADA
            () => contract = predictionCAKEContract, // CAKE
            () => contract = predictionBTCContract, // BITCOIN
            () => contract = predictionBTCContract, // DEFAULT
        );
        const getUserRounds = await contract.methods.getUserRounds(account, cursor, size).call();
        const rounds = getUserRounds[0];
        const ledgers = getUserRounds[1];

        return rounds.reduce((accum, round, index) => {
            const ledgersSerialized = [];
            for (let i = 0; i < ledgers.length; i++) {
                ledgersSerialized.push({
                    amount: ethers.BigNumber.from(ledgers[i].amount),
                    claimed: ledgers[i].claimed,
                    position: ledgers[i].position
                })
            }
            return {
                ...accum,
                [round.toString()]: serializePredictionsLedgerResponse(ledgersSerialized[index]),
            }
        }, {})
    } catch {
        // When the results run out the contract throws an error.
        return null
    }
}


export const fetchNodeHistory = async (account: any, activeGame: Games, page: number = 1) => {
    const userRoundsLength = await fetchUsersRoundsLength(account, activeGame)
    const emptyResult = { bets: [], claimableStatuses: {}, totalHistory: userRoundsLength.toNumber() }
    const maxPages = userRoundsLength.lte(ROUNDS_PER_PAGE) ? 1 : Math.ceil(userRoundsLength.toNumber() / ROUNDS_PER_PAGE)

    if (userRoundsLength.eq(0)) {
        return emptyResult
    }

    if (page > maxPages) {
        return emptyResult
    }

    const cursor = userRoundsLength.sub(ROUNDS_PER_PAGE * page)

    // If the page request is the final one we only want to retrieve the amount of rounds up to the next cursor.
    const size =
        maxPages === page
            ? userRoundsLength
                .sub(ROUNDS_PER_PAGE * (page - 1)) // Previous page's cursor
                .toNumber()
            : ROUNDS_PER_PAGE
    const userRounds = await fetchUserRounds(activeGame, account, cursor.lt(0) ? 0 : cursor.toNumber(), size)

    if (!userRounds) {
        return emptyResult
    }

    const epochs = Object.keys(userRounds).map((epochStr) => Number(epochStr));
    const roundData = await getRoundsData(epochs, activeGame)
    const claimableStatuses = await getClaimStatuses(account, epochs, activeGame)

    const bets: Bet[] = roundData.reduce((accum, round) => {
        const reduxRound = serializePredictionsRoundsResponse(round)
        const ledger = userRounds[reduxRound.epoch]
        const ledgerAmount = ethers.BigNumber.from(ledger && ledger.amount ? ledger.amount : 0);
        const closePrice = round.closePrice ? parseFloat(formatUnits(round.closePrice, 8)) : null
        const lockPrice = round.lockPrice ? parseFloat(formatUnits(round.lockPrice, 8)) : null

        const getRoundPosition = () => {
            if (!closePrice) {
                return null
            }

            if (round.closePrice.eq(round.lockPrice)) {
                return BetPosition.HOUSE
            }

            return round.closePrice.gt(round.lockPrice) ? BetPosition.BULL : BetPosition.BEAR
        }

        return [
            ...accum,
            {
                id: null,
                hash: null,
                amount: parseFloat(formatUnits(ledgerAmount)),
                position: ledger && ledger.position,
                claimed: ledger && ledger.claimed,
                claimedAt: null,
                claimedHash: null,
                claimedBNB: 0,
                claimedNetBNB: 0,
                createdAt: null,
                updatedAt: null,
                block: 0,
                round: {
                    id: null,
                    epoch: round.epoch.toNumber(),
                    failed: false,
                    startBlock: null,
                    startAt: round.startTimestamp ? round.startTimestamp.toNumber() : null,
                    startHash: null,
                    lockAt: round.lockTimestamp ? round.lockTimestamp.toNumber() : null,
                    lockBlock: null,
                    lockPrice,
                    lockHash: null,
                    lockRoundId: round.lockOracleId ? round.lockOracleId.toString() : null,
                    closeRoundId: round.closeOracleId ? round.closeOracleId.toString() : null,
                    closeHash: null,
                    closeAt: null,
                    closePrice,
                    closeBlock: null,
                    totalBets: 0,
                    totalAmount: parseFloat(formatUnits(round.totalAmount)),
                    bullBets: 0,
                    bullAmount: parseFloat(formatUnits(round.bullAmount)),
                    bearBets: 0,
                    bearAmount: parseFloat(formatUnits(round.bearAmount)),
                    position: getRoundPosition(),
                },
            },
        ]
    }, [])

    return { bets, claimableStatuses, page, totalHistory: userRoundsLength.toNumber() }
}

export const getRoundResult = (bet: Bet, currentEpoch: number): RoundResult => {
    const { round } = bet
    if(round.closeRoundId === "0" && round.position === null) {
        return RoundResult.CANCELED
    }

    if (round.failed) {
        return RoundResult.CANCELED
    }

    if (round.epoch >= currentEpoch - 1) {
        return RoundResult.LIVE
    }
    const roundResultPosition = round.closePrice > round.lockPrice ? BetPosition.BULL : BetPosition.BEAR

    return bet.position === roundResultPosition ? RoundResult.WIN : RoundResult.LOSE
}

export const getMultiplier = (total: number, amount: number) => {
    if (total === 0 || amount === 0) {
        return 0
    }

    return total / amount
}

/**
 * Calculates the total payout given a bet
 */
export const getPayout = (bet: Bet, rewardRate = 1) => {
    if (!bet || !bet.round) {
        return 0
    }

    const { bullAmount, bearAmount, totalAmount } = bet.round
    const multiplier = getMultiplier(totalAmount, bet.position === BetPosition.BULL ? bullAmount : bearAmount)
    return bet.amount * multiplier * rewardRate
}

export const getNetPayout = (bet: Bet, rewardRate = 1): number => {
    if (!bet || !bet.round) {
        return 0
    }

    const payout = getPayout(bet, rewardRate)
    return payout - bet.amount
}


export const updatePredictionGame = (currentEpoch: any, earliestEpoch: any, activeGame: Games, dispatch: Function, account: any) => {
    const liveCurrentAndRecent = [currentEpoch, currentEpoch - 1, currentEpoch - 2]

    const updateRoundsAndMarketData = (fetchRounds: Function, fetchMarketData: Function) => {
        dispatch(fetchRounds(liveCurrentAndRecent, activeGame));
        dispatch(fetchMarketData(activeGame));
    }

    if (liveCurrentAndRecent && !liveCurrentAndRecent.includes(-1) && !liveCurrentAndRecent.includes(-2)) {
        callbackActiveGame(
            activeGame,
            () => { // lINK
                updateRoundsAndMarketData(fetchRoundsLink, fetchMarketDataLink);
            },
            () => { // ADA
                updateRoundsAndMarketData(fetchRoundsAda, fetchMarketDataAda);
            },
            () => { // CAKE
                updateRoundsAndMarketData(fetchRoundsCake, fetchMarketDataCake);
            },
            () => { // BITCOIN
                updateRoundsAndMarketData(fetchRoundsBitcoin, fetchMarketDataBitcoin);
            },
            () => { // DEFAULT
                updateRoundsAndMarketData(fetchRoundsBitcoin, fetchMarketDataBitcoin);
            }
        );
    }

    const updateFetchLedgerAndClaimableData = async (fetchLedgerData: Function, fetchClaimableStatuses: Function, updateHistory: Function, epochRange: number[]) => {
        dispatch(fetchLedgerData(account, epochRange))
        dispatch(fetchClaimableStatuses(account, epochRange))
        const nodeHistory = await fetchNodeHistory(account, activeGame);
        dispatch(updateHistory(nodeHistory))
    }

    if (account) {
        const epochRange = range(earliestEpoch, currentEpoch + 1);
        callbackActiveGame(
            activeGame,
            () => { // lINK
                updateFetchLedgerAndClaimableData(fetchLedgerDataLink, fetchClaimableStatusesLink, updateHistoryLink, epochRange);
            },
            () => { // ADA
                updateFetchLedgerAndClaimableData(fetchLedgerDataAda, fetchClaimableStatusesAda, updateHistoryAda, epochRange);
            },
            () => { // CAKE
                updateFetchLedgerAndClaimableData(fetchLedgerDataCake, fetchClaimableStatusesCake, updateHistoryCake, epochRange);
            },
            () => { // BITCOIN
                updateFetchLedgerAndClaimableData(fetchLedgerDataBitcoin, fetchClaimableStatusesBitcoin, updateHistoryBitcoin, epochRange);
            },
            () => { // DEFAULT
                updateFetchLedgerAndClaimableData(fetchLedgerDataBitcoin, fetchClaimableStatusesBitcoin, updateHistoryBitcoin, epochRange);
            }
        );
    }
}
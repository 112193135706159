import React from 'react'
import HistoryDropdown from 'components/HistoryDropdown/HistoryDropdown';
import _ from 'lodash';

import { Scrollbar } from "react-scrollbars-custom";
import { Bet } from 'types/Bet';

interface Props {
    hasBetHistory: boolean
    bets: Bet[] | any
}

function HistoryRounds(props: Props) {
    return (
        <div className='history-side-menu-content'>
            <Scrollbar>
                {_.orderBy(props.bets.bets ? props.bets.bets : props.bets, ['round.epoch'], ['desc']).map((bet: Bet, index: number) => {
                    return <HistoryDropdown key={bet.round.epoch} bet={bet} dataTarget={`collapse${index}`} />
                })}
            </Scrollbar>
        </div>
    )
}

export default HistoryRounds;
import { Games } from "types/Games";

export const SET_INITIAL_BLOCK = "SET_INITIAL_BLOCK";
export const SET_CURRENT_BLOCK = "SET_CURRENT_BLOCK";
export const SET_ACTIVE_GAME = "SET_ACTIVE_GAME";

export const setBlock = (block: number) => {
    return async (dispatch: Function, getState: any) => {
        const initialBlock = getState().globalReducer.initialBlock;
        if (initialBlock > 0) {
            dispatch({
                type: SET_CURRENT_BLOCK,
                payload: {
                    currentBlock: block
                }
            })
        } else {
            dispatch({
                type: SET_INITIAL_BLOCK,
                payload: {
                    initialBlock: block
                }
            })
        }
    }
}

export const setActiveGame = (game: Games) => {
    return async (dispatch: Function) => {
        dispatch({
            type: SET_ACTIVE_GAME,
            payload: {
                activeGame: game
            }
        })
    }
}
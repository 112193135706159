import React from 'react'
import { formatBigNumberToFixed } from '../../containers/Prediction/helper';
import { useGetLastOraclePrice } from '../../hooks/useGetLastOraclePrice';
import { useSpring, animated } from 'react-spring';
import { countNumbers } from '../../helpers/AnimationHelper';

interface Props {
    size: 'lg' | 'sm',
    imageSide: 'left' | 'right'
    image: any,
    imageAlt: string,
    text: React.ReactNode,
    showPrice?: boolean
}

function Showdown(props: Props) {
    const price = useGetLastOraclePrice();
    const priceAsNumber = parseFloat(formatBigNumberToFixed(price, 3, 8));
    const priceCount = useSpring(countNumbers(1000, 0, priceAsNumber, 0))

    return (
        <div className={`showdown-box showdown-${props.size} ${props.imageSide === 'left' ? 'showdown-img-left' : 'showdown-img-right'}`}>
            {props.imageSide === 'left' &&
                <div className='showdown-img'>
                    <img src={props.image} alt={props.imageAlt} />
                </div>
            }
            <div className='showdown-txt'>
                {props.text}&nbsp;
                {props.showPrice &&
                    <animated.b>
                        {priceCount.number.interpolate((val: any) => {
                            return '$' + val.toFixed(3);
                        })}
                    </animated.b>
                }
            </div>
            {props.imageSide === 'right' &&
                <div className='showdown-img'>
                    <img src={props.image} alt={props.imageAlt} />
                </div>
            }
        </div>
    )
}

export default Showdown;
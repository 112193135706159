/* eslint-disable react-hooks/rules-of-hooks */
import { callbackActiveGame } from "helpers/SharedFunctions";
import { useSelector } from "react-redux"
import { useGetActiveGame } from "./useGetActiveGame";

export const useGetBufferSeconds = () => {
  const activeGame = useGetActiveGame();
  let bufferSeconds = useSelector((state: any) => state.predictionBitcoinReducer.bufferSeconds)

  callbackActiveGame(
    activeGame,
    () => { // lINK
      bufferSeconds = useSelector((state: any) => state.predictionLinkReducer.bufferSeconds);
    },
    () => { // ADA
      bufferSeconds = useSelector((state: any) => state.predictionAdaReducer.bufferSeconds);
    },
    () => { // CAKE
      bufferSeconds = useSelector((state: any) => state.predictionCakeReducer.bufferSeconds);
    },
    () => { // BITCOIN
      bufferSeconds = useSelector((state: any) => state.predictionBitcoinReducer.bufferSeconds);
    },
    () => { // DEFAULT
      bufferSeconds = useSelector((state: any) => state.predictionBitcoinReducer.bufferSeconds);
    }
  );

  return bufferSeconds;
}

import { useWallet } from "@binance-chain/bsc-use-wallet"
import { ethers } from "ethers"
import { useEffect, useState } from "react"
import { FetchStatus } from "types/FetchStatus"
import { web3 } from "utils/web3"
import useLastUpdated from "./useLastUpdated"

export const useGetBnbBalance = () => {
    const [fetchStatus, setFetchStatus] = useState(FetchStatus.NOT_FETCHED)
    const [balance, setBalance] = useState(ethers.BigNumber.from(0))
    const { account } = useWallet()
    const { lastUpdated, setLastUpdated } = useLastUpdated()
  
    useEffect(() => {
      const fetchBalance = async () => {
        try {
          const walletBalance = await web3.eth.getBalance(account)
          setBalance(ethers.BigNumber.from(walletBalance))
          setFetchStatus(FetchStatus.SUCCESS)
        } catch {
          setFetchStatus(FetchStatus.FAILED)
        }
      }
  
      if (account) {
        fetchBalance()
      }
    }, [account, lastUpdated, setBalance, setFetchStatus])
  
    return { balance, fetchStatus, refresh: setLastUpdated }
  }
  
/* eslint-disable react-hooks/rules-of-hooks */
import { callbackActiveGame } from 'helpers/SharedFunctions';
import { useSelector } from 'react-redux';
import { Games } from 'types/Games';

export const useGetMaxPurchasePresale = (activeGame: Games) => {
    let maxPurchase: any = "";

    callbackActiveGame(
        activeGame,
        () => { // lINK
            maxPurchase = useSelector((state: any) => state.presaleReducer.LINK.maxPurchase);
        },
        () => { // ADA
            maxPurchase = useSelector((state: any) => state.presaleReducer.ADA.maxPurchase);
        },
        () => { // CAKE
            maxPurchase = useSelector((state: any) => state.presaleReducer.CAKE.maxPurchase);
        },
        () => { }, // BITCOIN
        () => { // DEFAULT
            maxPurchase = useSelector((state: any) => state.presaleReducer.LINK.maxPurchase);
        }
    );

    return maxPurchase;
}
import React, { useEffect } from 'react'
import useCountdown from 'hooks/useCountdown';

import { useGetCurrentRoundLockTimestamp } from 'hooks/useGetCurrentRoundLockTimestamp';
import { ROUND_BUFFER } from 'containers/Prediction/config';
import { formatRoundTime } from 'containers/Prediction/helper';

interface Props {
    callback?: Function
}

let timeout: any = null;

function Timer(props: Props) {
    const currentRoundLockTimestamp = useGetCurrentRoundLockTimestamp()
    const { secondsRemaining } = useCountdown(currentRoundLockTimestamp + ROUND_BUFFER)
    
    // secondsRemaining should not be negative:
    const seconds = secondsRemaining < 0 ? 0 : secondsRemaining;
    
    const countdown = formatRoundTime(seconds);

    useEffect(() => {
        if (props.callback && countdown === '00:00') {
            props.callback()
        }
        return () => {
            clearTimeout(timeout);
        }
    }, [countdown, props.callback, props])

    return (
        seconds > 0
            ? <b>{countdown}</b>
            : <b>Closing</b>
    )
}

export default Timer;
import React from 'react'
import { NodeRound } from '../../types/NodeRound';
import { GameStatus } from '../../types/GameStatus';
import { getRoundPosition } from '../../containers/Prediction/helper';
import { BetPosition } from '../../types/BetPosition';
import { ScaleLoader } from 'react-spinners';
import CenterActionBlock from 'components/CenterActionBlock/CenterActionBlock';

interface Props {
    round: NodeRound
    hasEnteredUp: boolean
    hasEnteredDown: boolean
}

function CalculatingCard(props: Props) {
    const { epoch, lockPrice, closePrice }: any = props.round
    const betPosition = getRoundPosition(lockPrice, closePrice);

    const getColorStatus = () => {
        if (betPosition === BetPosition.BULL) {
            return 'status-up';
        }
        if (betPosition === BetPosition.BEAR) {
            return 'status-down';
        }
        if (betPosition === null) {
            return 'status-down';
        }
        return '';
    }

    return (
        <div className={`card-wrap is-calculating ${getColorStatus()}`}>
            <div className='card-header'>
                <div className='card-header-left'>
                    <div className='sm-icon'>
                        <i className="fa-thin fa-clock-five"></i>
                    </div>
                    <span>{GameStatus.CALCULATING}</span>
                </div>
                <div className='card-header-right'>
                    <span>#{epoch}</span>
                </div>
            </div>
            <div className='card-content'>
                <div className='card-content-loader-wrap'>
                    <div className='card-content-loader'>
                        <ScaleLoader radius={20} color='#FFFFFF48' />
                    </div>
                    <div className='card-content-loader-txt'>
                        <CenterActionBlock title='Calculating' description='This round’s closing transaction has been submitted to the blockchain, and is awaiting confirmation.' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CalculatingCard;
export enum GameStatus {
    LIVE = 'LIVE',
    EXPIRED = 'EXPIRED',
    NEXT = 'NEXT',
    FAILED = 'FAILED',
    CANCELED = 'CANCELED',
    CALCULATING = 'CALCULATING',
    LATER = 'LATER',
    COLLECT = 'COLLECT',
    SET_POSITION = 'SET POSITION'
}
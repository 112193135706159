import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { oraclePriceADAUSDTContract, oraclePriceBTCUSDTContract, oraclePriceCAKEUSDTContract, oraclePriceLINKUSDTContract } from '../utils/contracts'
import { callbackActiveGame } from 'helpers/SharedFunctions'
import { useGetActiveGame } from './useGetActiveGame'

import useLastUpdated from './useLastUpdated'

const useGetLatestOraclePrice = () => {
  const [price, setPrice] = useState(ethers.BigNumber.from(0))
  const { lastUpdated, setLastUpdated: refresh } = useLastUpdated()
  const activeGame = useGetActiveGame();

  useEffect(() => {
    const fetchPrice = () => {
      callbackActiveGame(
        activeGame,
        async () => { // lINK
          const response = await oraclePriceLINKUSDTContract.methods.latestAnswer().call();
          setPrice(response)
        },
        async () => { // ADA
          const response = await oraclePriceADAUSDTContract.methods.latestAnswer().call();
          setPrice(response)
        },
        async () => { // CAKE
          const response = await oraclePriceCAKEUSDTContract.methods.latestAnswer().call();
          setPrice(response)
        },
        async () => {  // BITCOIN
          const response = await oraclePriceBTCUSDTContract.methods.latestAnswer().call();
          setPrice(response)
        },
        async () => { // DEFAULT
          const response = await oraclePriceBTCUSDTContract.methods.latestAnswer().call();
          setPrice(response)
        }
      );
    }

    fetchPrice()
  }, [activeGame, lastUpdated, setPrice])

  return { price, lastUpdated, refresh, activeGame }
}

export default useGetLatestOraclePrice

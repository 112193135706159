/* eslint-disable react-hooks/rules-of-hooks */
import { callbackActiveGame } from 'helpers/SharedFunctions';
import { useSelector } from 'react-redux';
import { Games } from 'types/Games';

export const useGetRewardTokenCountPresale = (activeGame: Games) => {
    let rewardTokenCount: any = '';

    callbackActiveGame(
        activeGame,
        () => { // lINK
            rewardTokenCount = useSelector((state: any) => state.presaleReducer.LINK.rewardTokenCount);
        },
        () => { // ADA
            rewardTokenCount = useSelector((state: any) => state.presaleReducer.ADA.rewardTokenCount);
        },
        () => { // CAKE
            rewardTokenCount = useSelector((state: any) => state.presaleReducer.CAKE.rewardTokenCount);
        },
        () => { }, // BITCOIN
        () => { // DEFAULT
            rewardTokenCount = useSelector((state: any) => state.presaleReducer.LINK.rewardTokenCount);
        }
    );

    return rewardTokenCount;
}
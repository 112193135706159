/* eslint-disable react-hooks/rules-of-hooks */
import { callbackActiveGame } from "helpers/SharedFunctions";
import { useSelector } from "react-redux"
import { useGetActiveGame } from "./useGetActiveGame";

export const useGetHistory = () => {
  const activeGame = useGetActiveGame();
  let history = useSelector((state: any) => state.predictionBitcoinReducer.history)

  callbackActiveGame(
    activeGame,
    () => { // lINK
      history = useSelector((state: any) => state.predictionLinkReducer.history)
    },
    () => { // ADA
      history = useSelector((state: any) => state.predictionAdaReducer.history)
    },
    () => { // CAKE
      history = useSelector((state: any) => state.predictionCakeReducer.history)
    },
    () => { // BITCOIN
      history = useSelector((state: any) => state.predictionBitcoinReducer.history)
    },
    () => { // DEFAULT
      history = useSelector((state: any) => state.predictionBitcoinReducer.history)
    }
  );

  return history;
}
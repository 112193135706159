/* eslint-disable react-hooks/rules-of-hooks */

import { callbackActiveGame } from "helpers/SharedFunctions";
import { useSelector } from "react-redux"
import { useGetActiveGame } from "./useGetActiveGame";

export const useGetGameIsInitialized = () => {
    const activeGame = useGetActiveGame();
    let isFetched = useSelector((state: any) => state.predictionBitcoinReducer.isFetched);

    callbackActiveGame(
        activeGame,
        () => { // lINK
            isFetched = useSelector((state: any) => state.predictionLinkReducer.isFetched);
        },
        () => { // ADA
            isFetched = useSelector((state: any) => state.predictionAdaReducer.isFetched);
        },
        () => { // CAKE
            isFetched = useSelector((state: any) => state.predictionCakeReducer.isFetched);
        },
        () => { // BITCOIN
            isFetched = useSelector((state: any) => state.predictionBitcoinReducer.isFetched);
        },
        () => { // DEFAULT
            isFetched = useSelector((state: any) => state.predictionBitcoinReducer.isFetched);
        }
    );

    return isFetched;
}
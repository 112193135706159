import React from 'react'
import LiveRoundCard from '../LiveRoundCard/LiveRoundCard';
import OpenRoundCard from '../OpenRoundCard/OpenRoundCard';
import ExpiredRoundCard from '../ExpiredRoundCard/ExpiredRoundCard';

import { NodeRound } from '../../types/NodeRound';
import { useGetCurrentEpoch } from '../../hooks/useGetCurrentEpoch';
import { useGetBetByEpoch } from '../../hooks/useGetBetByEpoch';
import { useWallet } from '@binance-chain/bsc-use-wallet';
import { BetPosition } from '../../types/BetPosition';
import { getMultiplierv2 } from '../../containers/Prediction/helper';
import SoonRoundCard from 'components/SoonRoundCard/SoonRoundCard';
import { useGetActiveGame } from 'hooks/useGetActiveGame';

interface Props {
    round: NodeRound
}

function Card(props: Props) {
    const { epoch, lockPrice, closePrice, totalAmount, bullAmount, bearAmount } = props.round;
    const { account }: any = useWallet();
    const activeGame = useGetActiveGame();
    const currentEpoch = useGetCurrentEpoch(activeGame);
    const ledger: any = useGetBetByEpoch(account, epoch);
    const hasEntered = ledger ? ledger.amount.gt(0) : false;
    const hasEnteredUp = hasEntered && ledger.position === BetPosition.BULL;
    const hasEnteredDown = hasEntered && ledger.position === BetPosition.BEAR;
    const hasClaimedUp = hasEntered && ledger.claimed && ledger.position === BetPosition.BULL;
    const hasClaimedDown = hasEntered && ledger.claimed && ledger.position === BetPosition.BEAR;

    const bullMultiplier = getMultiplierv2(totalAmount, bullAmount)
    const bearMultiplier = getMultiplierv2(totalAmount, bearAmount)
    const formattedBullMultiplier = bullMultiplier.toUnsafeFloat().toFixed(bullMultiplier.isZero() ? 0 : 2)
    const formattedBearMultiplier = bearMultiplier.toUnsafeFloat().toFixed(bearMultiplier.isZero() ? 0 : 2)

    // Next (open) round
    if (epoch === currentEpoch && lockPrice === null) {
        return (
            <OpenRoundCard
                round={props.round}
                hasEnteredDown={hasEnteredDown}
                hasEnteredUp={hasEnteredUp}
                betAmount={ledger?.amount}
                bullMultiplier={formattedBullMultiplier}
                bearMultiplier={formattedBearMultiplier}
            />
        )
    }

    // Live round
    if ((closePrice === null || closePrice === undefined) && epoch === currentEpoch - 1) {
        return (
            <LiveRoundCard
                round={props.round}
                hasEnteredDown={hasEnteredDown}
                hasEnteredUp={hasEnteredUp}
                betAmount={ledger?.amount}
                bullMultiplier={formattedBullMultiplier}
                bearMultiplier={formattedBearMultiplier}
            />
        )
    }

    // Fake future rounds
    if (epoch > currentEpoch) {
        return <SoonRoundCard round={props.round} />
    }

    // Expired round
    return (
        <ExpiredRoundCard
            round={props.round}
            hasEnteredDown={hasEnteredDown}
            hasEnteredUp={hasEnteredUp}
            hasClaimedDown={hasClaimedDown}
            hasClaimedUp={hasClaimedUp}
            betAmount={ledger?.amount}
            bullMultiplier={formattedBullMultiplier}
            bearMultiplier={formattedBearMultiplier}
        />
    )
}

export default Card;
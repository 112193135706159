import { useEffect, useRef } from 'react'
import { range } from 'lodash'
import { useDispatch } from 'react-redux'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { useGetCurrentEpoch } from './useGetCurrentEpoch'
import { useGetEarliestEpoch } from './useGetEarliestEpoch'
import { useGetPredictionsStatus } from './useGetPredictionsStatus'
import { PredictionStatus } from '../types/PredictionStatus'
import { fetchNodeHistory } from 'containers/Prediction/helper'
import { useGetActiveGame } from './useGetActiveGame'
import { callbackActiveGame } from 'helpers/SharedFunctions'

import { fetchClaimableStatuses as fetchClaimableStatusesBitcoin, fetchLedgerData as fetchLedgerDataBitcoin, updateHistory as updateHistoryBitcoin, fetchRounds as fetchRoundsBitcoin, fetchMarketData as fetchMarketDataBitcoin } from 'actions/PredictionBitcoin'
import { fetchClaimableStatuses as fetchClaimableStatusesLink, fetchLedgerData as fetchLedgerDataLink, updateHistory as updateHistoryLink, fetchRounds as fetchRoundsLink, fetchMarketData as fetchMarketDataLink } from 'actions/PredictionLink'
import { fetchClaimableStatuses as fetchClaimableStatusesAda, fetchLedgerData as fetchLedgerDataAda, updateHistory as updateHistoryAda, fetchRounds as fetchRoundsAda, fetchMarketData as fetchMarketDataAda } from 'actions/PredictionAda'
import { fetchClaimableStatuses as fetchClaimableStatusesCake, fetchLedgerData as fetchLedgerDataCake, updateHistory as updateHistoryCake, fetchRounds as fetchRoundsCake, fetchMarketData as fetchMarketDataCake} from 'actions/PredictionCake'

const POLL_TIME_IN_SECONDS = 10

const usePollPredictions = () => {
  const timer: any = useRef<NodeJS.Timeout>(null)
  const dispatch = useDispatch()
  const { account } = useWallet()
  const activeGame = useGetActiveGame();
  const currentEpoch = useGetCurrentEpoch(activeGame);
  const earliestEpoch = useGetEarliestEpoch()
  const status = useGetPredictionsStatus()

  useEffect(() => {
    // Clear old timer
    if (timer.current) {
      clearInterval(timer.current)
    }

    if (status !== PredictionStatus.INITIAL) {
      timer.current = setInterval(async () => {
        const liveCurrentAndRecent = [currentEpoch, currentEpoch - 1, currentEpoch - 2]

        const updateRoundsAndMarketData = (fetchRounds: Function, fetchMarketData: Function) => {
          dispatch(fetchRounds(liveCurrentAndRecent, activeGame));
          dispatch(fetchMarketData(activeGame));
        }

        if (liveCurrentAndRecent && !liveCurrentAndRecent.includes(-1) && !liveCurrentAndRecent.includes(-2)) {
          callbackActiveGame(
            activeGame,
            () => { // lINK
              updateRoundsAndMarketData(fetchRoundsLink, fetchMarketDataLink);
            },
            () => { // ADA
              updateRoundsAndMarketData(fetchRoundsAda, fetchMarketDataAda);
            },
            () => { // CAKE
              updateRoundsAndMarketData(fetchRoundsCake, fetchMarketDataCake);
            },
            () => { // BITCOIN
              updateRoundsAndMarketData(fetchRoundsBitcoin, fetchMarketDataBitcoin);
            },
            () => { // DEFAULT
              updateRoundsAndMarketData(fetchRoundsBitcoin, fetchMarketDataBitcoin);
            }
          );
        }

        const updateFetchLedgerAndClaimableData = async (fetchLedgerData: Function, fetchClaimableStatuses: Function, updateHistory: Function, epochRange: number[]) => {
          dispatch(fetchLedgerData(account, epochRange))
          dispatch(fetchClaimableStatuses(account, epochRange))
          const nodeHistory = await fetchNodeHistory(account, activeGame);
          dispatch(updateHistory(nodeHistory))
        }

        if (account) {
          const epochRange = range(earliestEpoch, currentEpoch + 1);
          callbackActiveGame(
            activeGame,
            () => { // lINK
              updateFetchLedgerAndClaimableData(fetchLedgerDataLink, fetchClaimableStatusesLink, updateHistoryLink, epochRange);
            },
            () => { // ADA
              updateFetchLedgerAndClaimableData(fetchLedgerDataAda, fetchClaimableStatusesAda, updateHistoryAda, epochRange);
            },
            () => { // CAKE
              updateFetchLedgerAndClaimableData(fetchLedgerDataCake, fetchClaimableStatusesCake, updateHistoryCake, epochRange);
            },
            () => { // BITCOIN
              updateFetchLedgerAndClaimableData(fetchLedgerDataBitcoin, fetchClaimableStatusesBitcoin, updateHistoryBitcoin, epochRange);
            },
            () => { // DEFAULT
              updateFetchLedgerAndClaimableData(fetchLedgerDataBitcoin, fetchClaimableStatusesBitcoin, updateHistoryBitcoin, epochRange);
            }
          );
        }
      }, POLL_TIME_IN_SECONDS * 1000)
    }

    return () => {
      if (timer.current) {
        clearInterval(timer.current)
      }
    }
  }, [timer, account, status, currentEpoch, earliestEpoch, dispatch, activeGame])
}

export default usePollPredictions

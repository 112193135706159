import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';

export const PAST_ROUND_COUNT = 5;
export const DISPLAYED_DECIMALS = 3;
export const MIN_PRICE_USD_DISPLAYED = ethers.BigNumber.from(100000);
export const MIN_PRICE_BNB_DISPLAYED = ethers.BigNumber.from('1000000000000000');
export const BIG_ZERO = new BigNumber(0);
// Estimated number of seconds it takes to submit a transaction (3 blocks) in seconds
export const ROUND_BUFFER = 9
export const ROUNDS_PER_PAGE = 1000
export const REWARD_RATE = 0.97;
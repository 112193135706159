/* eslint-disable react-hooks/rules-of-hooks */
import { callbackActiveGame } from 'helpers/SharedFunctions';
import { useSelector } from 'react-redux';
import { Games } from 'types/Games';

export const useGetPresaleEndICO = (activeGame: Games) => {
    let endICO = "0";

    callbackActiveGame(
        activeGame,
        () => { // lINK
            endICO = useSelector((state: any) => state.presaleReducer.LINK.endICO);
        },
        () => { // ADA
            endICO = useSelector((state: any) => state.presaleReducer.ADA.endICO);
        },
        () => { // CAKE
            endICO = useSelector((state: any) => state.presaleReducer.CAKE.endICO);
        },
        () => { }, // BITCOIN
        () => { // DEFAULT
            endICO = useSelector((state: any) => state.presaleReducer.LINK.endICO);
        }
    );

    return endICO;
}
import React, { useState } from 'react'
import { Bet } from 'types/Bet';
import { getMultiplier, getNetPayout, getRoundResult } from 'containers/Prediction/helper';
import { useGetCurrentEpoch } from 'hooks/useGetCurrentEpoch';
import { RoundResult } from 'types/RoundResult';
import { REWARD_RATE } from 'containers/Prediction/config';
import { formatBnb, formatUsd } from 'helpers/SharedFunctions';

import HistoryCard from 'components/HistoryCard/HistoryCard';
import Chip from 'components/Chip/Chip';
import BigNumber from 'bignumber.js';
import { useGetActiveGame } from 'hooks/useGetActiveGame';
import { useGetActiveCurrency } from 'hooks/useGetActiveCurrency';
import { useGetPredictionsStatus } from 'hooks/useGetPredictionsStatus';
import { PredictionStatus } from 'types/PredictionStatus';

interface Props {
    dataTarget: string,
    bet: Bet
}

function HistoryDropdown(props: Props) {
    const { amount, round, position } = props.bet;
    const { lockPrice, closePrice, totalAmount, bullAmount, bearAmount } = round;
    const activeGame = useGetActiveGame();
    const currency = useGetActiveCurrency();
    const currentEpoch = useGetCurrentEpoch(activeGame);
    const roundResult = getRoundResult(props.bet, currentEpoch)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const priceDifference = closePrice - lockPrice

    const getRoundPrefix = (result) => {
        if (result === RoundResult.LOSE) {
            return '-'
        }

        if (result === RoundResult.WIN) {
            return '+'
        }

        return ''
    }

    // Winners get the payout, otherwise the claim what they put it if it was canceled
    const resultTextPrefix = getRoundPrefix(roundResult)
    const payout = roundResult === RoundResult.WIN ? getNetPayout(props.bet, REWARD_RATE) : amount
    const getRoundStatus = new BigNumber(formatUsd(priceDifference)).isGreaterThan(0);
    const bullMultiplier = getMultiplier(totalAmount, bullAmount)
    const bearMultiplier = getMultiplier(totalAmount, bearAmount)
    const isOpenRound = round.epoch === currentEpoch
    const status = useGetPredictionsStatus()
    const isLiveRound = status === PredictionStatus.LIVE && round.epoch === currentEpoch - 1

    const payoutRow = (multiplier, amount) => {
        const formattedMultiplier = `${multiplier.toLocaleString(undefined, { maximumFractionDigits: 2 })}x Payout`
        return formattedMultiplier + ' / ' + formatBnb(amount) + ' ' + currency;
    }

    const renderBetLabel = () => {
        if (isOpenRound) {
            return (
                <>
                    <span>Your Result</span>
                    <span className='txt-yellow'>
                        Starting Soon
                    </span>
                </>
            )
        }

        if (isLiveRound) {
            return (
                <>
                    <span>Your Result</span>
                    <span className='txt-green'>
                        Live
                    </span>
                </>
            )
        }

        return (
            <>
                <span>Your Result</span>
                <span className={`${resultTextPrefix === '+' ? 'txt-green' : 'txt-red'} `}>
                    {roundResult === RoundResult.CANCELED
                        ? 'Canceled'
                        : `${resultTextPrefix.replace('-', '').replace('+', '')}${formatBnb(payout).replace('-', '').replace('+', '')}`
                    }
                </span>
            </>
        )
    }

    return (
        <div className={`accordion ${isDropdownOpen ? 'active' : ''} ${roundResult === RoundResult.LIVE ? 'live' : ''}`}>
            <div className="card history-dropdown">
                <div className="card-header" data-toggle="collapse" data-target={'#' + props.dataTarget} aria-expanded="true" aria-controls={props.dataTarget} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                    <div className='sm-icon'>
                        {roundResult === RoundResult.WIN
                            ? <i className="fa-thin fa-trophy"></i>
                            : roundResult === RoundResult.CANCELED
                                ? <i className="fa-thin fa-circle-xmark"></i>
                                : roundResult === RoundResult.LIVE
                                    ? <i className="fa-thin fa-tower-broadcast"></i>
                                    : <i className="fa-thin fa-circle-xmark"></i>
                        }
                    </div>
                    <div className='card-header-info'>
                        <span>Round</span>
                        <span className='txt-white'>{round.epoch}</span>
                    </div>
                    <div className='card-header-info'>
                        {renderBetLabel()}
                    </div>
                    <div className='sm-icon'>
                        <i className="fa-thin fa-angle-down"></i>
                    </div>
                </div>
                <div id={props.dataTarget} className="collapse">
                    <div className="card-body">
                        <h3>Your History</h3>
                        <HistoryCard
                            resultTextPrefix={resultTextPrefix}
                            bet={props.bet}
                            payout={payout}
                            roundResult={roundResult}
                            position={position}
                        />
                        <h3>Round History</h3>
                        <div className={`vote-info ${getRoundStatus ? 'status-up' : 'status-down'} `}>
                            <div className='vote-info-top'>
                                <div className='vote-info-top-left'>
                                    <span className='info-txt'>Closed price:</span>
                                    <h3>{formatUsd(closePrice)}</h3>
                                </div>
                                <div className='vote-info-top-right'>
                                    <Chip color={getRoundStatus ? 'green' : 'red'} txt={round.failed ? 'Canceled' : formatUsd(priceDifference)} />
                                </div>
                            </div>
                            <div className='vote-info-btm'>
                                <div className='vote-info-btm-left'>
                                    <span>Locked price:</span>
                                    <div>Price pool:</div>
                                    <span>UP</span>
                                    <span>DOWN</span>
                                </div>
                                <div className='vote-info-btm-right'>
                                    <span className='info-txt'>{formatUsd(lockPrice)}</span>
                                    <div>{totalAmount} {currency}</div>
                                    <span>{payoutRow(bullMultiplier, bullAmount)}</span>
                                    <span>{payoutRow(bearMultiplier, bearAmount)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HistoryDropdown;
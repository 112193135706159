import { HistoryFilter } from "types/HistoryFilter";
import {
    INIT_PREDICTIONS_ADA,
    INIT_HISTORY_ADA,
    SET_LAST_ORACLE_PRICE_ADA,
    SET_ROUNDS_ADA, UPDATE_PREDICTIONS_ADA,
    SET_HISTORY_SIDE_MENU_STATUS_ADA,
    SET_HISTORY_FILTER_ADA,
    UPDATE_ROUNDS_ADA,
    UPDATE_LEDGERS_ADA,
    UPDATE_CLAIMABLE_STATUSES_ADA,
    UPDATE_LEDGERS_AS_MARKED_ADA,
    IS_FETCHED_ADA
} from "../actions/PredictionAda";
import { BIG_ZERO } from "../containers/Prediction/config";

const initialState: any = {
    history: {
        bets: {},
        claimableStatuses: {},
        page: 0,
        totalHistory: 0
    },
    historySideMenuStatus: false,
    historyFilter: HistoryFilter.ALL,
    bufferSeconds: 0,
    claimableStatuses: {},
    currentEpoch: 0,
    intervalSeconds: 0,
    ledgers: {},
    minBetAmount: '0',
    rounds: {},
    status: undefined,
    lastOraclePriceADAUSDT: BIG_ZERO.toJSON(),
    isFetched: false
};

export default function predictionAdaReducer(state = initialState, action: any) {
    let changes;
    switch (action.type) {
        case INIT_PREDICTIONS_ADA:
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case SET_LAST_ORACLE_PRICE_ADA:
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case SET_ROUNDS_ADA:
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case SET_HISTORY_FILTER_ADA:
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case UPDATE_PREDICTIONS_ADA:
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case SET_HISTORY_SIDE_MENU_STATUS_ADA:
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case UPDATE_ROUNDS_ADA:
            changes = {
                ...state,
                rounds: { ...state.rounds, ...action.payload.rounds }
            };
            return changes;
        case UPDATE_LEDGERS_ADA:
            changes = {
                ...state,
                ledgers: { ...state.ledgers, ...action.payload.ledgers }
            };
            return changes;
        case UPDATE_CLAIMABLE_STATUSES_ADA:
            changes = {
                ...state,
                claimableStatuses: { ...state.claimableStatuses, ...action.payload.claimableStatuses }
            };
            return changes;
        case INIT_HISTORY_ADA:
            changes = {
                ...state,
                history: { ...state.history, ...action.payload.history }
            };
            return changes;
        case UPDATE_LEDGERS_AS_MARKED_ADA:
            changes = {
                ...state,
                history: {
                    ...state.history,
                    claimableStatuses: action.payload.history.claimableStatuses
                }
            };
            return changes;
        case IS_FETCHED_ADA:
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        default:
            return state;
    }
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { DropdownItem } from 'types/DropdownItem';

import History from '../../helpers/History'

interface Props {
    name: string,
    // icon: string,
    disabled: boolean,
    dropdownItems: Array<DropdownItem>,
    link: string
    onClick?: Function
}

function MenuItem(props: Props) {
    const location = useLocation();
    const [hoverClass, setHoverClass] = useState('');

    useEffect(() => { }, [location])

    const onClick = () => {
        if (props.onClick) return props.onClick()

        if (props.link.includes('http')) {
            window.open(props.link, '_blank');
        } else if (props.link.length > 0) {
            History.push(props.link);
        }

        const body = document.body;
        if (body && window.innerWidth <= 1024) {
            body.classList.add('menu-ani');
            setTimeout(() => {
                body.classList.remove('menu-ani');
                body.classList.remove('menu-open');
            }, 300)
        }
    }

    const isDropdownMenu = () => {
        return props.dropdownItems && props.dropdownItems.length > 0;
    }

    const onDropdownItemClick = (dItem: DropdownItem) => {
        History.push(dItem.link);
    }

    const isActive = () => {
        if (props.link === location.pathname) {
            return 'active';
        }
        return '';
    }

    return (
        <li
            className={`menu-item ${isActive()} ${isDropdownMenu() ? hoverClass : ''}`}
            onMouseEnter={() => setHoverClass('show')}
            onMouseLeave={() => setHoverClass('')}>
            {/* 
                The reason why there is a seperate HTML for the dropdown items and
                the non dropdown items is because the non dropdownItem does not need
                to have a [data-toggle,  aria-haspopup and aria-expanded] this will 
                glitch out the normal dropdowns. 
             */}
            {isDropdownMenu()
                ? <div className='menu-div' onClick={() => onClick()}>
                    {props.name}
                    <div className='sm-icon menu-icon'>
                        <i className="fa-light fa-angle-down"></i>
                    </div>
                </div>
                :
                <div className='menu-div' onClick={() => onClick()}>
                    {props.name}
                </div>
            }
            {isDropdownMenu() &&
                <div className={`dropdown-menu ${isDropdownMenu() ? hoverClass : ''}`} aria-labelledby="dropdownMenuButton">
                    {props.dropdownItems.map((dItem: DropdownItem) =>
                        <a key={dItem.link} className={`dropdown-item ${location.pathname === dItem.link ? 'active' : ''}`} onClick={() => onDropdownItemClick(dItem)}>
                            {dItem.name}
                            {dItem.imgDetails &&
                                <div className='sm-icon menu-icon'>
                                    <img src={dItem.imgDetails.image} alt={dItem.imgDetails.alt} />
                                </div>
                            }
                        </a>
                    )}
                </div>
            }
        </li>
    )
}

export default MenuItem;
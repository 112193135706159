import { useCallback, useEffect, useState } from 'react'
import BigNumber from "bignumber.js";

function useGetAllowance(contract: any, owner: string, spender: string, reload?: boolean) {
    const [allowance, setAllowance] = useState<string | BigNumber | number>(new BigNumber(0))

    const getAllowance = useCallback(async () => {
        if (owner === null || spender === null) {
            setAllowance(new BigNumber(0));
            return;
        }
        const bal = await contract.methods.allowance(owner, spender).call();
        setAllowance(bal);
    }, [contract.methods, owner, spender]);

    useEffect(() => {
        getAllowance();
    }, [getAllowance, reload])

    return allowance.toString();
}

export default useGetAllowance

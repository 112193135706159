/* eslint-disable react-hooks/rules-of-hooks */
import { callbackActiveGame } from "helpers/SharedFunctions";
import { useSelector } from "react-redux"
import { useGetActiveGame } from "./useGetActiveGame";

export const useGetIsClaimable = (epoch) => {
  const activeGame = useGetActiveGame();
  let claimableStatuses = useSelector((state: any) => state.predictionBitcoinReducer.history.claimableStatuses);

  callbackActiveGame(
    activeGame,
    () => { // lINK
      claimableStatuses = useSelector((state: any) => state.predictionLinkReducer.history.claimableStatuses)
    },
    () => { // ADA
      claimableStatuses = useSelector((state: any) => state.predictionAdaReducer.history.claimableStatuses)
    },
    () => { // CAKE
      claimableStatuses = useSelector((state: any) => state.predictionCakeReducer.history.claimableStatuses)
    },
    () => { // BITCOIN
      claimableStatuses = useSelector((state: any) => state.predictionBitcoinReducer.history.claimableStatuses)
    },
    () => { // DEFAULT
      claimableStatuses = useSelector((state: any) => state.predictionBitcoinReducer.history.claimableStatuses)
    }
  );

  return claimableStatuses[epoch] || false
}
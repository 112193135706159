/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from "react-redux"
import { callbackActiveGame, parseBigNumberObj } from "../helpers/SharedFunctions"
import { NodeLedger } from "../types/NodeLedger"
import { useGetActiveGame } from "./useGetActiveGame"

export const useGetBetByEpoch = (account: string, epoch: number) => {
  const activeGame = useGetActiveGame();
  let bets = useSelector((state: any) => state.predictionBitcoinReducer.ledgers);

  callbackActiveGame(
    activeGame,
    () => { // lINK
      bets = useSelector((state: any) => state.predictionLinkReducer.ledgers)
    },
    () => { // ADA
      bets = useSelector((state: any) => state.predictionAdaReducer.ledgers)
    },
    () => { // CAKE
      bets = useSelector((state: any) => state.predictionCakeReducer.ledgers)
    },
    () => { // BITCOIN
      bets = useSelector((state: any) => state.predictionBitcoinReducer.ledgers)
    },
    () => { // DEFAULT
      bets = useSelector((state: any) => state.predictionBitcoinReducer.ledgers)
    }
  );

  if (!bets[account]) {
    return null
  }

  if (!bets[account][epoch]) {
    return null
  }

  return parseBigNumberObj<NodeLedger>(bets[account][epoch])
}
import React from 'react'
import RoundResult from '../RoundResult/RoundResult';
import RoundProgress from '../RoundProgress/RoundProgress';
import BigNumber from 'bignumber.js';
import CanceledRoundCard from 'components/CanceledRoundCard/CanceledRoundCard';
import CalculatingCard from 'components/CalculatingCard/CalculatingCard';

import { NodeRound } from '../../types/NodeRound';
import { NodeLedger } from '../../types/NodeLedger';
import { GameStatus } from '../../types/GameStatus';
import { formatBigNumberToFixed, getHasRoundFailed, getPriceDifference, getRoundPosition } from '../../containers/Prediction/helper';
import { useGetBufferSeconds } from '../../hooks/useGetBufferSeconds';
import { useGetLastOraclePrice } from '../../hooks/useGetLastOraclePrice';
import { countNumbers } from '../../helpers/AnimationHelper';
import { useSpring, animated } from 'react-spring';
import { formatUsdv2 } from '../../helpers/SharedFunctions';
import Chip from 'components/Chip/Chip';

interface Props {
    round: NodeRound
    betAmount?: NodeLedger['amount']
    hasEnteredUp: boolean
    hasEnteredDown: boolean
    bullMultiplier: string
    bearMultiplier: string
}

function LiveRoundCard(props: Props) {
    const { epoch, lockPrice, closePrice, lockTimestamp, closeTimestamp }: any = props.round
    const bufferSeconds = useGetBufferSeconds();
    const hasRoundFailed = getHasRoundFailed(props.round, bufferSeconds);
    const betPosition = getRoundPosition(lockPrice, closePrice);
    const price = useGetLastOraclePrice();
    const priceAsNumber = parseFloat(formatBigNumberToFixed(price, 3, 8));
    const priceCount = useSpring(countNumbers(1000, 0, priceAsNumber, 0))
    const currentPriceDifference = getPriceDifference(price, lockPrice);
    const priceDifferenceLiveFormattedToFixed = isNaN(new BigNumber(formatUsdv2(currentPriceDifference, false).priceFormattedToFixed).toNumber()) ? 0 : new BigNumber(formatUsdv2(currentPriceDifference, false).priceFormattedToFixed).toNumber();
    const now = Date.now()

    const getColorStatus = () => {
        if (priceDifferenceLiveFormattedToFixed > 0) {
            return 'status-up';
        }
        if (priceDifferenceLiveFormattedToFixed <= 0) {
            return 'status-down';
        }
        if (betPosition === null) {
            return 'status-down';
        }
        return '';
    }

    if (hasRoundFailed) {
        return (
            <CanceledRoundCard cursorNoDrop opacity={0.48} round={props.round} />
        )
    }

    if (now > closeTimestamp * 1000) {
        return <CalculatingCard round={props.round} hasEnteredDown={props.hasEnteredDown} hasEnteredUp={props.hasEnteredUp} />
    }

    return (
        <div className={`card-wrap live ${getColorStatus()}`}>
            <div className='card-header'>
                <div className='card-header-left'>
                    <div className='sm-icon'>
                        <i className="fa-thin fa-tower-broadcast"></i>
                    </div>
                    <span>{GameStatus.LIVE}</span>
                </div>
                <div className='card-header-right'>
                    <span>#{epoch}</span>
                </div>
                <RoundProgress lockTimestamp={lockTimestamp} closeTimestamp={closeTimestamp} />
            </div>
            <div className='card-content'>
                <div className='vote-wrap'>
                    <div className='vote-top'>
                        {props.hasEnteredUp && <Chip fill uppercase marginRightIcon={8} icon={<i className="fa-thin fa-check"></i>} color='main' txt='Entered' />}
                        <h3>UP</h3>
                        <span>{props.bullMultiplier !== undefined ? `${props.bullMultiplier}x` : '-'} Payout</span>
                    </div>
                    <div className='vote-btm'>
                        {props.hasEnteredDown && <Chip fill uppercase marginRightIcon={8} icon={<i className="fa-thin fa-check"></i>} color='main' txt='Entered' />}
                        <span>{props.bearMultiplier !== undefined ? `${props.bearMultiplier}x` : '-'} Payout</span>
                        <h3>DOWN</h3>
                    </div>
                </div>
                <RoundResult
                    round={props.round}
                    hasFailed={hasRoundFailed}
                    gameStatus={GameStatus.LIVE}
                    customClosePrice={
                        <animated.b>
                            {priceCount.number.interpolate((val: any) => {
                                return '$' + val.toFixed(3);
                            })}
                        </animated.b>
                    } />
            </div>
        </div>
    )
}

export default LiveRoundCard;
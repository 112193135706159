import BigNumber from 'bignumber.js';
import { ethers } from 'ethers'
import { toast } from 'react-toastify';
import { Games } from 'types/Games';
import { DISPLAYED_DECIMALS, MIN_PRICE_BNB_DISPLAYED, MIN_PRICE_USD_DISPLAYED } from '../containers/Prediction/config';
import { formatBigNumberToFixed } from '../containers/Prediction/helper';
import { formatPriceDifferenceProps } from '../types/formatPriceDifferenceProps';
import { PrizePoolRowProps } from '../types/PrizePoolRowProps';

export const truncateFromCenter = (value: string): string => {
    return value.substring(0, 12) + '...' + value.substring(value.length - 3, value.length);
}

export const numberWithCommas = (number: string | number | ethers.BigNumber) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Parse serialized values back into ethers.BigNumber
 * ethers.BigNumber values are stored with the "toJSON()" method, e.g  { type: "BigNumber", hex: string }
 */
export const parseBigNumberObj = <K = Record<string, any>>(data: any): K => {
    return Object.keys(data).reduce((accum, key) => {
        const value = data[key]

        if (value && value?.type === 'BigNumber') {
            return {
                ...accum,
                [key]: ethers.BigNumber.from(value),
            }
        }

        return {
            ...accum,
            [key]: value,
        }
    }, {}) as K
}

const formatPriceDifference = ({
    price = ethers.BigNumber.from(0),
    minPriceDisplayed,
    unitPrefix,
    decimals,
    getFormat = true
}: formatPriceDifferenceProps) => {
    const sign = price && price.isNegative() ? ethers.BigNumber.from(-1) : ethers.BigNumber.from(1)

    if (price === null) {
        const ZERO = ethers.BigNumber.from(0);
        return `<${unitPrefix}${formatBigNumberToFixed(ZERO, DISPLAYED_DECIMALS, decimals)}`
    }

    if (price.abs().lt(minPriceDisplayed)) {
        const signedPriceToFormat = minPriceDisplayed.mul(sign)
        return `<${unitPrefix}${formatBigNumberToFixed(signedPriceToFormat, DISPLAYED_DECIMALS, decimals)}`
    }

    if (getFormat) {
        return `${unitPrefix}${formatBigNumberToFixed(price, DISPLAYED_DECIMALS, decimals)}`;
    } else {
        return {
            unitPrefix: unitPrefix,
            price: price.toString(),
            priceFormattedToFixed: formatBigNumberToFixed(price, DISPLAYED_DECIMALS, decimals)
        }
    }
}

export const formatNumber = (number: number, minPrecision = 2, maxPrecision = 2) => {
    const options = {
        minimumFractionDigits: minPrecision,
        maximumFractionDigits: maxPrecision,
    }
    return number.toLocaleString(undefined, options)
}

export const formatUsd = (usd: number) => {
    return `$${formatNumber(usd || 0, 3, 3)}`
}

export const formatUsdv2: any = (usd: ethers.BigNumber, format: boolean = true) => {
    return formatPriceDifference({ price: usd, minPriceDisplayed: MIN_PRICE_USD_DISPLAYED, unitPrefix: '$', decimals: 8, getFormat: format })
}

export const formatBnb = (bnb: number) => {
    return bnb ? bnb.toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 }) : '0'
}

export const formatBnbv2 = (bnb: ethers.BigNumber, format: boolean = true) => {
    return formatPriceDifference({ price: bnb, minPriceDisplayed: MIN_PRICE_BNB_DISPLAYED, unitPrefix: '', decimals: 18, getFormat: format })
}

export const getPrizePoolAmount = (totalAmount: PrizePoolRowProps['totalAmount']) => {
    if (!totalAmount) {
        return '0'
    }
    return formatBnbv2(totalAmount)
}

/**
 * Method to format the display of wei given an ethers.BigNumber object
 * Note: does NOT round
 */
export const formatBigNumber = (number: ethers.BigNumber, displayDecimals = 18, decimals = 18) => {
    const n = new BigNumber(number.toString());
    return n.div(1e18).toFixed(3);
}

export const showToast = (txt, error) => {
    return toast(txt, {
        toastId: txt + Math.random + Math.random,
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        progressClassName: error ? 'error' : 'success',
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        className: error ? 'error' : 'success',
        progress: undefined,
    });
}

export const callbackActiveGame = (activeGame: Games, callbackLINK: Function, callbackADA: Function, callbackCAKE: Function, callbackBitcoin: Function, callbackDefault: Function) => {
    switch (activeGame) {
        case Games.LUCRA_LINK:
            callbackLINK();
            break;
        case Games.LUCRA_ADA:
            callbackADA();
            break;
        case Games.LUCRA_CAKE:
            callbackCAKE();
            break;
        case Games.BITCOIN:
            callbackBitcoin();
            break;
        default:
            callbackDefault()
            break;
    }
}
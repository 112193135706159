import { setActiveGame } from "actions/Global";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useGetActiveGame } from "./useGetActiveGame";

export const useSetActiveGame = () => {
    const activeGame = useGetActiveGame();
    const dispatch = useDispatch()
    const urlParams: any = useParams();

    useEffect(() => {
        if (urlParams && urlParams.name && urlParams.name !== activeGame) {
            dispatch(setActiveGame(urlParams.name));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams])

    return;
}


import React from 'react';

interface Props {
  lockTimestamp: number
  closeTimestamp: number
}

function RoundProgress(props: Props) {
  const { lockTimestamp, closeTimestamp } = props;

  const startMs = lockTimestamp * 1000
  const endMs = closeTimestamp * 1000
  const now = Date.now()
  const rawProgress = ((now - startMs) / (endMs - startMs)) * 100
  const progress = rawProgress <= 100 ? rawProgress : 100;

  return (
    <div className='progress-header' style={{ width: (100 - progress) > 100 ? (100 - progress) - 100 : (100 - progress) + '%' }}></div>
  )
}

export default RoundProgress;
/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { ethers } from 'ethers';
import { useGetActiveGame } from "./useGetActiveGame";
import { callbackActiveGame } from "helpers/SharedFunctions";

export const useGetLastOraclePrice = () => {
    const activeGame = useGetActiveGame();
    let lastOraclePrice = useSelector((state: any) => state.predictionBitcoinReducer.lastOraclePriceBTCUSDT);

    callbackActiveGame(
        activeGame,
        () => { // lINK
            lastOraclePrice = useSelector((state: any) => state.predictionLinkReducer.lastOraclePriceLINKUSDT)
        },
        () => { // ADA
            lastOraclePrice = useSelector((state: any) => state.predictionAdaReducer.lastOraclePriceADAUSDT)
        },
        () => { // CAKE
            lastOraclePrice = useSelector((state: any) => state.predictionCakeReducer.lastOraclePriceCAKEUSDT)
        },
        () => { // BITCOIN
            lastOraclePrice = useSelector((state: any) => state.predictionBitcoinReducer.lastOraclePriceBTCUSDT)
        },
        () => { // DEFAULT
            lastOraclePrice = useSelector((state: any) => state.predictionBitcoinReducer.lastOraclePriceBTCUSDT)
        }
    );

    return useMemo(() => { return ethers.BigNumber.from(lastOraclePrice) }, [lastOraclePrice])
}

/* eslint-disable react-hooks/rules-of-hooks */
import { ethers } from "ethers"
import { callbackActiveGame } from "helpers/SharedFunctions"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { useGetActiveGame } from "./useGetActiveGame"

export const useGetMinBetAmount = () => {
    const activeGame = useGetActiveGame();
    let minBetAmount = useSelector((state: any) => state.predictionBitcoinReducer.minBetAmount)

    callbackActiveGame(
        activeGame,
        () => { // lINK
            minBetAmount = useSelector((state: any) => state.predictionLinkReducer.minBetAmount)
        },
        () => { // ADA
            minBetAmount = useSelector((state: any) => state.predictionAdaReducer.minBetAmount)
        },
        () => { // CAKE
            minBetAmount = useSelector((state: any) => state.predictionCakeReducer.minBetAmount)
        },
        () => { // BITCOIN
            minBetAmount = useSelector((state: any) => state.predictionBitcoinReducer.minBetAmount)
        },
        () => { // DEFAULT
            minBetAmount = useSelector((state: any) => state.predictionBitcoinReducer.minBetAmount)
        }
    );

    return useMemo(() => ethers.BigNumber.from(minBetAmount), [minBetAmount])
}
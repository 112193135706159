import React from 'react'
import CenterActionBlock from 'components/CenterActionBlock/CenterActionBlock';

import { NodeRound } from '../../types/NodeRound';
import { GameStatus } from '../../types/GameStatus';
import { getRoundPosition } from '../../containers/Prediction/helper';
import { BetPosition } from '../../types/BetPosition';

interface Props {
    round: NodeRound
    opacity?: number,
    cursorNoDrop?: boolean
}

function CanceledRoundCard(props: Props) {
    const { epoch, lockPrice, closePrice }: any = props.round
    const betPosition = getRoundPosition(lockPrice, closePrice);

    const getColorStatus = () => {
        if (betPosition === BetPosition.BULL) {
            return 'status-up';
        }
        if (betPosition === BetPosition.BEAR) {
            return 'status-down';
        }
        if (betPosition === null) {
            return 'status-down';
        }
        return '';
    }

    return (
        <div className={`card-wrap is-calculating ${props.cursorNoDrop ? 'cursor-no-drop' : ''} ${getColorStatus()}`} style={{ opacity: props.opacity }}>
            <div className='card-header'>
                <div className='card-header-left'>
                    <div className='sm-icon'>
                        <i className="fa-thin fa-circle-xmark"></i>
                    </div>
                    <span>{GameStatus.CANCELED}</span>
                </div>
                <div className='card-header-right'>
                    <span>#{epoch}</span>
                </div>
            </div>
            <div className='card-content'>
                <div className='card-content-loader-wrap'>
                    <div className='card-content-loader-txt'>
                        <CenterActionBlock
                            icon={<i className="fa-thin fa-circle-xmark"></i>}
                            title='Round Canceled'
                            description='This round has been canceled'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CanceledRoundCard;
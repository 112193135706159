import { useWallet } from '@binance-chain/bsc-use-wallet';
import Button from 'components/Button/Button';
import TextDialog from 'components/TextDialog/TextDialog';
import { truncateFromCenter } from 'helpers/SharedFunctions';
import React, { useCallback, useEffect, useState } from 'react'
import { config, isWalletConnected, setWalletConnected } from 'utils/config';

interface Props {
    enableTextDialog: boolean
    disabled?: boolean
}

function ConnectButton(props: Props) {
    const wallet = useWallet();
    const { account } = useWallet();
    const [textDialogState, setTextDialogState] = useState(false);
    const connectedId = isWalletConnected();
    const [btnText, setBtnText] = useState('Connect')

    /**
     * @dev Connect wallet to metamask
     */
    const connectMetamask = useCallback(() => {
        if (wallet.status !== 'connected') {
            // is web3 undefined (not installed) show messages
            if (typeof window.ethereum == 'undefined') {
                setTextDialogState(true)
            } else {
                if (wallet.status !== 'connected') {
                    wallet.connect('injected');
                }
            }
        }
    }, [wallet])

    /**
     * @dev Checking if you connected before if yes reconnect.
     */
    const checkConnection = () => {
        if (wallet.status !== 'connected' && connectedId != null) {
            wallet.connect(connectedId)
        }
    }

    const setOrRemoveConnection = useCallback(() => {
        if (wallet && wallet.account) setWalletConnected('injected')
        else localStorage.removeItem('__WALLET_CONNECTED')
    }, [wallet])

    /**
    * @dev Based on the connected chain check if you need to show the wallet address or the wrong network txt
    */
     const getConnectButtonTxt = useCallback(async () => {
        // 0x38 = Binance Mainnet
        // 0x61 = Binance Testnet
        const id =
            config.networkId === 1 ? '0x1' :
                config.networkId === 4 ? '0x4' :
                    config.networkId === 2 ? '0x2a' :
                        config.networkId === 56 ? '0x38' :
                            config.networkId === 97 ? '0x61'
                                : '0x1';

        if (window.ethereum) {
            window.ethereum.on('chainChanged', (changedChainID: any) => {
                if (changedChainID === id) return window.location.reload()
                if (changedChainID !== id) return setBtnText('Wrong network')
                else return setBtnText('Connect')
            })
            const chainId = await window.ethereum.request({ method: 'eth_chainId' });
            if (chainId !== id) return setBtnText('Wrong network')
            if (chainId === id && wallet && wallet.account) return setBtnText(truncateFromCenter(wallet.account))
            else return setBtnText('Connect')
        }
    }, [wallet])

    useEffect(() => {
        checkConnection()
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallet])

    useEffect(() => {
        setOrRemoveConnection()
    }, [setOrRemoveConnection, wallet])

    useEffect(() => {
        getConnectButtonTxt()
    }, [getConnectButtonTxt, wallet])

    return (
        <>
            <Button disabled={props.disabled} className='button btn-main' onClick={() => !account ? connectMetamask() : null}>{btnText}</Button>
            {/* Dialog */}
            {props.enableTextDialog && <TextDialog open={textDialogState} onClose={() => setTextDialogState(false)} />}
        </>
    )
}

export default ConnectButton;
export enum ContractName {
    PredictionBTC = 'PredictionBTC',
    PredictionLINK = 'PredictionLINK',
    PredictionADA = 'PredictionADA',
    PredictionCAKE = 'PredictionCAKE',
    OracleBTCUSDT = 'OracleBTCUSDT',
    OracleLINKUSDT = 'OracleLINKUSDT',
    OracleADAUSDT = 'OracleADAUSDT',
    OracleCAKEUSDT= 'OracleCAKEUSDT',
    LucraLINKToken = 'LucraLINKToken',
    LucraADAToken = 'LucraADAToken',
    LucraCAKEToken = 'LucraCAKEToken',
    PresaleLucraLINK = 'PresaleLucraLINK',
    PresaleLucraADA = 'PresaleLucraADA',
    PresaleLucraCAKE = 'PresaleLucraCAKE',
    MultiCall = 'MultiCall',
    Exchange = 'Exchange',
    WBNB = 'WBNB',
    PancakeFactory = 'PancakeFactory',
    PancakeRouter = 'PancakeRouter'
}
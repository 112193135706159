/* eslint-disable react-hooks/rules-of-hooks */
import { callbackActiveGame } from "helpers/SharedFunctions";
import { useSelector } from "react-redux"
import { useGetActiveGame } from "./useGetActiveGame";

import _ from "lodash"

/**
 * Used to get the range of rounds to poll for
 */
export const useGetEarliestEpoch = () => {
  const activeGame = useGetActiveGame();
  let earliestRound: any = useSelector((state: any) => _.minBy(Object.values(state.predictionBitcoinReducer.rounds), 'epoch'))

  callbackActiveGame(
    activeGame,
    () => { // lINK
      earliestRound = useSelector((state: any) => _.minBy(Object.values(state.predictionLinkReducer.rounds), 'epoch'));
    },
    () => { // ADA
      earliestRound = useSelector((state: any) => _.minBy(Object.values(state.predictionAdaReducer.rounds), 'epoch'));
    },
    () => { // CAKE
      earliestRound = useSelector((state: any) => _.minBy(Object.values(state.predictionCakeReducer.rounds), 'epoch'));
    },
    () => { // BITCOIN
      earliestRound = useSelector((state: any) => _.minBy(Object.values(state.predictionBitcoinReducer.rounds), 'epoch'));
    },
    () => { // DEFAULT
      earliestRound = useSelector((state: any) => _.minBy(Object.values(state.predictionBitcoinReducer.rounds), 'epoch'));
    }
  );

  return earliestRound?.epoch;
}
import React, { useEffect, useState } from 'react'
import GameButtons from '../GameButtons/GameButtons';
import CardFlip from '../CardFlip/CardFlip';
import Chip from '../Chip/Chip';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Slider from '@mui/material/Slider';
import BigNumber from 'bignumber.js';
import Button from 'components/Button/Button';
import TextDialog from 'components/TextDialog/TextDialog';
import ConnectButton from 'components/ConnectButton/ConnectButton';
import useGetAllowance from 'hooks/useGetAllowance';
import moment from 'moment';

import { NodeRound } from '../../types/NodeRound';
import { NodeLedger } from '../../types/NodeLedger';
import { GameStatus } from '../../types/GameStatus';
import { callbackActiveGame, formatBigNumber, getPrizePoolAmount, showToast } from '../../helpers/SharedFunctions';
import { useWallet } from '@binance-chain/bsc-use-wallet';
import { useGetMinBetAmount } from 'hooks/useGetMinBetAmount';
import { useGetCurrentEpoch } from 'hooks/useGetCurrentEpoch';
import { getToken, LucraADATokenContract, LucraCAKETokenContract, LucraLINKTokenContract, predictionADAContract, predictionBTCContract, predictionCAKEContract, predictionLINKContract } from 'utils/contracts';
import { sendTransaction } from 'utils/web3';
import { useGetCurrentRound } from 'hooks/useGetCurrentRound';
import { useGetBnbBalance } from 'hooks/useGetBnbBalance';
import { initializePredictions, updatePredictionGame } from 'containers/Prediction/helper';
import { useDispatch } from 'react-redux';
import { ContractName } from 'types/ContractName';
import { useGetActiveGame } from 'hooks/useGetActiveGame';
import { useGetActiveCurrency } from 'hooks/useGetActiveCurrency';
import { PredictionGame } from 'types/PredictionGame';
import { useGetLucraLinkBalance } from 'hooks/useGetLucraLinkBalance';
import { useGetLucraAdaBalance } from 'hooks/useGetLucraAdaBalance';
import { useGetLucraCakeBalance } from 'hooks/useGetLucraCakeBalance';
import { Games } from 'types/Games';

import { initPredictions as initPredictionsLink } from 'actions/PredictionLink';
import { initPredictions as initPredictionsBitcoin } from 'actions/PredictionBitcoin';
import { initPredictions as initPredictionsAda } from 'actions/PredictionAda';
import { initPredictions as initPredictionsCake } from 'actions/PredictionCake';
import { useGetEarliestEpoch } from 'hooks/useGetEarliestEpoch';

interface Props {
    round: NodeRound
    betAmount?: NodeLedger['amount']
    hasEnteredUp: boolean
    hasEnteredDown: boolean
    bullMultiplier: string
    bearMultiplier: string
}

function OpenRoundCardFront(props: Props) {
    const { epoch, totalAmount }: any = props.round;
    const { account } = useWallet();
    const { ethereum }: { ethereum: any } = useWallet();
    const { balance: bnbBalance } = useGetBnbBalance();
    const { balance: lcrLinkBalance } = useGetLucraLinkBalance();
    const { balance: lcrAdaBalance } = useGetLucraAdaBalance();
    const { balance: lcrCakeBalance } = useGetLucraCakeBalance();
    const [approvePositionIsLoading, setApprovePositionIsLoading] = useState(false);
    const [approvePositionTxt, setApprovePositionTxt] = useState('Approve');
    const [positionIsLoading, setPositionIsLoading] = useState(false);
    const [positionButtonTxt, setPositionButtonTxt] = useState('Confirm');
    const [isFlipped, setIsFlipped] = useState(false);
    const [chipIsBull, setChipIsBull] = useState(false);
    const [positionRangeInput, setPositionRangeInput] = useState(0);
    const [textDialogState, setTextDialogState] = useState(false)
    const minBetAmount = useGetMinBetAmount();
    const [value, setValue] = useState(new BigNumber(formatBigNumber(minBetAmount)));
    const activeGame = useGetActiveGame();
    const dispatch = useDispatch();
    const currentRound = useGetCurrentRound();
    const currency = useGetActiveCurrency();
    const now = moment().unix();
    const currentEpoch = useGetCurrentEpoch(activeGame);
    const earliestEpoch = useGetEarliestEpoch()

    const infinityApprove = new BigNumber(2).pow(256).minus(1).toString(10);
    const [reloadAllowance, setReloadAllowance] = useState(false);
    const getAllowanceLink = useGetAllowance(LucraLINKTokenContract, account, getToken(ContractName.PredictionLINK).address, reloadAllowance);
    const getAllowanceAda = useGetAllowance(LucraADATokenContract, account, getToken(ContractName.PredictionADA).address, reloadAllowance);
    const getAllowanceCake = useGetAllowance(LucraCAKETokenContract, account, getToken(ContractName.PredictionCAKE).address, reloadAllowance);

    const getBalance = () => {
        if (currency === PredictionGame.BNB) {
            return bnbBalance;
        }
        if (currency === PredictionGame.LCR_LINK) {
            return lcrLinkBalance;
        }
        if (currency === PredictionGame.LCR_ADA) {
            return lcrAdaBalance;
        }
        if (currency === PredictionGame.LCR_CAKE) {
            return lcrCakeBalance;
        }
    }

    const balanceDisplay = formatBigNumber(getBalance());

    const refreshDataPredictions = async () => {
        const init = async (prediction: Function) => {
            const i = await initializePredictions(account, activeGame);
            dispatch(prediction(i));
        }

        callbackActiveGame(
            activeGame,
            () => init(initPredictionsLink), // lINK
            () => init(initPredictionsAda), // ADA
            () => init(initPredictionsCake), // CAKE
            () => init(initPredictionsBitcoin), // BITCOIN
            () => init(initPredictionsBitcoin), // DEFAULT
        );
    }

    const handleApprove = async () => {
        setApprovePositionIsLoading(true)
        setApprovePositionTxt('Submitting transaction...')

        try {
            let func: any;
            let address: string;
            callbackActiveGame(
                activeGame,
                () => { // lINK
                    address = getToken(ContractName.LucraLINKToken).address;
                    func = LucraLINKTokenContract.methods.approve(getToken(ContractName.PredictionLINK).address, infinityApprove).encodeABI();
                },
                () => { // ADA
                    address = getToken(ContractName.LucraADAToken).address;
                    func = LucraADATokenContract.methods.approve(getToken(ContractName.PredictionADA).address, infinityApprove).encodeABI();
                },
                () => { // CAKE
                    address = getToken(ContractName.LucraCAKEToken).address;
                    func = LucraCAKETokenContract.methods.approve(getToken(ContractName.PredictionCAKE).address, infinityApprove).encodeABI();
                },
                async () => { }, // BITCOIN (Uses BNB as wagering and payout so there is no need for approve)
                () => { } // DEFAULT (THERE IS NONE BECAUSE WE DONT WANT USERS TO APPROVE THE WRONG CONTRACT BECAUSE ACTIVE GAME HASN'T BEEN UPDATED)
            );
            await sendTransaction(ethereum, account, address, func, '0x0',
                async () => { // onSuccess
                    showToast('Successfully approved', false);
                    setApprovePositionTxt('Approve')
                    setApprovePositionIsLoading(false);
                    setReloadAllowance(true);
                }, (err: any) => { // onError
                    if (err.code === 4001) {
                        showToast('Transaction has been denied', true);
                    } else {
                        showToast('Failed to approve', true);
                    }

                    setApprovePositionTxt('Approve')
                    setApprovePositionIsLoading(false);
                })
        } catch {
            setApprovePositionTxt('Approve')
            setApprovePositionIsLoading(false);
        }
    }

    const handleEnterPosition = async () => {
        setPositionIsLoading(true)
        setPositionButtonTxt('Submitting transaction...')
        const betMethod = chipIsBull ? 'betBull' : 'betBear'

        const amountToWager = new BigNumber(value.toString()).times(1e18).toString();
        console.log('amountToWager', amountToWager)
        try {
            let func: any;
            let wei: any;
            let address: string;
            callbackActiveGame(
                activeGame,
                () => { // lINK
                    wei = '0x0'
                    func = predictionLINKContract.methods.betBear(currentEpoch, amountToWager).encodeABI();
                    address = getToken(ContractName.PredictionLINK).address;
                },
                () => { // ADA
                    wei = '0x0'
                    func = predictionADAContract.methods[betMethod](currentEpoch, amountToWager).encodeABI();
                    address = getToken(ContractName.PredictionADA).address;
                },
                () => { // CAKE
                    wei = '0x0'
                    func = predictionCAKEContract.methods[betMethod](currentEpoch, amountToWager).encodeABI();
                    address = getToken(ContractName.PredictionCAKE).address;
                },
                async () => { // BITCOIN
                    wei = new BigNumber(value).times(1e18).toString();
                    func = predictionBTCContract.methods[betMethod](currentEpoch).encodeABI();
                    address = getToken(ContractName.PredictionBTC).address;
                },
                () => { } // DEFAULT (THERE IS NONE BECAUSE WE DONT WANT USERS TO APPROVE THE WRONG CONTRACT BECAUSE ACTIVE GAME HASN'T BEEN UPDATED)
            );

            await sendTransaction(ethereum, account, address, func, wei,
                async () => { // onSuccess
                    showToast('Successfully entered round', false);
                    setPositionButtonTxt('Confirm')
                    setPositionIsLoading(false);
                    setIsFlipped(!isFlipped);
                    refreshDataPredictions();
                    updatePredictionGame(currentEpoch, earliestEpoch, activeGame, dispatch, account);
                }, (err: any) => { // onError
                    if (err.code === 4001) {
                        showToast('Transaction has been denied', true);
                    } else {
                        showToast('Failed to enter round', true);
                    }
                    setPositionButtonTxt('Confirm')
                    setPositionIsLoading(false);
                    refreshDataPredictions();
                })
        } catch {
            setPositionButtonTxt('Confirm')
            setPositionIsLoading(false);
        }
    }

    const lowerOpacityAndDisableOnError = () => {
        if (!account) return true;
        if (new BigNumber(balanceDisplay).isLessThanOrEqualTo(0)) return true;
        if (currentRound && now >= currentRound.closeTimestamp) return true;
        return false;
    }

    const getErrorMessages = () => {
        if (account) {
            if (new BigNumber(balanceDisplay).isLessThanOrEqualTo(0) && value.toString() !== '') return `Insufficient ${currency} balance`;
            if (new BigNumber(value).isGreaterThan(balanceDisplay)) return 'Not enough balance'
            if (new BigNumber(value).isLessThan(formatBigNumber(minBetAmount))) return `Min amount is ${formatBigNumber(minBetAmount).toString()}`
            if (currentRound && currentRound.closeTimestamp >= Date.now()) return "Round hasn't been closed"
        }
        return '';
    }

    const hasInfinityApproved = () => {
        if (activeGame === Games.LUCRA_LINK) {
            if (new BigNumber(getAllowanceLink).isGreaterThan(0)) return true;
        }
        if (activeGame === Games.LUCRA_ADA) {
            if (new BigNumber(getAllowanceAda).isGreaterThan(0)) return true;
        }
        if (activeGame === Games.LUCRA_CAKE) {
            if (new BigNumber(getAllowanceCake).isGreaterThan(0)) return true;
        }
        return false;
    }

    useEffect(() => {
        return () => {
            setReloadAllowance(false)
        }
    }, [])

    return (
        <>
            <CardFlip isFlipped={isFlipped} flipDirection="vertical">
                {/* FRONT */}
                <div className='card-wrap open card-wrap-front'>
                    <div className='card-header'>
                        <div className='card-header-left'>
                            <div className='sm-icon'>
                                <i className="fa-thin fa-circle-play"></i>
                            </div>
                            <span>{GameStatus.NEXT}</span>
                        </div>
                        <div className='card-header-right'>
                            <span>#{epoch}</span>
                        </div>
                    </div>
                    <div className='card-content'>
                        <div className='vote-wrap'>
                            <div className='vote-top'>
                                {props.hasEnteredUp && <Chip fill uppercase marginRightIcon={8} icon={<i className="fa-thin fa-check"></i>} color='main' txt='Entered' />}
                                <h3>UP</h3>
                                <span>{props.bullMultiplier !== undefined ? `${props.bullMultiplier}x` : '-'} Payout</span>
                            </div>
                            <div className='vote-btm'>
                                {props.hasEnteredDown && <Chip fill uppercase marginRightIcon={8} icon={<i className="fa-thin fa-check"></i>} color='main' txt='Entered' />}
                                <span>{props.bearMultiplier !== undefined ? `${props.bearMultiplier}x` : '-'} Payout</span>
                                <h3>DOWN</h3>
                            </div>
                        </div>
                        <GameButtons
                            hasEnteredDown={props.hasEnteredDown}
                            hasEnteredUp={props.hasEnteredUp}
                            onEnterBull={() => {
                                setIsFlipped(!isFlipped);
                                setChipIsBull(true);
                            }}
                            onEnterBear={() => {
                                setIsFlipped(!isFlipped);
                                setChipIsBull(false);
                            }}
                            prizePool={getPrizePoolAmount(totalAmount)}
                        />
                    </div>
                </div>
                {/* BACK */}
                <div className='card-wrap card-wrap-back swiper-no-swiping'>
                    <div className='card-header'>
                        <div className='card-header-left'>
                            <div className='sm-icon cursor-pointer' onClick={() => setIsFlipped(!isFlipped)}>
                                <i className="fa-thin fa-chevron-left"></i>
                            </div>
                            <span>{GameStatus.SET_POSITION}</span>
                        </div>
                        <div className='card-header-right'>
                            <span>
                                {chipIsBull
                                    ? <Chip addCursor color='green' txt='UP' onClick={() => setChipIsBull(!chipIsBull)} />
                                    : <Chip addCursor color='red' txt='DOWN' onClick={() => setChipIsBull(!chipIsBull)} />
                                }
                            </span>
                        </div>
                    </div>
                    <div className='card-content'>
                        <FormControl className={`form-control-wrap ${lowerOpacityAndDisableOnError() ? 'disabled' : ''}`} sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <div className='form-helper-text-row'>
                                <FormHelperText>Commit:</FormHelperText>
                                <FormHelperText>{currency}</FormHelperText>
                            </div>
                            <OutlinedInput
                                disabled={lowerOpacityAndDisableOnError()}
                                value={value}
                                type='number'
                                onChange={(ev: any) => setValue(ev.target.value)}
                                aria-describedby="outlined-weight-helper-text"
                                endAdornment={<InputAdornment position="end">{currency}</InputAdornment>}
                            />
                            <div className='form-helper-text-row btm'>
                                <FormHelperText>{getErrorMessages()}</FormHelperText>
                                <FormHelperText>Balance: {account ? formatBigNumber(getBalance()) : '0.0'}</FormHelperText>
                            </div>
                        </FormControl>
                        <div className={`slider-wrap ${lowerOpacityAndDisableOnError() ? 'disabled' : ''}`}>
                            <Slider
                                step={0.05}
                                min={new BigNumber(formatBigNumber(minBetAmount)).toNumber()}
                                max={new BigNumber(balanceDisplay).toNumber()}
                                onChange={(e: any) => { setPositionRangeInput(e.target.value); setValue(e.target.value); }}
                                value={positionRangeInput}
                                disabled={lowerOpacityAndDisableOnError()}
                            />
                        </div>
                        <div className={`slider-buttons ${lowerOpacityAndDisableOnError() ? 'disabled' : ''}`}>
                            <Button disabled={lowerOpacityAndDisableOnError()} className='button b-btn' onClick={() => {
                                setPositionRangeInput(new BigNumber(balanceDisplay).toNumber() / 100 * 15);
                                setValue(new BigNumber(balanceDisplay).div(100).times(15));
                            }}>
                                15%
                            </Button>
                            <Button disabled={lowerOpacityAndDisableOnError()} className='button b-btn' onClick={() => {
                                setPositionRangeInput(new BigNumber(balanceDisplay).toNumber() / 100 * 25);
                                setValue(new BigNumber(balanceDisplay).div(100).times(25));
                            }}>
                                25%
                            </Button>
                            <Button disabled={lowerOpacityAndDisableOnError()} className='button b-btn' onClick={() => {
                                setPositionRangeInput(new BigNumber(balanceDisplay).toNumber() / 100 * 50);
                                setValue(new BigNumber(balanceDisplay).div(100).times(50));
                            }}>
                                50%
                            </Button>
                            <Button disabled={lowerOpacityAndDisableOnError()} className='button b-btn' onClick={() => {
                                setPositionRangeInput(new BigNumber(balanceDisplay).toNumber() / 100 * 75);
                                setValue(new BigNumber(balanceDisplay).div(100).times(75));
                            }}>
                                75%
                            </Button>
                            <Button disabled={lowerOpacityAndDisableOnError()} className='button b-btn' onClick={() => {
                                setPositionRangeInput(new BigNumber(balanceDisplay).toNumber());
                                setValue(new BigNumber(balanceDisplay));
                            }}>
                                Max
                            </Button>
                        </div>
                        <div className='slider-confirm'>
                            {!account
                                ? <ConnectButton
                                    enableTextDialog={true}
                                />
                                : currency !== PredictionGame.BNB && !hasInfinityApproved() && !reloadAllowance
                                    ?
                                    <Button
                                        disabled={
                                            new BigNumber(balanceDisplay).isLessThanOrEqualTo(0) ||
                                            getErrorMessages().length > 0 ||
                                            value.toString() === '' ||
                                            (currentRound && now >= currentRound.closeTimestamp) ||
                                            approvePositionIsLoading
                                        }
                                        className='button btn-main'
                                        onClick={() => handleApprove()}
                                        isLoading={approvePositionIsLoading}>
                                        {approvePositionTxt}
                                    </Button>
                                    :
                                    <Button
                                        disabled={
                                            new BigNumber(balanceDisplay).isLessThanOrEqualTo(0) ||
                                            getErrorMessages().length > 0 ||
                                            value.toString() === '' ||
                                            (currentRound && now >= currentRound.closeTimestamp) ||
                                            positionIsLoading
                                        }
                                        className='button btn-main'
                                        onClick={() => handleEnterPosition()}
                                        isLoading={positionIsLoading}>
                                        {positionButtonTxt}
                                    </Button>
                            }
                        </div>
                    </div>
                </div>
            </CardFlip>
            {/* Dialog */}
            <TextDialog open={textDialogState} onClose={() => setTextDialogState(false)} />
        </>
    )
}

export default OpenRoundCardFront;
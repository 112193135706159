import React from 'react'
import RoundResult from '../RoundResult/RoundResult';
import CanceledRoundCard from 'components/CanceledRoundCard/CanceledRoundCard';
import Chip from 'components/Chip/Chip';
import ClaimRoundCard from 'components/ClaimRoundCard/ClaimRoundCard';

import { NodeRound } from '../../types/NodeRound';
import { NodeLedger } from '../../types/NodeLedger';
import { GameStatus } from '../../types/GameStatus';
import { getHasRoundFailed, getRoundPosition } from '../../containers/Prediction/helper';
import { useGetBufferSeconds } from '../../hooks/useGetBufferSeconds';
import { BetPosition } from '../../types/BetPosition';
import { useGetIsClaimable } from 'hooks/useGetIsClaimable';

interface Props {
    round: NodeRound
    betAmount?: NodeLedger['amount']
    hasEnteredUp: boolean
    hasEnteredDown: boolean
    hasClaimedUp: boolean
    hasClaimedDown: boolean
    bullMultiplier: string
    bearMultiplier: string
}

function ExpiredRoundCard(props: Props) {
    const { epoch, lockPrice, closePrice }: any = props.round
    const bufferSeconds = useGetBufferSeconds();
    const hasRoundFailed = getHasRoundFailed(props.round, bufferSeconds);
    const betPosition = getRoundPosition(lockPrice, closePrice);
    const isClaimable = useGetIsClaimable(epoch)

    const getColorStatus = () => {
        if (betPosition === BetPosition.BULL) {
            return 'status-up';
        }
        if (betPosition === BetPosition.BEAR) {
            return 'status-down';
        }
        if (betPosition === BetPosition.HOUSE) {
            return 'status-down';
        }
        if (betPosition === null) {
            return 'status-down';
        }
        return '';
    }

    
    if (isClaimable) {
        return <ClaimRoundCard round={props.round} />
    }

    if (hasRoundFailed || !closePrice) {
        return <CanceledRoundCard cursorNoDrop opacity={0.48} round={props.round} />
    }

    return (
        <div className={`card-wrap cursor-no-drop ${getColorStatus()}`} style={{ opacity: 0.48 }}>
            <div className='card-header'>
                <div className='card-header-left'>
                    <div className='sm-icon'>
                        <i className="fa-thin fa-circle-xmark"></i>
                    </div>
                    {betPosition === null
                        ? <span>{GameStatus.FAILED}</span>
                        : <span>{GameStatus.EXPIRED}</span>
                    }
                </div>
                <div className='card-header-right'>
                    <span>#{epoch}</span>
                </div>
            </div>
            <div className='card-content'>
                <div className='vote-wrap'>
                    <div className='vote-top'>
                        {props.hasEnteredUp && <Chip fill uppercase marginRightIcon={8} icon={<i className="fa-thin fa-check"></i>} color='main' txt='Entered' />}
                        <h3>UP</h3>
                        <span>{props.bullMultiplier !== undefined ? `${props.bullMultiplier}x` : '-'} Payout</span>
                    </div>
                    <div className='vote-btm'>
                        {props.hasEnteredDown && <Chip fill uppercase marginRightIcon={8} icon={<i className="fa-thin fa-check"></i>} color='main' txt='Entered' />}
                        <span>{props.bearMultiplier !== undefined ? `${props.bearMultiplier}x` : '-'} Payout</span>
                        <h3>DOWN</h3>
                    </div>
                </div>
                <RoundResult round={props.round} hasFailed={hasRoundFailed} gameStatus={GameStatus.EXPIRED} />
            </div>
        </div>
    )
}

export default ExpiredRoundCard;
import React, { useEffect } from 'react';
import mainLogo from '../../assets/images/Logo_Lucra.svg';
import MenuItem from '../MenuItem/MenuItem';
import ConnectButton from 'components/ConnectButton/ConnectButton';

import { useLocation } from 'react-router'
import { Menu } from '../../types/Menu'
import { mainMenuConfig } from './config'

function MainMenu() {
    const location: any = useLocation();

    useEffect(() => { }, [location]);

    return (
        <>
            <div id='navbar'>
                <button type="button" className="nav-toggle nav-trigger">
                    <div className="nav-icon"><span></span></div>
                </button>
                <a className="logo-main" href="/">
                    <img src={mainLogo} alt="Lucra" />
                </a>
                <div className='container'>
                    <div className="nav-wrap">
                        <nav id="nav-main">
                            <ul>
                                {mainMenuConfig().map((menu: Menu) => {
                                    return <MenuItem
                                        key={menu.name}
                                        link={menu.link}
                                        name={menu.name}
                                        disabled={menu.disabled}
                                        dropdownItems={menu.dropdownItems}
                                    // icon={menu.icon}
                                    />
                                })}
                                <li className='connect'>
                                    <ConnectButton enableTextDialog={false} />
                                </li>
                                <ul className="nav-social menu">
                                    <li>
                                        <a href="https://medium.com/" target="_blank" title="Medium" rel="noreferrer">
                                            <i className="fa-brands fa-medium"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com" target="_blank" title="Twitter" rel="noreferrer">
                                            <i className="fa-brands fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://telegram.com/" target="_blank" title="Telegram" rel="noreferrer">
                                            <i className="fa-brands fa-telegram"></i>
                                        </a>
                                    </li>
                                </ul>
                            </ul>
                        </nav>
                    </div>
                </div>
                <ul className="nav-social">
                    <li>
                        <a href="https://medium.com/" target="_blank" title="Medium" rel="noreferrer">
                            <i className="fa-brands fa-medium"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com" target="_blank" title="Twitter" rel="noreferrer">
                            <i className="fa-brands fa-twitter"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://telegram.com/" target="_blank" title="Telegram" rel="noreferrer">
                            <i className="fa-brands fa-telegram"></i>
                        </a>
                    </li>
                </ul>
                <ConnectButton enableTextDialog={true} />
            </div>
            <div id="bg-wrapper"></div>
        </>
    )
}

export default MainMenu;